import React from 'react';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Router, Outlet, ReactLocation } from '@tanstack/react-location';

import HomePage from './app-pages/HomePage';
import WatershedStatistics from './app-pages/WatershedStatistics';
import SnowAnalog from './app-pages/SnowAnalog';
import Permafrost from './app-pages/Permafrost';
import Drtspore from './app-pages/Drtspore';
import NotFound from './app-pages/NotFound';
import Private from './app-components/navigation/private';
import Modal from './app-components/modal';
import NavBar from './app-components/navigation';
import PageContent from './app-components/page-content';

import './styles/css/bootstrap/css/bootstrap.water.min.css';
import './styles/index.scss';
import { injectStyle } from 'react-toastify/dist/inject-style';
injectStyle();

export const queryClient = new QueryClient();

const location = new ReactLocation();
const routes = [
  { path: '/', element: <HomePage /> },
  {
    path: '/watershed-statistics',
    element: (
      <Private>
        <WatershedStatistics />
      </Private>
    ),
  },
  {
    path: '/snow-analog',
    element: (
      <Private>
        <SnowAnalog />
      </Private>
    ),
  },
  {
    path: '/permafrost',
    element: (
      <Private>
        <Permafrost />
      </Private>
    ),
  },
  {
    path: '/drtspore',
    element: (
      <Private>
        <Drtspore />
      </Private>
    ),
  },
  { path: '*', element: <NotFound /> },
];

export default () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Router location={location} routes={routes}>
        <ReactQueryDevtools initialIsOpen={false} />
        <NavBar
          dropdownPaths={routes
            .filter(r => r.path !== '/' && r.path !== '*')
            .map(r => r.path)}
        />
        <ToastContainer
          position='top-center'
          autoClose={3500}
          hideProgressBar={false}
        />
        <PageContent>
          <Outlet />
        </PageContent>
        <Modal />
      </Router>
    </QueryClientProvider>
  );
};
