import { useEffect, useState } from 'react';

const useAutoFocus = ({ source, shouldReset = false }) => {
  const [focus, setFocus] = useState(source);

  useEffect(() => {
    source && setFocus(source);
  }, [source]);

  useEffect(() => {
    return () => {
      shouldReset && setFocus(undefined);
    };
  }, [shouldReset]);

  return [focus, setFocus];
};

export default useAutoFocus;
