import { useEffect } from 'react';

import { useOLMap } from '../../olMap';
import LayerGroup from 'ol/layer/Group';
import TileLayer from 'ol/layer/Tile';

const LabelLayerGroup = () => {
  const { map } = useOLMap();

  useEffect(() => {
    const labels = new LayerGroup({
      title: 'Labels',
      visible: false,
      layers: [
        new TileLayer({
          type: 'label',
          zIndex: 5,
        }),
      ],
    });

    map.addLayer(labels);
    return () => map.removeLayer(labels);
  }, [map]);

  return null;
};

export default LabelLayerGroup;
