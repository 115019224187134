import React, { useState } from 'react';
import { Link } from '@tanstack/react-location';

import NavItem from './navItem';
import { classArray } from '../../utils/helpers';
import { isLoggedIn, doLogin, doLogout } from '../../utils/userService';

import Icon from '../icon';
import { mdiLogin, mdiLogout } from '@mdi/js';

import usaceLogo from '../../styles/images/USACE-Logo.png';
import './navigation.scss';

const NavBar = ({ dropdownPaths = [] }) => {
  const [show, setShow] = useState(false);
  const navClasses = classArray([
    'navbar',
    'navbar-expand-lg',
    'navbar-light',
    'fixed-top',
    'bg-white',
    'text-dark',
    'seperator',
    'py-0',
    'mb-0',
  ]);

  const navCollapseClasses = classArray([
    'collapse',
    'navbar-collapse',
    show && 'show',
  ]);

  const toggleShow = () => setShow(!show);

  return (
    <nav className={navClasses}>
      <div className='navbar-brand'>
        <Link to='/' title='Extreme Cold Weather - Home' className='row'>
          <img
            alt='USACE Logo'
            className='mr-2'
            src={usaceLogo}
            width={78}
            height={52}
          />
          <div className='col text-primary font-weight-light'>
            <span className='row'>Extreme Cold Weather (ECW)</span>
            <span className='subnav-text row' style={{ fontSize: '14px' }}>
              {`Arctic Data Analysis Environment`}
            </span>
          </div>
        </Link>
      </div>
      <div className='col text-danger'>
        <b>TEST ENVIRONMENT</b>
      </div>
      <button
        className='navbar-toggler'
        type='button'
        aria-expanded='false'
        aria-label='Toggle navigation'
        onClick={toggleShow}
      >
        <span className='navbar-toggler-icon' />
      </button>
      <div className={navCollapseClasses}>
        <ul className='navbar-nav ml-auto'>
          {isLoggedIn() ? (
            <>
              <NavItem href={['/']} isHome>
                Home
              </NavItem>
              <NavItem isDropdown allCaps href={dropdownPaths}>
                Tools
              </NavItem>
              <NavItem
                icon={<Icon path={mdiLogout} size={1} />}
                handler={doLogout}
                className='vl'
              >
                Logout
              </NavItem>
            </>
          ) : (
            <NavItem
              icon={<Icon path={mdiLogin} size={1} />}
              handler={doLogin}
              className='vl'
            >
              Login
            </NavItem>
          )}
        </ul>
      </div>
    </nav>
  );
};

export default NavBar;
