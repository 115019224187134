import { Fill, Stroke, Style, Circle } from 'ol/style';

// Vector Styles
export const unselectedStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(50,50,50,0.9)',
    width: 1.2,
  }),
  fill: new Fill({
    color: 'rgba(0,0,0,0)',
  }),
});

export const hoverStyle = new Style({
  stroke: new Stroke({
    color: 'rgba(50,50,50,0.9)',
    width: 2,
  }),
  fill: new Fill({
    color: 'rgba(0,0,0,0.3)',
  }),
});

export const selectedStyle = new Style({
  stroke: new Stroke({
    color: 'white',
    width: 3,
  }),
  fill: new Fill({
    color: 'rgba(0,0,0,0.3)',
  }),
});

export const alaskaBboxStyle = new Style({
  stroke: new Stroke({
    color: 'white',
    width: 3,
  }),
  fill: new Fill({
    color: 'rgba(0,0,0,0)',
  }),
});

export const pointStyle = new Style({
  image: new Circle({
    radius: 7,
    stroke: new Stroke({
      color: 'white',
      width: 2.5,
    }),
    fill: new Fill({
      color: 'black',
    }),
  }),
});
