import { gen } from './helpers';

export const createWatershedSlice = set => ({
  ...gen(set, {
    watershedId: '',
    watershedName: '',
    myWatershedId: '',
    drawShape: 'Radius',
    drawGeomWatershed: '',
    isMyWatersheds: false,
    isDrawingWatershed: false,
    isSavePromptWatershed: false,
    isDeletePromptWatershed: false,
    isWatershedStatsLoading: true,
  }),
});
