import React from 'react';

const NotFound = () => {
  return (
    <div className='mt-4 d-flex justify-content-center text-center'>
      <div>
        <h2>404 Not found</h2>
        <p>We couldn't find what you were looking for.</p>
      </div>
    </div>
  );
};

export default NotFound;
