import React, { useEffect, useState } from 'react';
import Select, { createFilter } from 'react-select';
import { FixedSizeList as List } from 'react-window';

/**
 * A Select element, with optional multi-select, that utilizes list virtualization.
 * Creates `<option>`(s) based on the array of options provided and handles state internally.
 *
 * @param {string} title The title of the select element, read by screen readers and provides text on hover.
 * @param {string} label Adds a label tag with the text provided.
 * @param {string} placeholderText Provide custom text to display in the placeholder option.
 * @param {string} className Classes to provide to the `<select>` element.
 * @param {string} labelClassName Classes to provide to the `<label>` element.
 * @param {array} options A list of options `{ value: string, label: string }` provided within the select element.
 * @param {boolean} isDisabled Whether or not the select is disabled and should apply the correct styles. Defaulted to `false`.
 * @param {boolean} isMulti Whether or not the user may select multiple values. Defaulted to `false`.
 * @param {function} onChange An onChange event handler function which is passed the label(s) and value(s)
 * @param {object} value The optional external value object, which turns this into a managed component
 */
const VirtualSelect = ({
  title = '',
  label = '',
  placeholderText = 'Select an option...',
  className = '',
  labelClassName = '',
  options = [],
  isDisabled = false,
  isMulti = false,
  onChange = () => {},
  value = undefined,
  ...customProps
}) => {
  const [optionData, setOptionData] = useState(options);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleChange = selectedOptions => {
    setSelectedOptions(selectedOptions);
    onChange(selectedOptions);
  };

  useEffect(() => {
    setOptionData(options);
  }, [options]);

  const Row = ({ options, index, style }) => (
    <div style={style}>{options[index].label}</div>
  );

  const MenuList = ({ innerProps, isDisabled }) => (
    <List
      options={innerProps?.options}
      height={200}
      isDisabled={isDisabled}
      itemCount={innerProps?.options.length}
      itemSize={40}
    >
      {Row}
    </List>
  );

  return (
    <div style={{ flex: 1 }}>
      {label && (
        <label className={labelClassName}>
          <small>{label}</small>
        </label>
      )}
      <Select
        className={className}
        closeMenuOnSelect={!isMulti}
        components={MenuList}
        filterOption={createFilter({ ignoreAccents: false })}
        isDisabled={isDisabled}
        isClearable={true}
        isMulti={isMulti}
        placeholder={
          <div>{placeholderText ? placeholderText : 'Select...'}</div>
        }
        onChange={handleChange}
        options={optionData}
        styles={{
          menu: baseStyles => ({
            ...baseStyles,
            zIndex: '9900',
          }),
          valueContainer: baseStyles => ({
            ...baseStyles,
            maxHeight: '80px',
            overflowY: 'auto',
          }),
        }}
        title={title}
        value={value === undefined ? selectedOptions : value}
        {...customProps}
      />
    </div>
  );
};

export default VirtualSelect;
