import React from 'react';

import RasterLayer from '../../shared-components/mapContent/rasterLayer';
import MapLayout from '../../shared-components/mapLayout/mapLayout';
import useWKT from '../../../app-components/ol-map/hooks/useWKT';
import { alaskaWebMercatorMapConfig } from '../../shared-components/mapContent/baseMapConfig';
import { Layers, VectorLayer } from '../../../app-components/ol-map/layers';
import { CoordinateMapContent } from '../../SnowAnalog/olMap/components';
import { Interactions } from '../../../app-components/ol-map/interactions';
import { PixelQueryInteraction } from '../../../app-components/ol-map/interactions/pixelQueryInteraction';
import { alaskaBboxStyle } from '../../../app-components/ol-map/styles/vectorStyles';
import { store } from '../../../stores/app-state/store';
import { useRasterLayersSettings } from '../../shared-components/mapContent/layerSettings';

import 'react-tooltip/dist/react-tooltip.css';

const OlMap = ({ productSlug, products = [], isLoading }) => {
  const { productId } = store.useProductId();

  const wkt =
    'POLYGON ((-148.417750808682 65.1004828864668,-148.151165096815 63.6661981481448,-144.432602602209 63.6530181049862,-144.234465415889 65.0851610339165,-148.417750808682 65.1004828864668))';
  const wktSource = useWKT({ wkt, featureProjection: 'EPSG:3857' });
  
  const productfiles = [];
  for (let i = 0; i < products.length; ++i) {
    productfiles.push(...products[i].productfiles);
  }

  const rasterLayers = useRasterLayersSettings({ productId, productfiles, isLoading })

  return (
    <MapLayout mapConfig={alaskaWebMercatorMapConfig} productSlug={productSlug}>
      <CoordinateMapContent />
      <Layers>
        {rasterLayers?.length
          ? rasterLayers.map(props => {
              return (
                <RasterLayer
                  key={props?.slug}
                  visibleStyle={props?.slug === productSlug}
                  {...props}
                />
              );
            })
          : null}
        {wktSource && (
          <VectorLayer
            source={wktSource}
            zIndex={100}
            style={alaskaBboxStyle}
            zoomSource={wktSource.getExtent()}
          />
        )}
      </Layers>
      <Interactions>
        <PixelQueryInteraction event='pointermove' />
      </Interactions>
    </MapLayout>
  );
};

export default OlMap;
