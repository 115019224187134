import React from 'react';
import Dropdown from '../../../../../app-components/dropdown';
import Button from '../../../../../app-components/button/button';
import InfoPanel from '../../../../../app-components/info-panel/infoPanel';
import {
  useGetMyCoordinateSelectionQuery,
  useGetMyCoordinatesQuery,
} from '../../../../../stores/server-state/coordinates';
import { store } from '../../../../../stores/app-state/store';

const FILE_NUM_LIMIT = 7;

const MyCoordinatesSelect = ({ tag }) => {
  const { myCoordinateId, doUpdateMyCoordinateId } = store.useMyCoordinateId();
  const { doUpdateShowSource } = store.useShowSource();
  const { doUpdateIsSavePromptCoordinate } = store.useIsSavePromptCoordinate();
  const { doUpdateIsDeletePromptCoordinate } =
    store.useIsDeletePromptCoordinate();
  const { isDrawingCoordinate, doUpdateIsDrawingCoordinate } =
    store.useIsDrawingCoordinate();

  const { data: myCoordinatesData } = useGetMyCoordinatesQuery({
    tagId: tag?.id,
  });
  const { data: myCoordinateSelection } = useGetMyCoordinateSelectionQuery({
    myCoordinateId,
  });

  const fileThresholdReached =
    tag?.name === 'Snow Analog'
      ? (myCoordinatesData?.length ?? 0) >= FILE_NUM_LIMIT
      : false;

  return (
    <div className='d-flex'>
      <div className='form-group p-1'>
        <label htmlFor='pointName'>
          <small>Point Name</small>
        </label>
        <div className='d-flex flex-nowrap flex-row input-group mt-1'>
          <Dropdown.Menu
            dropdownClass='p-0 m-0'
            buttonClass='form-control custom-select rounded-right-0'
            buttonContent={myCoordinateSelection?.name ?? '...'}
            isDisabled={!tag?.name || tag?.name !== 'Permafrost'}
          >
            {myCoordinatesData &&
              myCoordinatesData
                .filter(c => c.id !== myCoordinateSelection?.id)
                .map(c => (
                  <Dropdown.Item
                    value={c.id}
                    key={c.id}
                    onClick={e => {
                      doUpdateMyCoordinateId(e.target.value);
                    }}
                  >
                    {c.name}
                  </Dropdown.Item>
                ))}
          </Dropdown.Menu>
          <div className='input-group-append'>
            <Button
              className='input-group-text'
              text='Edit'
              variant={myCoordinateSelection?.name ? 'warning' : 'light'}
              handleClick={() => doUpdateIsSavePromptCoordinate(true)}
              isDisabled={!myCoordinateSelection?.name}
            />
            <Button
              className='input-group-text'
              text='Delete'
              variant={myCoordinateSelection?.name ? 'danger' : 'light'}
              handleClick={() => doUpdateIsDeletePromptCoordinate(true)}
              isDisabled={!myCoordinateSelection?.name}
            />
          </div>
        </div>
      </div>
      <div className='ml-auto p-1'>
        <label htmlFor='pointSelect'>
          <small>New Point</small>
        </label>
        <div className='mt-1' htmlFor='pointSelect'>
          <Button
            className='btn-block my-0 px-0'
            style={{ fontSize: '14px' }}
            text={!isDrawingCoordinate ? 'Draw' : 'Cancel'}
            variant={!isDrawingCoordinate ? 'success' : 'danger'}
            handleClick={() => {
              doUpdateIsDrawingCoordinate(!isDrawingCoordinate);
              doUpdateShowSource(true);
            }}
            isOutline={isDrawingCoordinate}
            isDisabled={fileThresholdReached}
          />
        </div>
      </div>
      {fileThresholdReached ? (
        <div className='col-5 mt-2 pl-3 pr-0'>
          <InfoPanel variant='warning' textVariant='white'>
            <strong>
              File limit reached: Delete files to create new points.
            </strong>
          </InfoPanel>
        </div>
      ) : null}
    </div>
  );
};

export default MyCoordinatesSelect;
