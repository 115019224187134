import { useEffect, useCallback } from 'react';

import { useOLMap } from '../olMap';

import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import { WKT } from 'ol/format';
import Polygon, { fromCircle } from 'ol/geom/Polygon';
import { Draw } from 'ol/interaction';
import { createBox } from 'ol/interaction/Draw';
import { fromUserCoordinate, getUserProjection, transform } from 'ol/proj';

import { selectedStyle, pointStyle } from '../styles/vectorStyles';
import { getLayerByName } from '../ol-map-helpers';
import { boundingExtent, getBottomLeft, getBottomRight, getTopLeft, getTopRight } from 'ol/extent';

const drawEndHandler = ({
  vectorSource,
  projection,
  updateSource,
  updateGeom,
  updateLatLon,
  drawEnd,
  e,
}) => {
  let drawGeometry;
  let latLon = [];
  const geometry = e.feature.getGeometry();

  // Downsample circle to polygon, circle type unsupported
  if (geometry.getType() === 'Circle') {
    drawGeometry = fromCircle(e.feature.getGeometry(), 64);
  } else {
    drawGeometry = e.feature.getGeometry();
  }

  const wktFormat = new WKT();
  const geometryAsWKT = wktFormat.writeGeometry(drawGeometry, {
    dataProjection: projection,
    featureProjection: projection,
  });

  if (updateLatLon) {
    // Get LatLon for Point default name
    if (geometry.getType() === 'Point') {
      const coord = transform(
        geometry.getCoordinates(),
        projection,
        'EPSG:4326',
      );
      latLon = coord.reverse();
    }
    updateLatLon(latLon);
  }
  updateSource(vectorSource);
  updateGeom(geometryAsWKT);
  drawEnd();
};

const removeDrawLayer = map => {
  const layer = getLayerByName(map.getLayers().getArray(), 'draw');
  layer && map.removeLayer(layer);
};

const DrawInteraction = ({
  isSavePrompt = false,
  zIndex = 2,
  isDrawing = false,
  drawShape = 'Radius',
  onSourceChange = () => {},
  onDrawGeomChange = () => {},
  onDrawEnd = () => {},
  onLatLonChange = undefined,
  maxLength = undefined, //[x, y]
}) => {
  const { map, projection } = useOLMap();

  const createDrawInteraction = useCallback(
    source => {
      let geometryFunction;
      let type = 'Circle';
      if (drawShape === 'Box') {
        if (maxLength) {
          geometryFunction = (coordinates, geometry, projection) => {
            const first = coordinates[0];
            const last = coordinates[coordinates.length - 1];
            const signX = first[0] > last[0] ? -1 : 1;
            const signY = first[1] > last[1] ? -1 : 1;
            const reduceX = Math.abs(Math.abs(first[0]) - Math.abs(last[0])) > maxLength[0];
            const reduceY = Math.abs(Math.abs(first[1]) - Math.abs(last[1])) > maxLength[1];
            const newCoord = [
              reduceX ? first[0] + maxLength[0] * signX: last[0],
              reduceY ? first[1] + maxLength[1] * signY: last[1]
            ];
            const extent = boundingExtent(([
                first,
                newCoord,
              ]).map(function (coordinate) {
                return fromUserCoordinate(coordinate, projection);
              })
            );
            const boxCoordinates = [
              [
                getBottomLeft(extent),
                getBottomRight(extent),
                getTopRight(extent),
                getTopLeft(extent),
                getBottomLeft(extent),
              ],
            ];
            if (geometry) {
              geometry.setCoordinates(boxCoordinates);
            } else {
              geometry = new Polygon(boxCoordinates);
            }
            const userProjection = getUserProjection();
            if (userProjection) {
              geometry.transform(projection, userProjection);
            }
            return geometry;
          }
        } else {
          geometryFunction = createBox();
        }
      }
      if (drawShape === 'Polygon' || drawShape === 'Point') {
        type = drawShape;
      }
      return new Draw({
        source,
        type,
        geometryFunction,
      });
    },
    [drawShape],
  );

  useEffect(() => {
    if (!isDrawing && !isSavePrompt) {
      removeDrawLayer(map);
    }
  }, [isDrawing, isSavePrompt, map]);

  useEffect(() => {
    const vectorSource = new VectorSource({
      wrapX: true,
    });
    const vectorLayer = new VectorLayer({
      name: 'draw',
      source: vectorSource,
      style: drawShape === 'Point' ? pointStyle : selectedStyle,
      zIndex,
    });

    let draw;
    if (isDrawing && !isSavePrompt) {
      draw = createDrawInteraction(vectorSource);

      map.addInteraction(draw);
      map.addLayer(vectorLayer);

      draw.on('drawend', e => {
        drawEndHandler({
          vectorSource,
          projection,
          updateSource: onSourceChange,
          updateGeom: onDrawGeomChange,
          updateLatLon: onLatLonChange,
          drawEnd: onDrawEnd,
          e,
        });
      });
    }

    return () => map.removeInteraction(draw);
  }, [
    projection,
    isDrawing,
    drawShape,
    isSavePrompt,
    onDrawGeomChange,
    onDrawEnd,
    zIndex,
    map,
    createDrawInteraction,
    onSourceChange,
    onLatLonChange,
  ]);

  useEffect(() => {
    return () => {
      removeDrawLayer(map);
    };
  }, [map]);

  return null;
};

export default DrawInteraction;
