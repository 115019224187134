import { gen } from './helpers';

export const createMapControlSlice = set => ({
  ...gen(set, {
    latLonValue: '',
    rasterOptionMap: new Map(),
    rasterValueMap: {},
    coordinateRasterValueMap: {},
    minFilter: undefined,
    maxFilter: undefined,
    rasterOpacity: 0.5,
    isAutoFocus: false,
  }),
});
