import React from 'react';

import { store } from '../../../../stores/app-state/store';
import { useGetDrtsporeSelectionQuery } from '../../../../stores/server-state/drtspore';
import { Fill, Stroke, Style } from 'ol/style';
import { useWKT, useAutoFocus } from '../../../../app-components/ol-map/hooks';
import { Layers, VectorLayer } from '../../../../app-components/ol-map/layers';
import RasterLayer from '../../../shared-components/mapContent/rasterLayer';
import { useRasterLayersSettings } from '../../../shared-components/mapContent/layerSettings';

const DrtsporeMapContent = () => {
  const { drtsporeId, doUpdateDrtsporeId } = store.useDrtsporeId();
  const { productId } = store.useProductId();
  const { productFileId } = store.useProductFileId();
  const { data: drtsporeSelection, isLoading } = useGetDrtsporeSelectionQuery({
    drtsporeId,
  });

  const productfiles = drtsporeSelection?.productfiles;

  const resetGeom = React.useCallback(
    () => doUpdateDrtsporeId(''),
    [doUpdateDrtsporeId],
  );

  const style = new Style({
    stroke: new Stroke({
      color: 'white',
      width: 3,
    }),
    fill: new Fill({
      color: 'rgba(0,0,0,0)',
    }),
  });

  const layerSettings = useRasterLayersSettings({
    productId,
    productfiles,
    isLoading,
  });

  const rasterLayerProps =
    layerSettings?.find(cfg => cfg.id === productFileId) ?? {};

  const wktSource = useWKT({
    wkt:
      drtsporeSelection?.status === 'SUCCESS' ? drtsporeSelection?.geom : null,
  });

  const [focus] = useAutoFocus({
    source: wktSource,
    shouldReset: true,
  });

  React.useEffect(() => {
    return () => {
      drtsporeSelection?.length && resetGeom();
    };
  }, []);

  return (
    <Layers>
      <VectorLayer
        title='drtspore-bound'
        source={wktSource}
        style={style}
        zIndex={100}
        zoomSource={focus}
      />
      {rasterLayerProps && <RasterLayer {...rasterLayerProps} />}
    </Layers>
  );
};

export default DrtsporeMapContent;
