import React from 'react';
import InfoPanel from '../../../../../app-components/info-panel/infoPanel';

const HydroBasinsSelect = ({ selectedFeatureId }) => {
  return (
    <div className='form-row'>
      <div className='w-50 ml-1 form-group'>
        <label htmlFor='hybasid'>
          <small>HyBasID</small>
        </label>
        <br />
        <input
          id='hybasid'
          className='mt-1 form-control'
          value={selectedFeatureId ?? 'Select a feature from the map...'}
          readOnly
        />
      </div>
      <div className='col mt-2 pl-3 pr-0'>
        <InfoPanel>
          Make a selection from the predefined watershed boundaries on the map.
        </InfoPanel>
      </div>
    </div>
  );
};

export default HydroBasinsSelect;
