import React from 'react';

import './sidebar.scss';

const Sidebar = ({ children }) => {
  return (
    <aside id='content-sidebar' className='sidebar'>
      {children}
    </aside>
  );
};

export default Sidebar;
