import React from 'react';

import Button from '../../../../../app-components/button/button';
import Select from '../../../../../app-components/select/select';
import { store } from '../../../../../stores/app-state/store';
import {
  useGetMyWatershedSelectionQuery,
  useGetMyWatershedsQuery,
} from '../../../../../stores/server-state/watersheds';

const MyWatershedsSelect = () => {
  const { doUpdateIsSavePromptWatershed } = store.useIsSavePromptWatershed();
  const { drawShape, doUpdateDrawShape } = store.useDrawShape();
  const { myWatershedId, doUpdateMyWatershedId } = store.useMyWatershedId();
  const { isDrawingWatershed, doUpdateIsDrawingWatershed } =
    store.useIsDrawingWatershed();
  const { doUpdateIsDeletePromptWatershed } =
    store.useIsDeletePromptWatershed();

  const { data: myWatersheds, error } = useGetMyWatershedsQuery();
  const { data: myWatershedSelection } = useGetMyWatershedSelectionQuery({
    myWatershedId,
  });

  const myWatershedOptions =
    (!error &&
      myWatersheds?.map(obj => ({
        key: obj.id,
        value: obj.id,
        text: obj.name,
      }))) ||
    [];

  return (
    <div className='form-row'>
      <div className='w-50 ml-1 form-group'>
        <label htmlFor='myWaterhsedSelect'>
          <small>My Watersheds</small>
        </label>
        <div className='input-group mt-1'>
          <Select
            className='form-control rounded-left'
            title={`My Watershed Selection: ${myWatershedSelection?.name}`}
            value={myWatershedId}
            onChange={value => {
              if (value.length && myWatershedId !== value)
                doUpdateMyWatershedId(value);
            }}
            defaultOption={myWatershedId}
            options={myWatershedOptions}
            id='myWaterhsedSelect'
            aria-describedby='basic-addon2'
          />
          <div className='input-group-append'>
            <Button
              className='input-group-text'
              text='Edit'
              variant={myWatershedSelection?.name ? 'warning' : 'light'}
              handleClick={() => doUpdateIsSavePromptWatershed(true)}
              isDisabled={!myWatershedSelection?.name}
            />
            <Button
              className='input-group-text'
              text='Delete'
              variant={myWatershedSelection?.name ? 'danger' : 'light'}
              handleClick={() => doUpdateIsDeletePromptWatershed(true)}
              isDisabled={!myWatershedSelection?.name}
            />
          </div>
        </div>
      </div>
      <div className='col ml-2 pl-2 pr-0'>
        <label htmlFor='shapeSelect'>
          <small>Create New Watershed</small>
        </label>
        <div className='input-group mt-1'>
          <Select
            id='shapeSelect'
            className='form-control rounded-left'
            title={drawShape}
            value={drawShape}
            onChange={doUpdateDrawShape}
            defaultOption={drawShape}
            options={[
              { value: 'Radius', key: 'Radius' },
              { value: 'Box', key: 'Box' },
              { value: 'Polygon', key: 'Polygon' },
            ]}
          />
          <div className='input-group-append'>
            <Button
              text={!isDrawingWatershed ? 'Draw' : 'Cancel'}
              variant={!isDrawingWatershed ? 'success' : 'danger'}
              className='my-0 py-0 text-sm'
              handleClick={() => {
                doUpdateIsDrawingWatershed(!isDrawingWatershed);
              }}
              isOutline={isDrawingWatershed}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyWatershedsSelect;
