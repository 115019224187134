import { useQuery } from '@tanstack/react-query';
import { apiGet } from './api-helpers';

export const useGetTagsQuery = () => {
  const uri = `/tags`;

  return useQuery(['tags'], () => apiGet(uri));
};

export const useGetTagByNameQuery = ({ name }) => {
  const uri = `/tags`;

  return useQuery(['tags', name], () => apiGet(uri), {
    select: tags => tags.find(tag => tag.name === name),
    enabled: !!name?.length,
  });
}
