export const createModalSlice = set => ({
  ModalContent: null,
  modalProps: null,
  doModalOpen: (ModalContent, modalProps) =>
    set(
      {
        ModalContent,
        modalProps,
      },
      false,
      'doModalOpen',
    ),
  doModalClose: () =>
    set(
      {
        ModalContent: null,
        modalProps: null,
      },
      false,
      'doModalClose',
    ),
});
