import { apiGet } from './api-helpers';
import { useQuery } from '@tanstack/react-query';
import { store } from '../app-state/store';

export const useGetMySnowAnalogQuery = (opts = null) => {
  const uri = '/snow_analog';

  return useQuery(['snow_analog'], () => apiGet(uri), opts);
};

export const usePollMySnowAnalogQuery = () => {
  const { shouldFetchCoordinates } = store.useShouldFetchCoordinates();
  const { doUpdateShouldFetchCoordinates } = store.useShouldFetchCoordinates();
  return useGetMySnowAnalogQuery({
    refetchInterval: shouldFetchCoordinates ? 1000 * 10 : false,
    refetchIntervalInBackground: true,
    onSuccess: body => {
      for (let i = 0, ii = body?.length; i < ii; i++) {
        if (body[i]?.status === 'INITIATED') {
          doUpdateShouldFetchCoordinates(true);
          return;
        }
      }
      doUpdateShouldFetchCoordinates(false);
    },
  });
};
