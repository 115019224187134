import { toast } from 'react-toastify';
import { tSuccess, tError } from '../../utils/toast-helpers';
import { apiGet, apiPost, apiPut, apiDelete } from './api-helpers';
import { useMutation, useQuery } from '@tanstack/react-query';
import { queryClient } from '../../App';
import { store } from '../app-state/store';

export const useGetMyCoordinatesQuery = ({ tagId }) => {
  const uri = `/my_coordinates?tag_id=${tagId}`;

  return useQuery(['my_coordinates', tagId], () => apiGet(uri), {
    enabled: !!tagId?.length,
  });
};

export const useGetMyCoordinateSelectionQuery = ({ myCoordinateId }) => {
  const uri = '/my_coordinates';

  return useQuery(['my_coordinates', myCoordinateId], () => apiGet(uri), {
    select: cs => cs.find(c => c.id === myCoordinateId),
    enabled: !!myCoordinateId?.length,
  });
};

export const useCreateMyCoordinateMutation = () => {
  const { doUpdateMyCoordinateId } = store.useMyCoordinateId();
  const { doUpdateShouldFetchCoordinates } = store.useShouldFetchCoordinates();

  const uri = '/my_coordinates';

  return useMutation(formData => apiPost(uri, formData), {
    onMutate: () => {
      const toastId = toast.loading('Creating new coordinate...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to create. Try again later.');
    },
    onSuccess: (body, __, ctx) => {
      queryClient.invalidateQueries(['my_coordinates']);
      doUpdateMyCoordinateId(body.id);
      doUpdateShouldFetchCoordinates(true);
      tSuccess(ctx.toastId, 'Successfully created new coordinate!');
    },
    onSettled: () => {
      setTimeout(() => {
        queryClient.invalidateQueries(['snow_analog']);
      }, 1000);
    },
  });
};

export const useUpdateMyCoordinateMutation = ({ myCoordinateId }) => {
  const { doUpdateMyCoordinateId } = store.useMyCoordinateId();

  const uri = `/my_coordinates/${myCoordinateId}`;

  return useMutation(coordinate => apiPut(uri, coordinate), {
    onMutate: () => {
      const toastId = toast.loading('Updating point name...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to update. Try again later.');
    },
    onSuccess: (body, __, ctx) => {
      queryClient.invalidateQueries(['my_coordinates']);
      queryClient.invalidateQueries(['snow_analog']);
      doUpdateMyCoordinateId(body.id);
      tSuccess(ctx.toastId, 'Successfully updated point name!');
    },
  });
};

export const useDeleteMyCoordinateMutation = ({ myCoordinateId }) => {
  const { doUpdateShowSource } = store.useShowSource();
  const { doUpdateMyCoordinateId } = store.useMyCoordinateId();

  const uri = `/my_coordinates/${myCoordinateId}`;

  return useMutation(() => apiDelete(uri), {
    onMutate: () => {
      const toastId = toast.loading('Deleting coordinate...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to delete. Try again later.');
    },
    onSuccess: (_, __, ctx) => {
      doUpdateMyCoordinateId('');
      queryClient.invalidateQueries(['my_coordinates']);
      queryClient.invalidateQueries(['snow_analog']);
      doUpdateShowSource(true);
      tSuccess(ctx.toastId, 'Successfully deleted coordinate!');
    },
  });
};
