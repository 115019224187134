import React from 'react';
import { Navigate } from '@tanstack/react-location';
import { isLoggedIn } from '../../utils/userService';

const Private = ({ children }) => {
  if (!isLoggedIn()) {
    return <Navigate to='/' />;
  }
  return children;
};
export default Private;
