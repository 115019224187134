import React, { useEffect, useState, useReducer, useRef } from 'react';
import Button from '../../../../../app-components/button/button';
import InfoPanel from '../../../../../app-components/info-panel/infoPanel';
import { store } from '../../../../../stores/app-state/store';
import { reduceFormData } from '../../../../../utils/form-helpers';
import {
  useCreateMyWatershedMutation,
  useGetMyWatershedsQuery,
  useGetMyWatershedSelectionQuery,
  useUpdateMyWatershedMutation,
} from '../../../../../stores/server-state/watersheds';

const myWatershedForm = () => {
  const { myWatershedId } = store.useMyWatershedId();
  const { drawGeomWatershed } = store.useDrawGeomWatershed();
  const { doUpdateIsDrawingWatershed } = store.useIsDrawingWatershed();
  const { doUpdateIsSavePromptWatershed } = store.useIsSavePromptWatershed();

  const { data: myWatershedsData } = useGetMyWatershedsQuery();
  const { data: myWatershedSelection } = useGetMyWatershedSelectionQuery({
    myWatershedId,
  });
  const creator = useCreateMyWatershedMutation();
  const updater = useUpdateMyWatershedMutation({ myWatershedId });

  const myWatershedsTotalCount = Number(myWatershedsData?.length);

  const defaultName = `My Watershed #${myWatershedsTotalCount + 1}`;
  let initialState = {
    name: defaultName,
    slug: '',
    geometry: '',
    office_symbol: null,
  };
  const inputRef = useRef();

  const myWatershedSelectionValid =
    myWatershedSelection && Object.keys(myWatershedSelection).length !== 0;
  if (myWatershedSelectionValid) initialState = myWatershedSelection;

  const [formIsValid, setFormIsValid] = useState(true);
  const [formType, setFormType] = useState('new');
  const [formData, dispatch] = useReducer(reduceFormData, initialState);

  const closeForm = () => {
    doUpdateIsDrawingWatershed(false);
    doUpdateIsSavePromptWatershed(false);
  };

  const submitForm = e => {
    e.preventDefault();
    formData.name = formData.name.trim();
    if (formData.name.length === 0) {
      setFormIsValid(false);
      return;
    }
    formData.slug = formData.name.trim().toLowerCase().replace(/ /g, '-');
    if (formType === 'edit') {
      updater.mutate(formData);
    } else {
      creator.mutate(formData);
    }
    closeForm();
  };

  const cancelForm = () => {
    dispatch({ type: 'reset', payload: initialState });
    closeForm();
  };

  useEffect(() => {
    drawGeomWatershed &&
      dispatch({
        type: 'update',
        field: 'geometry',
        value: drawGeomWatershed,
      });
  }, [dispatch, drawGeomWatershed]);

  useEffect(() => {
    if (myWatershedSelectionValid) {
      setFormType('edit');
    } else {
      setFormType('new');
    }
  }, [myWatershedSelectionValid, myWatershedSelection, setFormType]);

  useEffect(() => {
    if (formType === 'edit') {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [formType]);

  return (
    <div className='form-row'>
      <div className='w-50 ml-1 form-group'>
        <label htmlFor='my-watershed-name'>
          <small>My Watershed Name</small>
        </label>
        <form
          onSubmit={submitForm}
          id='my-watershed-name'
          className='input-group mt-1'
        >
          <input
            ref={inputRef}
            maxLength={100}
            className={`form-control ${!formIsValid && 'is-invalid'}`}
            placeholder={formData.name}
            defaultValue={formType === 'edit' ? formData.name : undefined}
            aria-label='My Watershed Name'
            aria-describedby='My Watershed Name'
            onChange={e =>
              dispatch({
                type: 'update',
                field: 'name',
                value: e.target.value,
              })
            }
            autoFocus
          />
          <div className='input-group-append'>
            <Button
              text='Save'
              type='submit'
              variant='success'
              className='my-0 py-0'
            />
            <Button
              className='input-group-text my-0 py-0'
              text='Cancel'
              variant='danger'
              handleClick={cancelForm}
            />
          </div>
        </form>
      </div>
      <div className='col mt-2 pl-3 pr-0'>
        <InfoPanel>
          To generate zonal statistics for the boundary, enter a name and click{' '}
          <strong>Save</strong>.
        </InfoPanel>
      </div>
    </div>
  );
};

export default myWatershedForm;
