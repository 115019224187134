import { toast } from 'react-toastify';
import { tError, tSuccess } from '../../utils/toast-helpers';
import { apiGet, apiPost, apiPut, apiDelete } from './api-helpers';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../App';

import { store } from '../app-state/store';

export const useGetDrtsporesQuery = (opts = null) => {
  const uri = '/drtspore';

  return useQuery(['drtspore'], () => apiGet(uri), opts);
};

export const usePollDrtsporeQuery = () => {
  const { shouldFetchDrtspores, doUpdateShouldFetchDrtspores } = store.useShouldFetchDrtspores();

  return useGetDrtsporesQuery({
    refetchInterval: shouldFetchDrtspores ? 1000 * 10 : false,
    refetchIntervalInBackground: true,
    onSuccess: body => {
      for (let i = 0, ii = body?.length; i < ii; i++) {
        if (body[i]?.status === 'INITIATED') {
          doUpdateShouldFetchDrtspores(true);
          return;
        }
      }
      doUpdateShouldFetchDrtspores(false);
    },
  });
};

export const useGetDrtsporeTimeQuery = (opts = null) => {
  const uri = '/drtspore/times';
  
  if (opts === null) {
    opts = { staleTime: Infinity, cacheTime: Infinity }
  }

  return useQuery(['drtspore', 'times'], () => apiGet(uri), opts);
};

export const useGetDrtsporeSelectionQuery = ({ drtsporeId }) => {
  return useGetDrtsporesQuery({
    select: ws => ws.find(w => w.id === drtsporeId),
    enabled: !!drtsporeId?.length,
  });
};

export const useCreateDrtsporeMutation = () => {
  const { doUpdateDrtsporeId } = store.useDrtsporeId();

  const uri = '/drtspore';

  return useMutation(formData => apiPost(uri, formData), {
    onMutate: () => {
      const toastId = toast.loading('Creating new drtspore...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to create. Try again later.');
    },
    onSuccess: (body, _, ctx) => {
      queryClient.invalidateQueries(['drtspore']);
      doUpdateDrtsporeId(body.id);
      tSuccess(ctx.toastId, 'Successfully created new drtspore!');
    },
  });
};

export const useUpdateDrtsporeMutation = ({ drtsporeId }) => {
  const { doUpdateDrtsporeId } = store.useDrtsporeId();

  const uri = `/drtspore/${drtsporeId}`;

  return useMutation(formData => apiPut(uri, formData), {
    onMutate: () => {
      const toastId = toast.loading('Updating drtspore name...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to update. Try again later.');
    },
    onSuccess: (body, _, ctx) => {
      queryClient.invalidateQueries(['drtspore']);
      doUpdateDrtsporeId(body.id);
      tSuccess(ctx.toastId, 'Successfully updated drtspore name!');
    },
  });
};

export const useDeleteDrtsporeMutation = ({ drtsporeId }) => {
  const { doUpdateDrtsporeId } = store.useDrtsporeId();

  const uri = `/drtspore/${drtsporeId}`;

  return useMutation(() => apiDelete(uri), {
    onMutate: () => {
      const toastId = toast.loading(
        'Deleting drtspore and associated statistics...',
      );
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to delete. Try again later.');
    },
    onSuccess: (_, __, ctx) => {
      doUpdateDrtsporeId('');
      queryClient.invalidateQueries(['drtspore']);
      tSuccess(
        ctx.toastId,
        'Successfully deleted drtspore and associated statistics!',
      );
    },
  });
};
