import { useState, useCallback, useEffect } from 'react';

import VectorTileLayer from 'ol/layer/VectorTile';

const useVectorTileLayer = ({
  source,
  extent,
  zIndex,
  name = '',
  style,
  renderMode,
  updateWhileAnimating = false,
}) => {
  const [layer, setLayer] = useState(null);

  const createVectorTileLayer = useCallback(
    vtSource => {
      return new VectorTileLayer({
        source: vtSource,
        extent,
        zIndex,
        name,
        renderMode,
        updateWhileAnimating,
      });
    },
    [extent, zIndex, name, renderMode, updateWhileAnimating],
  );

  useEffect(() => {
    layer && layer.setStyle(style);
  }, [layer, style]);

  useEffect(() => {
    source && setLayer(createVectorTileLayer(source));
    return () => {
      source && setLayer(null);
    };
  }, [source, createVectorTileLayer]);

  return layer;
};

export default useVectorTileLayer;
