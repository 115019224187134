import { useState, useEffect } from 'react';
import { arraysEqual } from '../../../utils/helpers';

import GeoTIFFSource from 'ol/source/GeoTIFF';

const useGeoTIFF = ({
  urls = [],
  blob,
  min,
  max,
  attributions = '',
  sourceOptions,
  interpolate = false,
}) => {
  const [source, setSource] = useState(undefined);
  const [validBlob, setValidBlob] = useState(false);
  const [currentBlob, setCurrentBlob] = useState(undefined);
  const [validUrls, setValidUrls] = useState(false);
  const [currentUrls, setCurrentUrls] = useState([]);

  const urlsChanged = !arraysEqual(currentUrls, urls);

  useEffect(() => {
    if (blob) {
      setCurrentBlob(blob);
    } else if (urlsChanged) {
      setCurrentUrls(urls);
    }
  }, [urlsChanged, urls, blob]);

  useEffect(() => {
    if (!currentBlob && !currentUrls?.length) {
      setValidUrls(false);
      setValidBlob(false);
    }
    if (blob) {
      setValidBlob(true);
      return;
    };
    let v = true;
    for (let i = 0; i < urls.length; i++) {
      if (!currentUrls[i]) {
        v = false;
        break;
      }
      setValidUrls(v);
    }
  }, [currentUrls, urls, currentBlob, blob]);
  
  useEffect(() => {
    if (validBlob || validUrls) {
      let sources = [];
      if (validBlob) {
        sources = [{ blob, min, max }];
      } else {
        sources = currentUrls.map(url => ({ url, min, max }));
      }

      const tiff = new GeoTIFFSource({
        sources,
        sourceOptions,
        interpolate,
        normalize: false,
      });
      
      tiff.setAttributions(attributions);
    
      setSource(tiff);
    } else {
      setSource(undefined);
    }
  }, [blob, currentUrls, validUrls, currentBlob, validBlob]);

  return blob || urls.length ? source : undefined;
};

export default useGeoTIFF;
