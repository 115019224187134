import React from 'react';

import {
  usePollDrtsporeQuery,
} from '../../../../../stores/server-state/drtspore';
import ExpandTable from './expandTable';

const DrtsporeTable = () => {
  const { data: drtspores } = usePollDrtsporeQuery();

  return (
    <ExpandTable rowData={drtspores}/>
  );
};

export default DrtsporeTable;
