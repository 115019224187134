import React from 'react';
import Toggle from '../../../app-components/toggle/toggle';
import RangeSlider from '../../../app-components/range-slider/rangeSlider';
import { store } from '../../../stores/app-state/store';
import { computedStore } from '../../../stores/app-state/computedStore';

import './mapContent.scss';

export const HoverValuePopout = ({ productSlug }) => {
  const { latLonValue } = store.useLatLonValue();
  const { rasterValue } = computedStore.useRasterValue({ slug: productSlug });
  const { rasterOpacity, doUpdateRasterOpacity } = store.useRasterOpacity();
  const { isAutoFocus, doUpdateIsAutoFocus } = store.useIsAutoFocus();

  const _rasterValue = rasterValue?.length ? rasterValue[0] : rasterValue;

  return (
    <div className='hover-value-popout'>
      <div>
        <label htmlFor='rasterValue'>
          <small>Pixel Value:</small>
          <br />
          <small>
            {!isNaN(_rasterValue) ? Number(_rasterValue).toFixed(2) : 'NoData'}
          </small>
        </label>
        <br />
        <label htmlFor='latLonValue'>
          <small>
            {`Lat: ${
              latLonValue?.length ? Number(latLonValue[0]).toFixed(3) : ''
            }`}
          </small>
          <br />
          <small>
            {`Lon: ${
              latLonValue?.length ? Number(latLonValue[1]).toFixed(3) : ''
            }`}
          </small>
        </label>
      </div>
      <div>
        <RangeSlider
          id='opacitySlider'
          value={rasterOpacity}
          onChange={doUpdateRasterOpacity}
          orient='horizontal'
          style={{ width: '5em' }}
        />
        <div>
          <label htmlFor='opacitySlider'>
            <small>Raster Opacity</small>
          </label>
        </div>
      </div>
      <Toggle
        isActive={isAutoFocus}
        onToggle={() => doUpdateIsAutoFocus(!isAutoFocus)}
        label='Auto-Focus'
      />
    </div>
  );
};
