import React from 'react';
import { Link, useRouter } from '@tanstack/react-location';

import Dropdown from '../dropdown';
import { classArray, hrefAsString } from '../../utils/helpers';

const NavItem = ({
  href,
  children,
  icon,
  className,
  handler,
  isHidden,
  asDropdown,
  isHome,
  inlcudedLinks = [],
  allCaps,
}) => {
  const pathname = useRouter().state.location.pathname;
  const cls = classArray([
    'nav-item',
    href &&
      (href.includes(pathname) || inlcudedLinks.includes(pathname)) &&
      'active',
    isHome && pathname === '/' && 'active',
    className,
  ]);

  const isDropdown = asDropdown || (href && href.length > 1);

  const handleClick = e => {
    if (handler && typeof handler === 'function') handler(e);
  };

  const ItemContent = () => (
    <>
      {icon}
      {icon && <>&nbsp;</>}
      {children}
    </>
  );

  return !isHidden ? (
    handler ? (
      <li className={cls} onClick={handleClick}>
        <span className='nav-link'>
          <ItemContent />
        </span>
      </li>
    ) : (
      <li className={cls}>
        {isDropdown ? (
          <Dropdown.Menu
            menuClass='dropdown-menu-right'
            buttonClass='btn-small p-0 mt-0 ml-0 nav-dropdown-button btn-block nav-link'
            buttonContent={
              <span className='nav-link'>
                <ItemContent />
              </span>
            }
          >
            {href.map(link => (
              <Dropdown.Item
                key={link}
                href={link}
                className={link === pathname ? 'active' : ''}
              >
                {hrefAsString(link, allCaps)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        ) : (
          <Link className='nav-link' to={href}>
            <ItemContent />
          </Link>
        )}
      </li>
    )
  ) : null;
};

export default NavItem;
