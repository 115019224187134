import React from 'react';

import tinygradient from 'tinygradient';
import { colormaps, GL_MAX_NSHADES } from '../../utils/colormaps';

const ColorBar = ({
  id = 'colorBarGraphic',
  colors = colormaps.jet,
  height = 250,
  ticks = [],
  nshades = ticks.length,
  nodataLabel = 'No Data',
  label = '',
}) => {
  if (!ticks.length) {
    return null;
  }

  nshades = Math.min(nshades, GL_MAX_NSHADES);
  const colorBarRef = React.useRef();

  const ratio = window.devicePixelRatio;
  const width = 130;

  const gradient = tinygradient(colors);
  const colormap = gradient.rgb(nshades);
  const yOff = 10;
  const yOffNoData = yOff * 2;

  const colorBarWidth = width * 0.1;
  const textWidth = width * 0.15;

  React.useLayoutEffect(() => {
    const canvas = colorBarRef.current;
    const ctx = canvas.getContext('2d');
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
    ctx.scale(ratio, ratio);

    for (let i = nshades - 1; i >= 0; i--) {
      ctx.fillStyle = colormap[i].toRgbString();
      let c = [0, ((nshades - i) * height) / nshades, colorBarWidth, height];

      ctx.fillRect(...c);
    }
    let c = [0, (80 * height) / nshades, colorBarWidth, height];
    // Draw NoData "X" indicator
    ctx.font = '18px arial';
    ctx.fillStyle = '#ffffff';
    ctx.strokeStyle = '#ffffff';

    ctx.fillRect(...c);
    ctx.fillStyle = '#ff0000';

    c = ['X', 0, (80 * height) / nshades + yOff + 9, colorBarWidth, height];
    ctx.fillText(...c);

    // Text style
    ctx.font = 'bold 12px sans-serif';
    ctx.fillStyle = '#000000';

    for (let i = 0; i < ticks.length; i++) {
      c = [
        ticks[i] || '',
        textWidth,
        (i / (ticks.length - 1)) * (height * 0.95) + yOff + 3,
        width,
        height,
      ];
      ctx.fillText(...c);
    }

    // Draw NoData text
    c = [
      nodataLabel,
      textWidth,
      (80 * height) / nshades + yOff + 8,
      width,
      height,
    ];
    ctx.fillText(...c);
    return () => {
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.resetTransform();
      }
    };
  }, [colors, nshades, height, JSON.stringify(ticks)]);

  return (
    <div>
      <canvas
        id={id}
        ref={colorBarRef}
        width={width * ratio}
        height={(height + yOffNoData) * ratio}
      />
      <br />
      <label style={{ fontSize: '14px' }} className='pt-2' htmlFor={id}>
        <small>{label}</small>
      </label>
    </div>
  );
};

export default ColorBar;
