// https://github.com/USACE/jems-ui/blob/b69618258b5a317713433fee43ce484432cefcca/src/utils/form-helpers.js

export const reduceFormData = (state, action) => {
  switch (action.type) {
    case 'init':
    case 'reset':
      return {
        ...action.payload,
      };
    case 'update':
      return {
        ...state,
        [action.field]: action.value,
      };
    default:
      throw new Error();
  }
};

export const isValid = field => {
  if (Array.isArray(field)) {
    if (field.length === 0) {
      return false;
    }
  } else {
    if (field === '' || field === undefined) {
      return false;
    }
  }

  return true;
};

export const allFieldsValid = (fields = []) => {
  let ret = true;
  fields.forEach(field => {
    if (!isValid(field)) {
      ret = false;
    }
  });

  return ret;
};
