const ACTION_PREFIX = 'doUpdate';

// generate actions (prefixed with `doUpdate` by default) for each key that
// passed to the state parameter object
// each value in the state is the default for the respective key
export const gen = (set, state = {}) => {
  for (let k of Object.keys(state)) {
    const capStateKey = k.charAt(0).toUpperCase() + k.slice(1);

    state[`${ACTION_PREFIX}${capStateKey}`] = val =>
      set({ [k]: val }, false, `${ACTION_PREFIX}${capStateKey}`);
  }

  return state;
};

// createSelectors auto generates selectors based on store key names
//
// all pairs of getters/setters will be exported as objects that can be destructured
//  To get the global state of an object, simply use the name of the
// state slice, prefixed with `use`. Then destructure the selector and action
// for the respective hook.
//
// example:
// const { myTodos, doUpdateMyTodos } = store.useMyTodos();
//
// If the state doesn't have an updater function, the same syntax can be used.
//
// example:
// const { myDerivedState } = store.useDerivedState();
//
export const createSelectors = store => {
  const stateArr = Object.keys(store.getState());

  // 1) Pair and export all of the state with matching actionPrefix
  const matcher = new RegExp(`^${ACTION_PREFIX}`, 'g');
  const actions = stateArr.filter(word => word.match(matcher));

  for (let action of actions) {
    const selectorUpper = action.split(ACTION_PREFIX)[1];
    const selector =
      selectorUpper.charAt(0).toLowerCase() + selectorUpper.slice(1);

    store[`use${selectorUpper}`] = () => ({
      [selector]: store(s => s[selector]),
      [action]: store(s => s[action]),
    });

    stateArr.splice(stateArr.indexOf(selector), 1);
    stateArr.splice(stateArr.indexOf(action), 1);
  }

  // 2) Export everything else left in state array
  for (let k of stateArr) {
    const kUpper = k.charAt(0).toUpperCase() + k.slice(1);

    store[`use${kUpper}`] = () => ({ [k]: store(s => s[k]) });
  }

  return store;
};
