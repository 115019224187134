import { config } from '../../config';
import { store } from './store';

const useProductFileMediaUrl = () => {
  const { productFileId } = store.useProductFileId();

  const productFileMediaUrl = productFileId.length
    ? `${config.API_ROOT}/product/file/${productFileId}`
    : '';

  return { productFileMediaUrl };
};

const useMySnowAnalogMediaUrl = () => {
  const { mySnowAnalogId } = store.useMySnowAnalogId();

  const mySnowAnalogMediaUrl = mySnowAnalogId.length
    ? `${config.API_ROOT}/snow_analog/${mySnowAnalogId}`
    : '';

  return { mySnowAnalogMediaUrl };
};

const useRasterValue = ({ slug }) => {
  const { rasterValueMap } = store.useRasterValueMap();

  return { rasterValue: rasterValueMap[slug] ?? '--' };
};

export const computedStore = {
  useProductFileMediaUrl,
  useMySnowAnalogMediaUrl,
  useRasterValue,
};
