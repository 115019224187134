import React, { useState, Fragment, useEffect } from 'react';
import { store } from '../../../../stores/app-state/store';
import { useGetProductFilesQuery } from '../../../../stores/server-state/products';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

import './permaTable.scss';
import { layerType, mapConfigHandler } from '../../../../utils/colormaps';

const PermaTable = ({ rowData = [] }) => {
  const [rowSelectionCancelled, setRowSelectionCancelled] = useState(false);
  const { productId, doUpdateProductId } = store.useProductId();
  const { doUpdateProductFileId } = store.useProductFileId();
  const { coordinateRasterValueMap } = store.useCoordinateRasterValueMap();

  const { data: productFiles } = useGetProductFilesQuery({ productId });

  const descriptionHandler = row => {
    const rowCfg = mapConfigHandler(row.slug);
    if (!rowCfg) {
      return '';
    }

    if (rowCfg?.type === layerType.Categorical) {
      return rowCfg?.colors[coordinateRasterValueMap[row.slug]]?.description;
    } else {
      return rowCfg?.description ?? '--';
    }
  };

  const columnHelper = createColumnHelper();
  const rowIsSelected = row => row.original.id === productId;
  const getRowClasses = row => {
    return rowIsSelected(row) ? 'row-selected' : '';
  };

  const columns = [
    columnHelper.accessor('description', {
      header: () => (
        <div
          style={{
            paddingLeft: 4,
          }}
        >
          Product Name
        </div>
      ),
      cell: info => {
        return (
          <div
            style={{
              paddingLeft: 4,
            }}
          >
            {info.getValue()}
          </div>
        );
      },
    }),
    columnHelper.accessor('id', {
      header: () => (
        <div
          style={{
            paddingLeft: 4,
          }}
        >
          Pixel Value
        </div>
      ),
      cell: info => {
        return (
          <div
            style={{
              paddingLeft: 4,
            }}
          >
            {info?.row?.original?.slug
              ? coordinateRasterValueMap[info.row.original.slug]
              : ''}
          </div>
        );
      },
    }),
    columnHelper.accessor('slug', {
      header: () => (
        <div
          style={{
            paddingLeft: 4,
          }}
        >
          Description
        </div>
      ),
      cell: info => {
        return (
          <div
            style={{
              paddingLeft: 4,
              fontSize: '11px',
            }}
          >
            {descriptionHandler(info?.row?.original)}
          </div>
        );
      },
    }),
  ];

  const rowSelectHandler = row => {
    if (!rowIsSelected(row)) {
      doUpdateProductId(row.original.id);
    }
  };

  const table = useReactTable({
    data: rowData,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: import.meta.env.DEV,
  });

  let timerId;

  useEffect(() => {
    !!productFiles && doUpdateProductFileId(productFiles[0]?.id);
  }, [doUpdateProductFileId, productFiles]);

  return (
    <div className='p-0 table-wrapper'>
      <div className='h-2' />
      <table className='perma-table'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize(), whiteSpace: 'nowrap' }}
                  >
                    {header.isPlaceholder || header.id === 'status' ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                      </div>
                    )}
                    {header.index + 1 !== columns.length ? (
                      <>
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className='pl-2 resizer'
                        />
                        <div className='resizer-line' />
                      </>
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            const rowExpanded = row.getIsExpanded();
            return (
              <Fragment key={row.id}>
                <tr
                  onMouseDown={e => {
                    // Differentiate click from hold and rightclick for text highlighting
                    setRowSelectionCancelled(e.button === 2);
                    timerId = setTimeout(() => {
                      setRowSelectionCancelled(true);
                    }, 300);
                  }}
                  onMouseUp={() => {
                    if (!rowSelectionCancelled) rowSelectHandler(row);
                    clearTimeout(timerId);
                  }}
                  style={{
                    borderTop: '1.5px solid grey',
                    borderBottom: !rowExpanded ? '1.5px solid grey' : 'none',
                    userSelect: 'text',
                  }}
                  key={row.id}
                  className={getRowClasses(row)}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default PermaTable;
