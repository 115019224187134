import { useCallback } from 'react';
import tinycolor from 'tinycolor2';

import {
  getColorStops,
  colormaps,
  getColorStopsMatch,
  layerType,
  getNoDisplayValuesMatch,
} from '../../../utils/colormaps';

const useWebGLStyle = ({
  type,
  band = 1,
  nshades = 80,
  colors = colormaps['jet'],
  visible = true,
  minRange = Number.MIN_SAFE_INTEGER,
  maxRange = Number.MAX_SAFE_INTEGER,
  minFilter = Number.MIN_SAFE_INTEGER,
  maxFilter = Number.MAX_SAFE_INTEGER,
  nodata = undefined,
  clipMinColor = true,
  clipMaxColor = true,
  rangeInputBand = undefined,
  noDisplayValues = undefined,
}) => {
  const inputBand = rangeInputBand?.length ? rangeInputBand : ['band', band];

  const setStyle = useCallback(() => {
    if (visible) {
      return {
        minFilter,
        maxFilter,
      };
    } else {
      return {};
    }
  }, [visible, minFilter, maxFilter]);

  const nodataCase = nodata !== undefined ? [
    ['==', inputBand, nodata],
    [0, 0, 0, 0],
  ] : [];

  const filterCases =
    type === layerType.Categorical
      ? []
      : [
          ['<=', inputBand, ['var', 'minFilter']],
          [
            'case',
            ['==', minRange, ['var', 'minFilter']],
            clipMinColor && colors?.length
              ? Array.from(Object.values(tinycolor(colors[0]).toRgb()))
              : [0, 0, 0, 0],
            [0, 0, 0, 0],
          ],
          ['>=', inputBand, ['var', 'maxFilter']],
          [
            'case',
            ['==', maxRange, ['var', 'maxFilter']],
            clipMaxColor && colors?.length
              ? Array.from(
                  Object.values(tinycolor(colors[colors.length - 1]).toRgb()),
                )
              : [0, 0, 0, 0],
            [0, 0, 0, 0],
          ],
        ];

  const colorHandler = (type, visible) => {
    if (!visible) {
      return ['case', ['==', true, true], [0, 0, 0, 0], [0, 0, 0, 0]];
    } else if (type === layerType.Categorical) {
      return getColorStopsMatch({ colors, inputBand });
    } else {
      return [
        'case',
        ...getNoDisplayValuesMatch({noDisplayValues, inputBand}),
        ...nodataCase,
        ...filterCases,
        ...getColorStops({
          nshades,
          min: minRange,
          max: maxRange,
          colors,
          rangeInputBand: inputBand,
        }),
        [0, 0, 0, 0],
      ];
    }
  };

  const style = {
    variables: setStyle(visible),
    color: colorHandler(type, visible),
  };

  return [style, setStyle];
};

export default useWebGLStyle;
