import { config } from '../../config';
import { getToken } from '../../utils/userService';

const arrayIze = thing => (!thing || Array.isArray(thing) ? thing : [thing]);

const shouldSkipToken = (method, path, unless) => {
  let skip = false;

  // check for auth enabled
  if (config.ECW_AUTH_DISABLED) {
    skip = true;
  }

  // check for method
  if (!skip) {
    if (unless && unless.method) {
      const methods = arrayIze(unless.method);
      if (methods.indexOf(method) !== -1) skip = true;
    }
  }

  // check for path
  if (!skip) {
    if (unless && unless.path) {
      const paths = arrayIze(unless.path);
      if (paths.indexOf(path) !== -1) skip = true;
    }
  }

  // check custom
  if (!skip) {
    if (unless && unless.custom) {
      if (typeof unless.custom === 'function') {
        skip = unless.custom({ method: method, path: path });
      }
    }
  }

  return skip;
};

export const commonFetch = async (root, path, options) => {
  const res = await fetch(`${root}${path}`, options);
  if (!res.ok) throw new Error(res.statusText);
  return await res.json();
};

const getCommonItems = () => ({
  root: config.API_ROOT,
  unless: config.UNLESS,
  token: getToken(),
});

export const defaultHeaders = token => ({
  Authorization: `Bearer ${token}`,
});

export const apiFetch = (path, options = {}) => {
  const { root, unless, token } = getCommonItems();
  if (!shouldSkipToken(options.method, path, unless)) {
    if (!token) return null;
    else {
      options.headers = { ...defaultHeaders(token) };
    }
  }
  return fetch(`${root}${path}`, options);
};

export const apiGet = (path, callback) => {
  const { root, unless, token } = getCommonItems();
  const options = {
    method: 'GET',
  };
  if (!shouldSkipToken(options.method, path, unless)) {
    if (!token) return null;
    else {
      options.headers = { ...defaultHeaders(token) };
    }
  }
  return commonFetch(root, path, options, callback);
};

export const apiPut = (path, payload, callback) => {
  const { root, unless, token } = getCommonItems();
  const options = {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (!shouldSkipToken(options.method, path, unless)) {
    if (!token) return null;
    else {
      options.headers = {
        ...options.headers,
        ...defaultHeaders(token),
      };
    }
  }
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  return commonFetch(root, path, options, callback);
};

export const apiPost = (path, payload, callback) => {
  const { root, unless, token } = getCommonItems();
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (!shouldSkipToken(options.method, path, unless)) {
    if (!token) return null;
    else {
      options.headers = {
        ...options.headers,
        ...defaultHeaders(token),
      };
    }
  }
  if (payload) {
    options.body = JSON.stringify(payload);
  }
  return commonFetch(root, path, options, callback);
};

export const apiDelete = (path, callback) => {
  const { root, unless, token } = getCommonItems();
  const options = {
    method: 'DELETE',
  };
  if (!shouldSkipToken(options.method, path, unless)) {
    if (!token) return null;
    else {
      options.headers = { ...defaultHeaders(token) };
    }
  }
  return commonFetch(root, path, options, callback);
};
