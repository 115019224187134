import React from 'react';

import Button from '../../../../../app-components/button/button';
import { store } from '../../../../../stores/app-state/store';
import {
  useGetDrtsporeSelectionQuery,
  useDeleteDrtsporeMutation,
} from '../../../../../stores/server-state/drtspore';

const DeletePrompt = () => {
  const { drtsporeId, doUpdateDrtsporeId } = store.useDrtsporeId();
  const { doModalClose } = store.useDoModalClose();

  const { data: drtsporeSelection } = useGetDrtsporeSelectionQuery({
    drtsporeId,
  });
  const deleter = useDeleteDrtsporeMutation({ drtsporeId });

  const { name: drtsporeSelectionName } = drtsporeSelection;

  return (
    <div className='modal-dialog modal-dialog-centered'>
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>Confirm Deletion</h5>
        </div>
        <div className='modal-body'>
          <label htmlFor='deleteForm'>
            Are you sure you want to delete{' '}
            <strong>{drtsporeSelectionName}</strong>?
          </label>
        </div>
        <div id='deleteForm' className='modal-footer'>
          <Button
            text='Delete'
            variant='danger'
            className='my-0 my-0'
            handleClick={() => {
              deleter.mutate();
              doUpdateDrtsporeId('');
              doModalClose();
            }}
          />
          <Button
            className='ml-2 my-0'
            text='Cancel'
            variant='secondary'
            handleClick={() => doModalClose()}
          />
        </div>
      </div>
    </div>
  );
};

export default DeletePrompt;
