import React from 'react';
import { normalize, roundDown, roundUp } from '../../utils/helpers';
import './dualRangeSlider.scss';

const thumbsize = 14;

const DualRangeSlider = ({
  minRange = 0,
  maxRange = 1,
  minSliderValue = minRange,
  maxSliderValue = maxRange,
  onMinSliderChange = () => {},
  onMaxSliderChange = () => {},
}) => {
  minSliderValue = Math.max(minRange, minSliderValue);
  maxSliderValue = Math.min(maxRange, maxSliderValue);

  const [avg, setAvg] = React.useState((minRange + maxRange) / 2);

  const normalizedAvg = normalize(avg, minRange, maxRange);
  const normalizedMin = normalize(minSliderValue, minRange, maxRange);
  const normalizedMax = normalize(maxSliderValue, minRange, maxRange);

  const width = 242;
  const minWidth = thumbsize + (normalizedAvg / 1) * (width - 2 * thumbsize);
  const minPercent = Math.max(0, normalizedMin / normalizedAvg) * 100;
  const maxPercent =
    Math.min(1, (normalizedMax - normalizedAvg) / (1 - normalizedAvg)) * 100;

  const styles = {
    min: {
      width: minWidth,
      left: 0,
      '--minRangePercent': `${minPercent}%`,
    },
    max: {
      width: thumbsize + (1 - normalizedAvg) * (width - 2 * thumbsize),
      left: minWidth,
      '--maxRangePercent': `${maxPercent}%`,
    },
  };

  React.useLayoutEffect(() => {
    setAvg((maxSliderValue + minSliderValue) / 2);
  }, [maxSliderValue, minSliderValue]);

  return (
    <div
      className='dual-range-slider'
      data-legendnum='2'
      data-rangemin={minRange}
      data-rangemax={maxRange}
      data-thumbsize={thumbsize}
      data-rangewidth={width}
    >
      <input
        id='min'
        className='min'
        style={styles.min}
        name='min'
        type='range'
        step='0.01'
        min={minRange}
        max={avg}
        value={minSliderValue}
        onChange={({ target }) => {
          const n = Number(target.value);
          let p = 2;
          if (maxRange - minRange <= 1) {
            p = 2;
          }
          onMinSliderChange(roundDown(n, p));
        }}
      />
      <input
        id='max'
        className='max'
        style={styles.max}
        name='max'
        type='range'
        step='0.01'
        min={avg}
        max={maxRange}
        value={maxSliderValue}
        onChange={({ target }) => {
          const n = Number(target.value);
          let p = 2;
          if (maxRange - minRange <= 1) {
            p = 2;
          }
          onMaxSliderChange(roundUp(n, p));
        }}
      />
    </div>
  );
};

export default DualRangeSlider;
