import { getUid } from 'ol';

import {
  selectedStyle as defaultSelectedStyle,
  unselectedStyle as defaultUnselectedStyle,
  hoverStyle as defaultHoverStyle,
} from './styles/vectorStyles';

const defaultStyles = {
  hoverStyle: defaultHoverStyle,
  clickStyle: defaultSelectedStyle,
  defaultStyle: defaultUnselectedStyle,
};

export const getLayerByName = (layers = [], name = '') => {
  for (var i = 0, n = layers.length; i < n; ++i) {
    if (layers[i].get('name') === name) return layers[i];
  }
};

export const styleFn = ({ mapInstance, feature }) => {
  if (mapInstance.click === getUid(feature)) {
    return defaultStyles.clickStyle;
  } else if (mapInstance.pointermove === getUid(feature)) {
    return defaultStyles.hoverStyle;
  }
};

export const selectFeature = ({
  mapInstance,
  layer,
  updaterFn = () => {},
  resetFn = () => {},
  e,
}) => {
  const features = mapInstance.current.getFeaturesAtPixel(e.pixel);
  const status = mapInstance[e.type];
  if (features.length) {
    mapInstance[e.type] = getUid(features[0]);
    e.type === 'click' && updaterFn(features[0]);
  } else {
    mapInstance[e.type] = undefined;
    e.type === 'click' && resetFn();
  }
  if (status !== mapInstance[e.type]) {
    layer.changed();
  }
};

export const resetSelectFeature = ({ mapInstance, layer }) => {
  mapInstance.pointermove = undefined;
  layer.changed();
};

export const zoomToFeature = ({
  mapInstance,
  feature,
  shouldFocus = true,
  extent,
  zoomPadding = 100,
}) => {
  if (shouldFocus) {
    const view = mapInstance.current.getView();

    if (!feature) {
      view.fit(extent, { duration: 1500 });
    } else {
      const padding = new Array(4).fill(zoomPadding);
      view.fit(feature.getGeometry(), {
        duration: 1500,
        padding,
      });
    }
  }
};
