import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { initKeycloak } from './utils/userService';

const renderApp = () => {
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
};

initKeycloak(renderApp);
