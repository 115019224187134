import { useCallback, useState, useEffect } from 'react';

import VectorSource from 'ol/source/Vector';
import { WKT } from 'ol/format';

const useWKT = ({
  wkt = '',
  dataProjection = 'EPSG:4326',
  featureProjection = 'EPSG:4326',
}) => {
  const [wktSource, setWktSource] = useState(null);

  const createWktSource = useCallback(
    geom => {
      const wktFormat = new WKT();
      return new VectorSource({
        features: [
          wktFormat.readFeature(geom, {
            dataProjection,
            featureProjection,
          }),
        ],
      });
    },
    [dataProjection, featureProjection],
  );

  useEffect(() => {
    wkt && setWktSource(createWktSource(wkt));
    return () => {
      wkt && setWktSource(null);
    };
  }, [setWktSource, createWktSource, wkt]);

  useEffect(() => {
    return () => {
      setWktSource(null);
    };
  }, []);

  return wktSource;
};

export default useWKT;
