import React from 'react';
import Icon from '../icon';
import { mdiHelpCircleOutline } from '@mdi/js';
import { Tooltip } from 'react-tooltip';
import { classArray } from '../../utils/helpers';

const Card = ({
  header = '',
  tooltip = '',
  bodyStyle = null,
  className = '',
  children,
}) => {
  const cls = classArray(['card-body', className]);

  return (
    <div className='card mb-2'>
      {header ? <div className='card-header font-weight-light p-1'>
        <div className='row'>
          <span className='col ml-3 mt-2'>
            {header}
            {tooltip ? (
              <>
                <Icon
                  path={mdiHelpCircleOutline}
                  size={0.6}
                  className='tooltip-icon ml-2'
                  id={header}
                />
                <Tooltip
                  style={{ zIndex: 1000 }}
                  anchorId={header}
                  html={tooltip}
                ></Tooltip>
              </>
            ) : null}
          </span>
        </div>
      </div> : null}
      <div className={cls} style={bodyStyle}>
        {children}
      </div>
    </div>
  );
};

export default Card;
