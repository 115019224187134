import React from 'react';

import './toggle.scss';

const Toggle = ({
  isActive = false,
  onToggle = () => {},
  label = '',
  title = undefined,
}) => {
  return (
    <div>
      <div
        title={title ?? 'Toggle'}
        className='mt-0 toggle custom-control custom-switch'
      >
        <input
          id='flexSwitchCheckDefault'
          role='switch'
          type='checkbox'
          defaultChecked={isActive}
          onClick={onToggle}
          className='custom-control-input'
        />
        <label
          className='custom-control-label'
          htmlFor='flexSwitchCheckDefault'
        ></label>
      </div>
      <label className='toggle-label'>
        <small>{label}</small>
      </label>
    </div>
  );
};

export default Toggle;
