import React from 'react';

import Button from '../../../../../app-components/button/button';
import { store } from '../../../../../stores/app-state/store';
import {
  useGetMyWatershedSelectionQuery,
  useDeleteMyWatershedMutation,
} from '../../../../../stores/server-state/watersheds';

const MyWatershedsDeletePrompt = () => {
  const { myWatershedId, doUpdateMyWatershedId } = store.useMyWatershedId();
  const { doUpdateIsDeletePromptWatershed } =
    store.useIsDeletePromptWatershed();

  const { data: myWatershedSelection } = useGetMyWatershedSelectionQuery({
    myWatershedId,
  });
  const deleter = useDeleteMyWatershedMutation({ myWatershedId });

  const { name: myWatershedSelectionName } = myWatershedSelection;

  return (
    <div className='form-row'>
      <div className='form-group mr-3'>
        <label htmlFor='deleteForm'>
          <small>
            <strong>
              Are you sure you want to delete "{myWatershedSelectionName}"?
            </strong>
          </small>
        </label>
        <div id='deleteForm' className='mt-1'>
          <Button
            text='Delete'
            variant='danger'
            className='my-0 my-0'
            handleClick={() => {
              deleter.mutate();
              doUpdateMyWatershedId('');
              doUpdateIsDeletePromptWatershed(false);
            }}
          />
          <Button
            className='ml-2 my-0'
            text='Cancel'
            variant='secondary'
            handleClick={() => doUpdateIsDeletePromptWatershed(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default MyWatershedsDeletePrompt;
