import React, { useCallback, useEffect } from 'react';

import {
  Interactions,
  DrawInteraction,
} from '../../../../app-components/ol-map/interactions';
import { store } from '../../../../stores/app-state/store';
import { useGetDrtsporeSelectionQuery } from '../../../../stores/server-state/drtspore';

const DrtsporeMapDraw = () => {
  const { isDrawingDrtspore } = store.useIsDrawingDrtspore();
  const { drawDrtsporeShape } = store.useDrawDrtsporeShape();
  const { doUpdateDrawGeomDrtspore } = store.useDrawGeomDrtspore();
  const { drtsporeId, doUpdateDrtsporeId } = store.useDrtsporeId();
  const { data: drtsporeSelection } = useGetDrtsporeSelectionQuery({
    drtsporeId,
  });
  const { isSavePromptDrtspore, doUpdateIsSavePromptDrtspore } =
    store.useIsSavePromptDrtspore();

  const updateDrawGeomHandler = useCallback(
    geom => doUpdateDrawGeomDrtspore(geom),
    [doUpdateDrawGeomDrtspore],
  );

  const drawEnd = useCallback(() => {
    doUpdateDrtsporeId('');
    doUpdateIsSavePromptDrtspore(true);
  }, [doUpdateDrtsporeId, doUpdateIsSavePromptDrtspore]);

  const resetGeom = useCallback(
    () => doUpdateDrtsporeId(''),
    [doUpdateDrtsporeId],
  );

  useEffect(() => {
    return () => {
      drtsporeSelection?.length && resetGeom();
    };
  }, []);

  return (
    <Interactions>
      <DrawInteraction
        zIndex={2}
        drawShape={drawDrtsporeShape}
        isDrawing={isDrawingDrtspore}
        isSavePrompt={isSavePromptDrtspore}
        onDrawGeomChange={updateDrawGeomHandler}
        onDrawEnd={drawEnd}
        maxLength={[0.901, 0.439]}
      />
    </Interactions>
  );
};

export default DrtsporeMapDraw;
