import React, { useState, Fragment } from 'react';
import { store } from '../../../../stores/app-state/store';
import Button from '../../../../app-components/button';
import SubExpandTable from './subExpandTable';
import Icon from '../../../../app-components/icon';
import {
  mdiChevronRight,
  mdiChevronDown,
  mdiSort,
  mdiSortAscending,
  mdiSortDescending,
} from '@mdi/js';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { WKT } from 'ol/format';
import { transform } from 'ol/proj';
import { easeGridNorthMapConfig } from '../../../shared-components/mapContent/baseMapConfig';

import './expandTable.scss';

const ExpandTable = ({ rowData = [] }) => {
  const [expanded, setExpanded] = useState({});
  const [rowSelectionCancelled, setRowSelectionCancelled] = useState(false);

  const { doUpdateProductId } = store.useProductId();
  const { doUpdateProductFileId } = store.useProductFileId();
  const { doUpdateAnalogProductId } = store.useAnalogProductId();
  const { doUpdateAnalogProductFileId } = store.useAnalogProductFileId();
  const { doUpdateSourceProductId } = store.useSourceProductId();
  const { doUpdateSourceProductFileId } = store.useSourceProductFileId();
  const { doUpdateShowSource } = store.useShowSource();
  const { mySnowAnalogId, doUpdateMySnowAnalogId } = store.useMySnowAnalogId();
  const { myCoordinateId, doUpdateMyCoordinateId } = store.useMyCoordinateId();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('coordinate_name', {
      header: () => (
        <>
          <Button
            onClick={table.getToggleAllRowsExpandedHandler()}
            style={{ boxShadow: 'none' }}
            icon={
              <Icon
                path={
                  table.getIsAllRowsExpanded()
                    ? mdiChevronDown
                    : mdiChevronRight
                }
                size={0.7}
                className='mb-1'
              />
            }
            size='large'
            variant='link'
            title='Expand / Collapse All Rows'
            className='p-1 m-0'
          />
          Coordinate Name
        </>
      ),
      cell: ({ row, getValue }) => (
        <div
          style={{
            paddingLeft: 2,
          }}
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              row.toggleExpanded();
            }}
            onMouseUp={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            style={{ boxShadow: 'none' }}
            icon={
              <Icon
                path={row.getIsExpanded() ? mdiChevronDown : mdiChevronRight}
                size={0.7}
                className='mb-1'
              />
            }
            size='large'
            variant='link'
            title='Expand / Collapse Row'
            className='p-1 m-0'
            isDisabled={!row.getCanExpand()}
          />
          {getValue()}
        </div>
      ),

      footer: info => info.column.id,
      minSize: 230,
    }),
    columnHelper.accessor('create_date', {
      header: () => 'Created',
      cell: info => {
        const dateVal = info.getValue();

        if (dateVal)
          return new Date(dateVal).toLocaleString('en-US', { hour12: false });
        else return info.renderValue();
      },
      footer: info => info.column.id,
    }),
    columnHelper.accessor('geometry', {
      header: () => <span>Lat / Long</span>,
      cell: info => {
        // dont crash the app if unable to parse bad WKT string
        try {
          const wktText = info.getValue();
          const wktFormat = new WKT();
          const coords = wktFormat
            .readFeature(wktText)
            ?.getGeometry()
            ?.getCoordinates();
          const projection = easeGridNorthMapConfig?.projection;
          if (coords?.length && projection) {
            const lonlat = transform(coords, projection, 'EPSG:4326');
            return `${lonlat[1].toFixed(4)}, ${lonlat[0].toFixed(4)}`;
          } else return info.renderValue();
        } catch {
          // eslint-disable-next-line no-console
          console.error('error parsing WKT geometry for lat lon');
        }
      },
      footer: info => info.column.id,
    }),

    columnHelper.accessor('progress', {
      size: 10,
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: ({ getValue }) => {
        if (getValue() !== 100) {
          return (
            <>
              <div
                className='d-flex spinner-border spinner-border-sm p-1 m-0 mt-1'
                role='status'
              >
                <span className='sr-only'>Loading...</span>
              </div>
              <div>
                <small>{getValue() + '%'}</small>
              </div>
            </>
          );
        }
      },
      header: null,
      footer: info => info.column.id,
    }),
  ];

  const rowIsSelected = row => row.original.coordinate_id === myCoordinateId;

  const sortChangeHandler = sortType => {
    if (sortType === 'asc') {
      return mdiSortAscending;
    } else if (sortType === 'desc') return mdiSortDescending;
    else return mdiSort;
  };

  const updateLayerSelectionHandler = row => {
    if (row?.status === 'SUCCESS') {
      const snowAnalogSelection = row.snow_analogs.find(
        sa => sa.id === mySnowAnalogId,
      );
      if (!snowAnalogSelection) {
        const fileSelection = row.snow_analogs.find(fileObj =>
          fileObj?.analog_productfile?.product_slug?.includes(
            'aggregate-analog',
          ),
        );
        doUpdateMySnowAnalogId(fileSelection?.id ?? '');
        doUpdateAnalogProductId(
          fileSelection?.analog_productfile?.product_id ?? '',
        );
        doUpdateAnalogProductFileId(
          fileSelection?.analog_productfile?.id ?? '',
        );
        doUpdateProductId(fileSelection?.analog_productfile?.product_id ?? '');
        doUpdateProductFileId(fileSelection?.analog_productfile?.id ?? '');
        doUpdateSourceProductId('');
        doUpdateSourceProductFileId('');
        doUpdateShowSource(false);
      }
    } else {
      doUpdateShowSource(true);
      mySnowAnalogId && doUpdateMySnowAnalogId('');
    }
  };

  const rowSelectHandler = row => {
    if (!rowIsSelected(row)) {
      doUpdateMyCoordinateId(row.original.coordinate_id);
      updateLayerSelectionHandler(row.original);
    }
  };

  const getRowClasses = row => {
    return rowIsSelected(row) ? 'row-selected' : '';
  };

  const renderSubComponent = ({ row }) => {
    const { snow_analogs, coordinate_id, coordinate_name, status } =
      row.original;
    const sort_analogs = snow_analogs.sort((a, b) =>
      a?.analog_productfile?.product_slug.localeCompare(
        b?.analog_productfile?.product_slug,
      ),
    );

    return (
      <SubExpandTable
        rowData={sort_analogs}
        parentData={{ coordinate_id, coordinate_name, status }}
      />
    );
  };

  const updateMapLayerOnDataChange = () => {
    const selection = rowData.find(row => row.coordinate_id === myCoordinateId);
    updateLayerSelectionHandler(selection);
  };

  const table = useReactTable({
    data: rowData,
    columns,
    columnResizeMode: 'onChange',
    initialState: {
      sorting: [
        {
          id: 'create_date',
          desc: true,
        },
      ],
    },
    state: { expanded },
    onStateChange: updateMapLayerOnDataChange,
    getRowCanExpand: row => row.original.status === 'SUCCESS',
    renderSubComponent,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: import.meta.env.DEV,
  });

  let timerId;
  return (
    <div className='p-0 table-wrapper'>
      <div className='h-2' />
      <table className='expand-table'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize(), whiteSpace: 'nowrap' }}
                  >
                    {header.isPlaceholder || header.id === 'status' ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <Button
                          onClick={header.column.getToggleSortingHandler()}
                          icon={
                            header.id !== 'progress' ? (
                              <Icon
                                path={sortChangeHandler(
                                  header.column.getIsSorted(),
                                )}
                                size={0.7}
                                className='mb-1'
                              />
                            ) : null
                          }
                          variant='link'
                          style={{ boxShadow: 'none' }}
                        />
                      </div>
                    )}
                    {header.index + 1 !== columns.length ? (
                      <>
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className='pl-2 resizer'
                        />
                        <div className='resizer-line' />
                      </>
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            const rowExpanded = row.getIsExpanded();
            return (
              <Fragment key={row.id}>
                <tr
                  onMouseDown={e => {
                    // Differentiate click from hold and rightclick for text highlighting
                    setRowSelectionCancelled(e.button === 2);
                    timerId = setTimeout(() => {
                      setRowSelectionCancelled(true);
                    }, 300);
                  }}
                  onMouseUp={() => {
                    if (!rowSelectionCancelled) {
                      rowSelectHandler(row);
                    }
                    clearTimeout(timerId);
                  }}
                  style={{
                    borderTop: '1.5px solid grey',
                    borderBottom: !rowExpanded ? '1.5px solid grey' : 'none',
                    userSelect: 'text',
                  }}
                  key={row.id}
                  className={getRowClasses(row)}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
                {rowExpanded && (
                  <tr style={{ borderBottom: '1.5px solid grey' }}>
                    <td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandTable;
