import React from 'react';
import { Layers } from '../../../app-components/ol-map/layers';
import MapLayout from '../../shared-components/mapLayout/mapLayout';
import { WatershedMapContent } from './components';
import { easeGridNorthMapConfig } from '../../shared-components/mapContent/baseMapConfig';
import { store } from '../../../stores/app-state/store';
import { useGetProductFilesQuery } from '../../../stores/server-state/products';
import { useRasterLayersSettings } from '../../shared-components/mapContent/layerSettings';
import { Interactions } from '../../../app-components/ol-map/interactions';
import { PixelQueryInteraction } from '../../../app-components/ol-map/interactions/pixelQueryInteraction';
import { WCSDataTile } from '../../shared-components/mapContent/dataTile';

import 'react-tooltip/dist/react-tooltip.css';
import { sweWcsTileGrid } from '../../../app-components/ol-map/layers/layer-groups/baseLayerGroup';

const OlMap = ({ productSlug }) => {
  const { productId } = store.useProductId();
  const { dateSelection: timeISO } = store.useDateSelection();
  const { spatialResolution } = store.useSpatialResolution();

  const { data: productfiles, isLoading } = useGetProductFilesQuery({
    productId,
  });

  // TODO: make spatial resolution part of the product name name, not its own parameter
  // this is just a quick hack for a demo
  let layerName = 'CETB_SWE:nsidc1_daily_swe_last_in_max_n25km';
  if (spatialResolution === '6250.0') {
    layerName = 'CETB_SWE:nsidc1_daily_swe_last_in_max_n3_125km';
  }

  const wcsBaseUrl = `https://staging.nsidc.org/api/mapservices/CETB_SWE/wcs`;

  const layerSettings = useRasterLayersSettings({
    productId,
    productfiles,
    isLoading,
  });

  const rasterLayerProps = layerSettings.length ? layerSettings[0] : {};

  return (
    <MapLayout productSlug={productSlug} mapConfig={easeGridNorthMapConfig}>
      <Layers>
        <WatershedMapContent />
        <WCSDataTile
          {...rasterLayerProps}
          name={`${layerName}_${timeISO}`}
          baseUrl={wcsBaseUrl}
          layerName={layerName}
          timeISO={timeISO}
          tileGrid={sweWcsTileGrid}
        />
      </Layers>
      <Interactions>
        <PixelQueryInteraction />
      </Interactions>
    </MapLayout>
  );
};

export default OlMap;
