import React, { useRef } from 'react';

import Dropdown from '../../../../../../app-components/dropdown';
import { classArray } from '../../../../../../utils/helpers';

const ExportDropdown = ({
  text = '',
  buttonClasses = '',
  dropdownClasses = '',
  isDisabled = false,
  handleClick = () => {},
  className = '',
}) => {
  const dropdownRef = useRef();

  const handleOpen = () => {
    if (dropdownRef && dropdownRef.current) {
      dropdownRef.current.toggleDropdown();
    }
  };

  const classes = classArray([className]);

  return (
    <div className={classes}>
      <Dropdown.Menu
        dropdownClass={dropdownClasses}
        buttonClass={buttonClasses}
        handleClick={handleOpen}
        buttonContent={text}
        isDisabled={isDisabled}
      >
        <span className='pl-2 text-secondary'>
          <small>Chart</small>
        </span>
        <div className='dropdown-divider' />
        <Dropdown.Item onClick={e => handleClick(e.target.textContent)}>
          PNG
        </Dropdown.Item>
        <Dropdown.Item onClick={e => handleClick(e.target.textContent)}>
          JPEG
        </Dropdown.Item>
        <div className='dropdown-divider' />
        <span className='pl-2 text-secondary'>
          <small>Table</small>
        </span>
        <div className='dropdown-divider' />
        <Dropdown.Item onClick={e => handleClick(e.target.textContent)}>
          CSV
        </Dropdown.Item>
      </Dropdown.Menu>
    </div>
  );
};

export default ExportDropdown;
