import React, { forwardRef } from 'react';

import { Chart } from 'chart.js';
import { Line } from 'react-chartjs-2';
// eslint-disable-next-line
import _ from 'chart.js/auto'; // Register dep components

const LineChartWrapper = forwardRef(
  (
    { labels = [], datasets = [], options = {}, className, isHidden = false },
    ref,
  ) => {
    // Need to apply white background to canvas so the
    // background is not dark or transparent in file downloads
    const backgroundPlugin = {
      id: 'backgroundPlugin',
      beforeDraw: chart => {
        const ctx = chart.canvas.getContext('2d');
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      },
    };
    Chart.register(backgroundPlugin);

    const verticalLinePlugin = {
      id: 'verticalLine',
      getLinePosition: function (chart, pointIndex) {
        const meta = chart.getDatasetMeta(0); // first dataset is used to discover X coordinate of a point
        const data = meta.data;
        if (data[pointIndex]) return data[pointIndex].x;
      },
      renderVerticalLine: function (chart, pointIndex) {
        const lineLeftOffset = this.getLinePosition(chart, pointIndex);
        const scale = chart.scales.y;
        const context = chart.canvas.getContext('2d');

        // render vertical line
        context.beginPath();
        context.strokeStyle = '#ff8888';
        context.moveTo(lineLeftOffset, scale.top);
        context.lineTo(lineLeftOffset, scale.bottom);
        context.stroke();

        const lineLabel = chart.config.options.plugins.verticalLine.label;
        if (lineLabel) {
          // write label
          context.fillStyle = '#ff0000';
          context.textAlign = 'center';
          context.font = '11px "Helvetica Neue", Helvetica, Arial, sans-serif';
          context.fillText(
            lineLabel,
            lineLeftOffset - 20,
            (scale.bottom - scale.top) / 2 + scale.top - 135,
          );
        }
      },
      afterDatasetsDraw: function (chart, _) {
        const indexArray = chart.config.options.plugins.verticalLine.indexArray;
        if (indexArray) {
          indexArray.forEach(pointIndex =>
            this.renderVerticalLine(chart, pointIndex),
          );
        }
      },
    };
    Chart.register(verticalLinePlugin);

    return (
      <div className={!isHidden ? 'line-chart' : 'd-none'}>
        <Line
          ref={ref}
          className={className}
          data={{
            labels: labels,
            datasets: datasets,
          }}
          options={options}
        />
      </div>
    );
  },
);

export default LineChartWrapper;
