import { useEffect } from 'react';

import { useOLMap } from '../../olMap';
import { store } from '../../../../stores/app-state/store';
import {
  styleFn,
  selectFeature,
  resetSelectFeature,
  zoomToFeature,
} from '../../ol-map-helpers';
import { useVectorTileLayer } from '../../hooks';

import LayerGroup from 'ol/layer/Group';

import {
  selectedStyle as defaultSelectedStyle,
  unselectedStyle as defaultUnselectedStyle,
  hoverStyle as defaultHoverStyle,
} from '../../styles/vectorStyles';

const HoverVectorLayerGroup = ({
  title,
  source,
  zIndex,
  updateSelection = () => {},
  resetSelection = () => {},
  zoomPadding,
  styles = {
    selectedStyle: defaultSelectedStyle,
    unselectedStyle: defaultUnselectedStyle,
    hoverStyle: defaultHoverStyle,
  },
}) => {
  const { mapInstance, map, extent } = useOLMap();
  const { isAutoFocus } = store.useIsAutoFocus();

  const baseLayer = useVectorTileLayer({
    source,
    extent,
    zIndex,
    name: 'baseLayer',
    style: styles.unselectedStyle,
  });

  const styleLayer = useVectorTileLayer({
    source,
    extent,
    zIndex: zIndex + 1,
    name: 'clickLayer',
    style: feature => styleFn({ mapInstance, feature }),
    renderMode: 'vector',
    updateWhileAnimating: true,
  });

  useEffect(() => {
    const selectFeatureHandler = e =>
      selectFeature({
        mapInstance,
        layer: styleLayer,
        updaterFn: updateSelection,
        resetFn: resetSelection,
        e,
      });
    const mouseoutHoverHandler = () =>
      resetSelectFeature({ mapInstance, layer: styleLayer });
    const zoomToFeatureHandler = e =>
      zoomToFeature({
        mapInstance,
        feature: mapInstance.current.getFeaturesAtPixel(e.pixel)?.at(0),
        shouldFocus: isAutoFocus,
        extent,
        zoomPadding,
      });

    let layerGroup;
    if (baseLayer && styleLayer) {
      layerGroup = new LayerGroup({ title, layers: [baseLayer, styleLayer] });
      map.addLayer(layerGroup);
      map.on(['pointermove', 'click'], selectFeatureHandler);
      map.on('click', zoomToFeatureHandler);
      map.getViewport().addEventListener('mouseout', mouseoutHoverHandler);
    }
    return () => {
      if (layerGroup) {
        mapInstance.click = undefined;
        map.un(['pointermove', 'click'], selectFeatureHandler);
        map.un('click', zoomToFeatureHandler);
        map.getViewport().removeEventListener('mouseout', mouseoutHoverHandler);
        map.removeLayer(layerGroup);
      }
    };
  }, [
    baseLayer,
    styleLayer,
    extent,
    isAutoFocus,
    map,
    mapInstance,
    source,
    styles.unselectedStyle,
    title,
    updateSelection,
    zIndex,
    zoomPadding,
  ]);

  useEffect(() => {
    return () => {
      resetSelection();
    };
  }, [resetSelection]);

  return null;
};

export default HoverVectorLayerGroup;
