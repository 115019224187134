import React, { useCallback, useEffect } from 'react';
import { store } from '../../stores/app-state/store';

const Modal = ({ closeWithEscape = false }) => {
  const { doModalClose } = store.useDoModalClose();
  const { ModalContent } = store.useModalContent();
  const { modalProps } = store.useModalProps();

  const closeModalWithEscape = useCallback(
    e => {
      if (e.keyCode === 27) doModalClose();
    },
    [doModalClose],
  );

  useEffect(() => {
    if (closeWithEscape) {
      document.addEventListener('keydown', closeModalWithEscape);

      if (!ModalContent) {
        document.removeEventListener('keydown', closeModalWithEscape);
      }
    }
  }, [ModalContent, closeWithEscape, closeModalWithEscape]);

  return (
    !!ModalContent && (
      <>
        <div
          onClick={doModalClose}
          className='modal fade show'
          style={{
            display: 'block',
            backgroundColor: '#ccc',
            opacity: 0.5,
            zIndex: 9998,
          }}
        />
        <div
          className='modal fade show'
          style={{ display: 'block', zIndex: 9999 }}
        >
          <ModalContent {...modalProps} />
        </div>
      </>
    )
  );
};

export default Modal;
