import { gen } from './helpers';

export const createDrtsporeSlice = set => ({
  ...gen(set, {
    drtsporeId: '',
    drtsporeName: '',
    drtsporeDateRange: [],
    drtsporeTimeOptions: [],
    drawDrtsporeShape: 'Box',
    drawGeomDrtspore: '',
    isDrawingDrtspore: false,
    isSavePromptDrtspore: false,
    shouldFetchDrtspores: true,
  }),
});
