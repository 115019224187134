import { toast } from 'react-toastify';
import { tError, tSuccess } from '../../utils/toast-helpers';
import { apiGet, apiPost, apiPut, apiDelete } from './api-helpers';
import { useQuery, useMutation } from '@tanstack/react-query';
import { queryClient } from '../../App';
import { store } from '../app-state/store';
import { config } from '../../config';

// TODO: Move HYBAS dataset from GeoServer to API
// export const useGetWatershedsQuery = (opts = null) => {
//   const uri = '/products/parameter/ECW';

//   return useQuery(['products'], () => apiGet(uri), opts);
// };

export const useGetCoverageSweBlobWcsQuery = ({ coverageId, timestamp, projectionCode }) => {
  if (!config.WATERSHED_STATS_WCS_URL) {
    throw new Error("VITE_WATERSHED_STATS_WCS_URL is undefined")
  }

  const url = `${config.WATERSHED_STATS_WCS_URL}?request=GetCoverage&version=2.0.1&format=image/tiff&coverageid=${coverageId}&compression=LZW&cql_filter=timestamp=${timestamp}&crs=EPSG:${projectionCode}`
    
  return useQuery(['wcs', 'swe', coverageId, timestamp, projectionCode], () => fetch(url).then(res => res.blob()), {
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: !!(coverageId && timestamp && projectionCode),
  });
}

export const useGetMyWatershedsQuery = (opts = null) => {
  const uri = '/my_watersheds';

  return useQuery(['my_watersheds'], () => apiGet(uri), opts);
};

export const useGetMyWatershedSelectionQuery = ({ myWatershedId }) => {
  return useGetMyWatershedsQuery({
    select: ws => ws.find(w => w.id === myWatershedId),
    enabled: !!myWatershedId?.length,
  });
};

export const useCreateMyWatershedMutation = () => {
  const { doUpdateMyWatershedId } = store.useMyWatershedId();

  const uri = '/my_watersheds';

  return useMutation(formData => apiPost(uri, formData), {
    onMutate: () => {
      const toastId = toast.loading('Creating new watershed...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to create. Try again later.');
    },
    onSuccess: (body, _, ctx) => {
      queryClient.invalidateQueries(['my_watersheds']);
      doUpdateMyWatershedId(body.id);
      tSuccess(ctx.toastId, 'Successfully created new watershed!');
    },
  });
};

export const useUpdateMyWatershedMutation = ({ myWatershedId }) => {
  const { doUpdateMyWatershedId } = store.useMyWatershedId();

  const uri = `/my_watersheds/${myWatershedId}`;

  return useMutation(formData => apiPut(uri, formData), {
    onMutate: () => {
      const toastId = toast.loading('Updating watershed name...');
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to update. Try again later.');
    },
    onSuccess: (body, _, ctx) => {
      queryClient.invalidateQueries(['my_watersheds']);
      doUpdateMyWatershedId(body.id);
      tSuccess(ctx.toastId, 'Successfully updated watershed name!');
    },
  });
};

export const useDeleteMyWatershedMutation = ({ myWatershedId }) => {
  const { doUpdateMyWatershedId } = store.useMyWatershedId();

  const uri = `/my_watersheds/${myWatershedId}`;

  return useMutation(() => apiDelete(uri), {
    onMutate: () => {
      const toastId = toast.loading(
        'Deleting watershed and associated statistics...',
      );
      return { toastId };
    },
    onError: (_, __, ctx) => {
      tError(ctx.toastId, 'Failed to delete. Try again later.');
    },
    onSuccess: (_, __, ctx) => {
      doUpdateMyWatershedId('');
      queryClient.invalidateQueries(['my_watersheds']);
      tSuccess(
        ctx.toastId,
        'Successfully deleted watershed and associated statistics!',
      );
    },
  });
};

export const useGetWatershedStatsQuery = ({
  watershedId,
  spatialResolution,
  isMyWatersheds,
}) => {
  const uri = `/watersheds/${watershedId}/statistics/${spatialResolution}`;

  return useQuery(
    ['watersheds', watershedId, 'statistics', spatialResolution],
    () => apiGet(uri),
    {
      enabled:
        !!watershedId?.length && !!spatialResolution?.length && !isMyWatersheds,
    },
  );
};

export const useGetMyWatershedStatsQuery = ({
  myWatershedId,
  spatialResolution,
  isMyWatersheds,
}) => {
  const { isWatershedStatsLoading, doUpdateIsWatershedStatsLoading } =
    store.useIsWatershedStatsLoading();

  const uri = `/my_watersheds/${myWatershedId}/statistics/${spatialResolution}`;

  return useQuery(
    ['watersheds', myWatershedId, 'statistics', spatialResolution],
    () => apiGet(uri),
    {
      enabled:
        !!myWatershedId?.length &&
        !!spatialResolution?.length &&
        isMyWatersheds,
      refetchInterval: body => {
        if (
          body &&
          body?.message !== 'Job In Progress' &&
          body?.records?.length &&
          Object.keys(body?.records[0]).length > 5
        ) {
          isWatershedStatsLoading && doUpdateIsWatershedStatsLoading(false);
          return false; // don't fetch
        }
        !isWatershedStatsLoading && doUpdateIsWatershedStatsLoading(true);
        return 1000 * 10; // refetch every 10 seconds
      },
      refetchIntervalInBackground: true,
    },
  );
};
