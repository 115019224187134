import React from 'react';
import { useOLMap } from '../olMap';
import { store } from '../../../stores/app-state/store';
import { roundIfNeeded } from '../../../utils/helpers';
import { useGetMyCoordinateSelectionQuery } from '../../../stores/server-state/coordinates';
import { useWKT } from '../hooks';
import { transform } from 'ol/proj';

export const PixelQueryInteraction = ({ event = 'pointermove' }) => {
  const { map, projection } = useOLMap();
  const { myCoordinateId } = store.useMyCoordinateId();
  const { doUpdateRasterValueMap } = store.useRasterValueMap();
  const { doUpdateCoordinateRasterValueMap } = store.useCoordinateRasterValueMap();
  const { drawGeomCoordinate } = store.useDrawGeomCoordinate();
  const { doUpdateLatLonValue } = store.useLatLonValue();
  const { data: myCoordinateSelection } = useGetMyCoordinateSelectionQuery({
    myCoordinateId,
  });

  const wktSource = useWKT({
    wkt: myCoordinateSelection?.geometry || drawGeomCoordinate,
    dataProjection: projection,
    featureProjection: projection,
  });

  React.useEffect(() => {
    const layers = map.getLayers();

    const pixelHandler = (e, coordinateSrc) => {
      const feature = coordinateSrc?.getFeatures()[0];
      const coordinateOverride = feature?.getGeometry()?.getCoordinates();

      const px = coordinateOverride
        ? map.getPixelFromCoordinate(coordinateOverride)
        : e?.pixel;

      let hoverValueMap = {};
      let coordinateValueMap = {};

      layers.forEach(layer => {
        const name = layer.get('name');
        if (name && layer?.getSource()) {
          let hoverData;
          let intersectData;
          try {
            hoverData = layer?.getData(e?.pixel);
            intersectData = px !== undefined ? layer?.getData(px) : undefined;
          } catch (error) {
            // Sometimes the GL context is lost which causes this to throw
            // No noticable impact on funcionality, seems to correct on next render cycle
          }
          const hoverValue = hoverData?.length ? hoverData[0] : '';
          const intersectValue = intersectData?.length ? intersectData[0] : '';

          hoverValueMap = { [name]: roundIfNeeded(hoverValue, 0, 2), ...hoverValueMap };
          coordinateValueMap = { [name]: roundIfNeeded(intersectValue, 0, 2), ...coordinateValueMap };
        }
      });

      doUpdateRasterValueMap(hoverValueMap);
      doUpdateCoordinateRasterValueMap(coordinateValueMap);
    };

    const latLonHandler = e => {
      doUpdateLatLonValue(
        transform(
          e.coordinate,
          projection,
          'EPSG:4326',
        ).reverse(),
      );
    };
    map.on(event, e => pixelHandler(e, wktSource));
    map.on(event, latLonHandler);

    if (wktSource) {
      pixelHandler(null, wktSource)
    }

    return () => {
      map.un(event, e => pixelHandler(e, wktSource));
      map.un(event, latLonHandler);
    };
  }, [event, map, wktSource, drawGeomCoordinate]);

  return null;
};
