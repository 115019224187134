import React from 'react';

import { useOLMap } from '../olMap';
import { store } from '../../../stores/app-state/store';
import { isNullOrUndefined } from '../../../utils/helpers';
import WebGLTile from 'ol/layer/WebGLTile';

const WebGLTileLayer = ({
  source,
  name = '',
  zIndex = 0,
  style = undefined,
  visible = true,
  minFilter,
  maxFilter,
  getVariables,
}) => {
  const [layer, setLayer] = React.useState();

  const { map } = useOLMap();
  const { rasterOpacity } = store.useRasterOpacity(undefined);

  React.useEffect(() => {
    let tileLayer;
    if (name && style) {
      tileLayer = new WebGLTile({
        name,
        zIndex,
        opacity: rasterOpacity,
        type: 'raster',
      });
    }
    tileLayer && map.addLayer(tileLayer);
    tileLayer && setLayer(tileLayer);
    return () => {
      tileLayer && map.removeLayer(tileLayer);
      tileLayer && setLayer(undefined);
    };
  }, [map, name, zIndex]);

  React.useEffect(() => {
    source && layer?.setSource(source);
  }, [layer, source]);

  React.useEffect(() => {
    if (visible && style) {
      !isNullOrUndefined(rasterOpacity) && layer?.setOpacity(rasterOpacity);
    }
  }, [visible, style, layer, rasterOpacity]);

  React.useEffect(() => {
    !isNullOrUndefined(visible) && layer?.setVisible(visible);
  }, [layer, visible]);

  React.useEffect(() => {
    if (
      layer &&
      !isNullOrUndefined(minFilter) &&
      !isNullOrUndefined(maxFilter)
    ) {
      layer.updateStyleVariables(getVariables());
    }
  }, [layer, minFilter, maxFilter]);

  React.useEffect(() => {
    const addSpinnerHandler = () =>
      map.getTargetElement().classList.add('spinner');
    const removeSpinnerHandler = () =>
      map.getTargetElement().classList.remove('spinner');

    map.on('loadstart', addSpinnerHandler);
    map.on('loadend', removeSpinnerHandler);

    return () => {
      map.un('loadstart', addSpinnerHandler);
      map.un('loadend', removeSpinnerHandler);
    };
  }, [map]);

  // Ensure canvas uses full width
  React.useEffect(() => {
    map.updateSize();
  }, [map, layer]);

  React.useEffect(() => {
    style && layer?.setStyle(style);
  }, [layer, style]);

  return null;
};

export default WebGLTileLayer;
