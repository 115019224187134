import create from 'zustand';
import { devtools } from 'zustand/middleware';
import { createSelectors } from './helpers';

import { createProductSlice } from './product-store';
import { createWatershedSlice } from './watershed-store';
import { createSnowAnalogSlice } from './snow-analog-store';
import { createDrtsporeSlice } from './drtspore-store';
import { createMapControlSlice } from './map-control-store';
import { createModalSlice } from './modal-store';

const zStore = devtools(
  (...a) => ({
    ...createProductSlice(...a),
    ...createWatershedSlice(...a),
    ...createSnowAnalogSlice(...a),
    ...createMapControlSlice(...a),
    ...createDrtsporeSlice(...a),
    ...createModalSlice(...a),
  }),
  {
    name: 'Zustand Global Store',
    enabled: import.meta.env.DEV,
  },
);

export const store = createSelectors(create(zStore));
