import React, { useCallback } from 'react';

import { config } from '../../../../config';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Layers, VectorLayer } from '../../../../app-components/ol-map/layers';
import {
  Interactions,
  DrawInteraction,
} from '../../../../app-components/ol-map/interactions';
import { HoverVectorLayerGroup } from '../../../../app-components/ol-map/layers/layer-groups';
import {
  useWKT,
  useMVT,
  useAutoFocus,
} from '../../../../app-components/ol-map/hooks';

import { useOLMap } from '../../../../app-components/ol-map/olMap';
import { store } from '../../../../stores/app-state/store';
import { useGetMyWatershedSelectionQuery } from '../../../../stores/server-state/watersheds';

const WatershedMapContent = () => {
  const { isMyWatersheds } = store.useIsMyWatersheds();
  const { isDrawingWatershed } = store.useIsDrawingWatershed();
  const { drawShape } = store.useDrawShape();
  const { doUpdateDrawGeomWatershed } = store.useDrawGeomWatershed();
  const { doUpdateWatershedId } = store.useWatershedId();
  const { doUpdateWatershedName } = store.useWatershedName();
  const { myWatershedId, doUpdateMyWatershedId } = store.useMyWatershedId();
  const { isSavePromptWatershed, doUpdateIsSavePromptWatershed } =
    store.useIsSavePromptWatershed();

  const { data: myWatershedSelection } = useGetMyWatershedSelectionQuery({
    myWatershedId,
  });

  const { projection } = useOLMap();

  const geoserverRoot = config.GS_ROOT,
    geoserverMvtLayer = config.GS_MVT_LAYER_NAME;

  const mvtReady = geoserverRoot && geoserverMvtLayer;

  const mvtSource = useMVT({
    url:
      geoserverRoot +
      '/gwc/service/tms/1.0.0/' +
      geoserverMvtLayer +
      '@EPSG%3A' +
      projection.getCode().split(':')[1] +
      '@pbf/0/0/0.pbf',
    projection,
    idProperty: 'id',
    tileGrid: new TileGrid({
      origin: [-9009964.761231285, 9009964.761231285],
      resolutions: [70390.34969711941],
      tileSize: 256,
    }),
  });

  const wktSource = useWKT({
    wkt: myWatershedSelection?.geometry,
    dataProjection: projection,
    featureProjection: projection,
  });

  const [focus, setFocus] = useAutoFocus({
    source: !isMyWatersheds ? mvtSource : wktSource,
    shouldReset: true,
  });

  const sourceChangeHandler = useCallback(setFocus, [setFocus]);

  const resetWatershedHandler = useCallback(() => {
    doUpdateWatershedId('');
    doUpdateWatershedName('');
  }, [doUpdateWatershedId]);

  const updateWatershedHandler = useCallback(
    selection => {
      if (selection) {
        const watershedId = selection?.getId();
        const watershedName = selection?.getProperties().name;
        doUpdateWatershedId(watershedId ?? '');
        doUpdateWatershedName(watershedName ?? '');
      }
    },
    [doUpdateWatershedId],
  );

  const updateDrawGeomHandler = useCallback(
    geom => doUpdateDrawGeomWatershed(geom),
    [doUpdateDrawGeomWatershed],
  );
  const drawEnd = useCallback(() => {
    doUpdateMyWatershedId('');
    doUpdateIsSavePromptWatershed(true);
  }, [doUpdateMyWatershedId, doUpdateIsSavePromptWatershed]);

  const mapContentHandler = () => {
    if (!isMyWatersheds && mvtSource) {
      return (
        <HoverVectorLayerGroup
          zIndex={2}
          title='HydroBASINS'
          source={mvtReady ? mvtSource : undefined}
          updateSelection={updateWatershedHandler}
          resetSelection={resetWatershedHandler}
          zoomPadding={125}
        />
      );
    } else if (isMyWatersheds && wktSource && !isDrawingWatershed) {
      return (
        <VectorLayer
          zIndex={2}
          title='My Watersheds'
          source={wktSource}
          zoomSource={focus}
        />
      );
    } else return null;
  };

  return (
    <>
      <Layers>{mapContentHandler()}</Layers>
      <Interactions>
        {isMyWatersheds ? (
          <DrawInteraction
            zIndex={2}
            drawShape={drawShape}
            isDrawing={isDrawingWatershed}
            isSavePrompt={isSavePromptWatershed}
            onDrawGeomChange={updateDrawGeomHandler}
            onSourceChange={sourceChangeHandler}
            onDrawEnd={drawEnd}
          />
        ) : null}
      </Interactions>
    </>
  );
};

export default WatershedMapContent;
