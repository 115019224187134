import React from 'react';

import HydroBasinsSelect from './components/hydroBasinsSelect';
import MyWatershedsSelect from './components/myWatershedsSelect';
import MyWatershedForm from './components/myWatershedForm';
import MyWatershedsDeletePrompt from './components/myWatershedsDeletePrompt';
import { Tooltip } from 'react-tooltip';

import Icon from '../../../../app-components/icon';
import { mdiHelpCircleOutline } from '@mdi/js';

import 'react-tooltip/dist/react-tooltip.css';

const SelectWatershedCard = ({
  isMyWatersheds,
  onToggleWatersheds,
  selectedFeatureId,
  savePrompt,
  deletePrompt,
}) => {
  const watershedToggleHandler = (e, value) => {
    e.preventDefault();
    onToggleWatersheds(value);
  };

  return (
    <div className='card mb-2'>
      <div className='card-header font-weight-light p-2'>
        <div className='row'>
          <span className='col ml-3 mt-2'>
            Select Boundary
            <Icon
              path={mdiHelpCircleOutline}
              size={0.6}
              id='watershed-help'
              className='tooltip-icon ml-2'
            />
            <Tooltip style={{ zIndex: 1000 }} anchorId='watershed-help'>
              <span>
                <strong>HydroBASINS</strong>: Select from the pre-defined
                watershed boundaries on the map
                <br />
                <strong>My Watersheds</strong>: Select, create, and manage
                custom watershed boundaries
              </span>
            </Tooltip>
          </span>
          <div className='d-flex justify-content-end ml-3 mr-4 mb-1'>
            <ul
              className='nav nav-tabs nav-fill card-header-tabs'
              id='watershed-list'
              role='tablist'
            >
              <li className='nav-item mr-3'>
                <a
                  href='/watershed-statistics'
                  className={`nav-link ${!isMyWatersheds ? 'active' : ''}`}
                  role='tab'
                  aria-controls='hydrobasins'
                  aria-selected={!isMyWatersheds ? 'true' : 'false'}
                  onClick={e => watershedToggleHandler(e, 'HydroBASINS')}
                >
                  HydroBASINS
                </a>
              </li>
              <li className='nav-item mr-1'>
                <a
                  href='/watershed-statistics'
                  className={`nav-link ${isMyWatersheds ? 'active' : ''}`}
                  role='tab'
                  aria-controls='my-watersheds'
                  aria-selected={isMyWatersheds ? 'true' : 'false'}
                  onClick={e => watershedToggleHandler(e, 'My Watersheds')}
                >
                  My Watersheds
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='pt-2 px-4'>
        {!isMyWatersheds && (
          <HydroBasinsSelect selectedFeatureId={selectedFeatureId} />
        )}
        {isMyWatersheds && !deletePrompt && !savePrompt && (
          <MyWatershedsSelect />
        )}
        {isMyWatersheds && deletePrompt && !savePrompt && (
          <MyWatershedsDeletePrompt />
        )}
        {isMyWatersheds && savePrompt && <MyWatershedForm />}
      </div>
    </div>
  );
};

export default SelectWatershedCard;
