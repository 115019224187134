import React from 'react';

import PermaTable from './permaTable';

import Card from '../../../../app-components/card/card';
import 'react-tooltip/dist/react-tooltip.css';

const SelectRasterCard = ({ products }) => {
  const rowData = products?.length ? products : [];

  return (
    <Card
      header='Select Product'
      tooltip={`<span>Select a product to display on the map</span>`}
      className='p-2'
    >
      <PermaTable rowData={rowData} />
    </Card>
  );
};

export default SelectRasterCard;
