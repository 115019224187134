import React from 'react';
import { Layers } from '../../../app-components/ol-map/layers';
import MapLayout from '../../shared-components/mapLayout/mapLayout';
import RasterLayer from '../../shared-components/mapContent/rasterLayer';
import { CoordinateMapContent } from './components';
import { store } from '../../../stores/app-state/store';
import { easeGridNorthMapConfig } from '../../shared-components/mapContent/baseMapConfig';
import { Interactions } from '../../../app-components/ol-map/interactions';
import { PixelQueryInteraction } from '../../../app-components/ol-map/interactions/pixelQueryInteraction';
import { useRasterLayersSettings } from '../../shared-components/mapContent/layerSettings';

import 'react-tooltip/dist/react-tooltip.css';
import { useGetProductFilesQuery } from '../../../stores/server-state/products';

const OlMap = ({ productSlug }) => {
  const { productId } = store.useProductId();
  const { analogProductId } = store.useAnalogProductId();
  const { analogProductFileId } = store.useAnalogProductFileId();
  const { sourceProductId } = store.useSourceProductId();
  const { sourceProductFileId } = store.useSourceProductFileId();

  const { data: analogProductFiles, isLoading: analogIsLoading } =
    useGetProductFilesQuery({
      productId: analogProductId,
    });

  const analogLayerSettings = useRasterLayersSettings({
    productId: analogProductId,
    productfiles: analogProductFiles,
    isLoading: analogIsLoading,
  });

  const analogRasterLayerProps =
    analogLayerSettings?.find(cfg => cfg.id === analogProductFileId) ?? {};

  const { data: sourceProductFiles, isLoading: sourceIsLoading } =
    useGetProductFilesQuery({
      productId: sourceProductId,
    });

  const sourceLayerSettings = useRasterLayersSettings({
    productId: sourceProductId,
    productfiles: sourceProductFiles,
    isLoading: sourceIsLoading,
  });

  const sourceRasterLayerProps =
    sourceLayerSettings?.find(cfg => cfg.id === sourceProductFileId) ?? {};

  return (
    <MapLayout productSlug={productSlug} mapConfig={easeGridNorthMapConfig}>
      <Layers>
        <CoordinateMapContent nPixelsRadius={10} />
        {analogRasterLayerProps && (
          <RasterLayer
            visible={productId === analogProductId}
            {...analogRasterLayerProps}
          />
        )}
        {sourceRasterLayerProps && (
          <RasterLayer
            visible={productId === sourceProductId}
            {...sourceRasterLayerProps}
          />
        )}
      </Layers>
      <Interactions>
        <PixelQueryInteraction />
      </Interactions>
    </MapLayout>
  );
};

export default OlMap;
