import React from 'react';
import { store } from '../../../stores/app-state/store';
import { WebGLTileLayer } from '../../../app-components/ol-map/layers';
import { defaultHeaders } from '../../../stores/server-state/api-helpers';
import { getToken } from '../../../utils/userService';
import {
  useWebGLStyle,
  useGeoTIFF,
} from '../../../app-components/ol-map/hooks';
import { mapConfigHandler } from '../../../utils/colormaps';

const RasterLayer = ({
  name = '',
  urls = [],
  blob = undefined,
  nshades = 80,
  visible = true,
  visibleStyle = true,
  colors,
  type,
  min,
  max,
  minRange,
  maxRange,
  clipMinColor,
  clipMaxColor,
  nodata,
  interpolate,
  noDisplayValues,
}) => {
  const { minFilter } = store.useMinFilter();
  const { maxFilter } = store.useMaxFilter();

  const { noDisplayValues: noDisplayValuesDefault, attributions } =
    mapConfigHandler(name);

  const source = useGeoTIFF({
    urls,
    blob,
    min,
    max,
    attributions,
    interpolate,
    sourceOptions: {
      credentials: 'omit',
      headers: defaultHeaders(getToken()),
    },
  });

  const [style, setStyle] = useWebGLStyle({
    type,
    colors,
    nshades,
    clipMinColor,
    clipMaxColor,
    minRange,
    maxRange,
    minFilter,
    maxFilter,
    nodata,
    visible: visibleStyle,
    noDisplayValues: noDisplayValues ?? noDisplayValuesDefault,
  });

  return (
    <WebGLTileLayer
      name={name}
      source={source}
      style={style}
      visible={visible}
      minFilter={minFilter}
      maxFilter={maxFilter}
      getVariables={setStyle}
    />
  );
};

export default RasterLayer;
