import React from 'react';
import { Link } from '@tanstack/react-location';

import { classArray } from '../../../../utils/helpers';
import Button from '../../../../app-components/button';

const HomePageCard = ({
  title = '',
  subtitle = '',
  buttonText = '',
  hrefButton = [''],
  hrefTitle = [''],
  className = '',
  children,
  style = {},
  titleLinkDisabled = false,
}) => {
  const classes = classArray(['card', 'border-secondary', 'm-3', className]);

  return (
    <div className={classes} style={style}>
      <div className='card-header'>
        {title ? (
          <Link
            to={hrefTitle}
            onClick={e => {
              if (titleLinkDisabled) e.preventDefault();
            }}
          >
            <h5 className={`p-0 m-0 mt-2 ${hrefTitle && 'text-link'}`}>
              {title}
            </h5>
          </Link>
        ) : null}
      </div>
      <div className='card-body'>
        {subtitle ? (
          <h6 className='card-subtitle text-secondary mb-2'>{subtitle}</h6>
        ) : null}
        <div className='card-text'>{children}</div>
        {buttonText ? (
          <Button
            text={buttonText}
            className='btn btn-primary'
            href={hrefButton}
          />
        ) : null}
      </div>
    </div>
  );
};
export default HomePageCard;
