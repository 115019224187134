import React from 'react';

import { altKeyOnly } from 'ol/events/condition';

import OLMap from '../../../app-components/ol-map';
import { Layers } from '../../../app-components/ol-map/layers';
import {
  Interactions,
  DragRotateInteraction,
} from '../../../app-components/ol-map/interactions';
import {
  BaseLayerGroup,
  LabelLayerGroup,
} from '../../../app-components/ol-map/layers/layer-groups';

import './mapContent.scss'

const BaseMap = ({
  children,
  className,
  projection,
  extent,
  zoomExtent,
  center = [0, 0],
  zoom = 3.25,
  minZoom = 3.25,
  maxZoom = 13,
}) => {
  // extent is [minx, miny, maxx, maxy]
  if (extent?.length) {
    const zoomFactor = 1000000;
    extent[0] -= zoomFactor;
    extent[1] -= zoomFactor;
    extent[2] += zoomFactor;
    extent[3] += zoomFactor;
  }

  return (
    <div className='map-layout'>
      <div className='info-text-popout'>
        <span>Hold ALT/OPTION + click and drag on map to rotate</span>
      </div>
      <OLMap
        projection={projection}
        extent={extent}
        zoomExtent={zoomExtent}
        center={center}
        zoom={zoom}
        minZoom={minZoom}
        maxZoom={maxZoom}
        className={className}
      >
        <Layers>
          <BaseLayerGroup />
          <LabelLayerGroup />
        </Layers>
        <Interactions>
          <DragRotateInteraction condition={altKeyOnly} />
        </Interactions>
        {children}
      </OLMap>
    </div>
  );
};

export default BaseMap;
