import React, { useEffect, useState, useReducer, useRef } from 'react';
import Button from '../../../../../app-components/button/button';
import InfoPanel from '../../../../../app-components/info-panel/infoPanel';
import { store } from '../../../../../stores/app-state/store';
import {
  useGetMyCoordinateSelectionQuery,
  useCreateMyCoordinateMutation,
  useUpdateMyCoordinateMutation,
} from '../../../../../stores/server-state/coordinates';
import { reduceFormData } from '../../../../../utils/form-helpers';

const MyCoordinatesForm = ({ tag }) => {
  const { myCoordinateId } = store.useMyCoordinateId();
  const { drawGeomCoordinate } = store.useDrawGeomCoordinate();
  const { latLonCoordinate } = store.useLatLonCoordinate();
  const { doUpdateIsDrawingCoordinate } = store.useIsDrawingCoordinate();
  const { doUpdateIsSavePromptCoordinate } = store.useIsSavePromptCoordinate();
  const { doUpdateDrawGeomCoordinate } = store.useDrawGeomCoordinate();

  const { data: myCoordinateSelection } = useGetMyCoordinateSelectionQuery({
    myCoordinateId,
  });
  const creator = useCreateMyCoordinateMutation();
  const updater = useUpdateMyCoordinateMutation({ myCoordinateId });

  const defaultName = `${Number(latLonCoordinate[0]).toFixed(4)},${Number(
    latLonCoordinate[1],
  ).toFixed(4)} (LatLong)`;
  let initialState = {
    name: defaultName,
    slug: '',
    geometry: '',
    office_symbol: null,
    tag_id: tag?.id,
  };
  const inputRef = useRef();

  if (myCoordinateId?.length) initialState = myCoordinateSelection;

  const [formIsValid, setFormIsValid] = useState(true);
  const [formType, setFormType] = useState('new');
  const [formData, dispatch] = useReducer(reduceFormData, initialState);

  const closeForm = () => {
    doUpdateIsDrawingCoordinate(false);
    doUpdateIsSavePromptCoordinate(false);
    doUpdateDrawGeomCoordinate(undefined);
  };

  const submitForm = e => {
    e.preventDefault();
    formData.name = formData.name.trim();
    if (formData.name.length === 0) {
      setFormIsValid(false);
      return;
    }
    formData.slug = formData.name.trim().toLowerCase().replace(/ /g, '-');
    if (formType === 'edit') {
      delete formData.office_symbol;
      updater.mutate(formData);
    } else {
      creator.mutate(formData);
    }
    closeForm();
  };

  const cancelForm = () => {
    dispatch({ type: 'reset', payload: initialState });
    closeForm();
  };

  useEffect(() => {
    drawGeomCoordinate &&
      dispatch({
        type: 'update',
        field: 'geometry',
        value: drawGeomCoordinate,
      });
  }, [dispatch, drawGeomCoordinate]);

  useEffect(() => {
    if (myCoordinateId?.length) {
      setFormType('edit');
    } else {
      setFormType('new');
    }
  }, [myCoordinateId, myCoordinateSelection, setFormType]);

  useEffect(() => {
    if (formType === 'edit') {
      inputRef.current.selectionStart = inputRef.current.value.length;
      inputRef.current.selectionEnd = inputRef.current.value.length;
    }
  }, [formType]);

  return (
    <div className='form-row'>
      <div className='w-50 ml-1 form-group'>
        <label htmlFor='my-coordinate-name'>
          <small>Point Name</small>
        </label>
        <form
          onSubmit={submitForm}
          id='my-coordinate-name'
          className='input-group mt-1 border border-warning rounded'
        >
          <input
            ref={inputRef}
            maxLength={100}
            className={`form-control ${!formIsValid && 'is-invalid'}`}
            placeholder={formData.name}
            defaultValue={formType === 'edit' ? formData.name : undefined}
            aria-label='Enter a name for the point'
            aria-describedby='Enter a name for the point'
            onChange={e =>
              dispatch({
                type: 'update',
                field: 'name',
                value: e.target.value,
              })
            }
            autoFocus
          />
          <div className='input-group-append'>
            <Button
              text='Save'
              variant='success'
              type='submit'
              className='my-0 py-0'
            />
            <Button
              className='input-group-text my-0 py-0'
              text='Cancel'
              variant='danger'
              handleClick={cancelForm}
            />
          </div>
        </form>
      </div>
      <div className='col mt-2 pl-3 pr-0'>
        <InfoPanel>
          Enter a name and click <strong>Save</strong>.
        </InfoPanel>
      </div>
    </div>
  );
};

export default MyCoordinatesForm;
