import { useCallback, useState, useEffect } from 'react';

import VectorTileSource from 'ol/source/VectorTile';
import { MVT } from 'ol/format';

const useMVT = ({
  url = '',
  idProperty = undefined,
  tileGrid = undefined,
  projection = 'EPSG:4326',
}) => {
  const [mvtSource, setMvtSource] = useState(null);

  const createMvtSource = useCallback(
    mvtUrl => {
      return new VectorTileSource({
        url: mvtUrl,
        tileGrid,
        projection,
        format: new MVT({ idProperty }),
      });
    },
    [projection],
  );

  useEffect(() => {
    url && setMvtSource(createMvtSource(url));
    return () => {
      url && setMvtSource(null);
    };
  }, [createMvtSource, url]);

  return mvtSource;
};

export default useMVT;
