import { gen } from './helpers';

export const createSnowAnalogSlice = set => ({
  ...gen(set, {
    analogProductId: '',
    analogProductFileId: '',
    sourceProductId: '',
    sourceProductFileId: '',
    myCoordinateId: '',
    mySnowAnalogId: '',
    showSource: true,
    isSavePromptCoordinate: false,
    isDeletePromptCoordinate: false,
    drawGeomCoordinate: '',
    latLonCoordinate: [],
    shouldFetchCoordinates: true,
  }),
  isDrawingCoordinate: false,
  doUpdateIsDrawingCoordinate: isDrawingCoordinate =>
    set(
      { isDrawingCoordinate, myCoordinateId: '', mySnowAnalogId: '' },
      false,
      'doUpdateIsDrawingCoordinate',
    ),
});
