import React, { useState, Fragment } from 'react';
import { store } from '../../../../../stores/app-state/store';
import Button from '../../../../../app-components/button';
import SubExpandTable from './subExpandTable';
import Icon from '../../../../../app-components/icon';
import {
  mdiCheck,
  mdiChevronRight,
  mdiChevronDown,
  mdiSort,
  mdiSortAscending,
  mdiSortDescending,
  mdiClose,
} from '@mdi/js';
import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';

import './expandTable.scss';
import DeletePrompt from './deletePrompt';

const ExpandTable = ({ rowData = [] }) => {
  const [expanded, setExpanded] = useState({});

  const { doUpdateProductId } = store.useProductId();
  const { doUpdateProductFileId } = store.useProductFileId();
  const { drtsporeId, doUpdateDrtsporeId } = store.useDrtsporeId();
  const { doModalOpen } = store.useDoModalOpen();

  const columnHelper = createColumnHelper();
  const columns = [
    columnHelper.accessor('name', {
      header: () => (
        <>
          <Button
            onClick={table.getToggleAllRowsExpandedHandler()}
            style={{ boxShadow: 'none' }}
            icon={
              <Icon
                path={
                  table.getIsAllRowsExpanded()
                    ? mdiChevronDown
                    : mdiChevronRight
                }
                size={0.7}
                className='mb-1'
              />
            }
            size='large'
            variant='link'
            title='Expand / Collapse All Rows'
            className='p-1 m-0'
          />
          Drtspore Name
        </>
      ),
      cell: ({ row, getValue }) => (
        <div
          style={{
            paddingLeft: 2,
          }}
        >
          <Button
            onClick={e => {
              e.stopPropagation();
              row.toggleExpanded();
            }}
            onMouseUp={e => e.stopPropagation()}
            onMouseDown={e => e.stopPropagation()}
            style={{ boxShadow: 'none' }}
            icon={
              <Icon
                path={row.getIsExpanded() ? mdiChevronDown : mdiChevronRight}
                size={0.7}
                className='mb-1'
              />
            }
            size='large'
            variant='link'
            title='Expand / Collapse Row'
            className='p-1 m-0'
            isDisabled={!row.getCanExpand()}
          />
          {getValue()}
        </div>
      ),
      footer: info => info.column.id,
      minSize: 230,
    }),
    columnHelper.accessor('create_date', {
      header: () => 'Created',
      cell: info => {
        const dateVal = info.getValue();

        if (dateVal) {
          return new Date(dateVal).toLocaleString('en-US', { hour12: false });
        } else {
          return info.renderValue();
        }
      },
      footer: info => info.column.id,
    }),
    columnHelper.accessor('progress', {
      size: 10,
      enableSorting: false,
      enableColumnFilter: false,
      enableGlobalFilter: false,
      cell: ({ row, getValue }) => {
        if (getValue() !== 100) {
          return (
            <>
              <div
                className='d-flex spinner-border spinner-border-sm p-1 m-0 mt-1'
                role='status'
              >
                <span className='sr-only'>Loading...</span>
              </div>
              <div>
                <small>{getValue() + '%'}</small>
              </div>
            </>
          );
        } else {
          return (
            <Icon
              path={rowIsSelected(row) ? mdiClose : mdiCheck}
              size={0.9}
              onClick={() => {
                if (rowIsSelected(row)) {
                  handleDeleteModalOpen();
                }
              }}
            />
          );
        }
      },
      header: null,
      footer: info => info.column.id,
    }),
  ];

  const rowIsSelected = row => row.original.id === drtsporeId;

  const sortChangeHandler = sortType => {
    switch (sortType) {
      case 'asc':
        return mdiSortAscending;
      case 'desc':
        return mdiSortDescending;
      default:
        return mdiSort;
    }
  };

  const handleDeleteModalOpen = () => {
    doModalOpen(DeletePrompt);
  };

  const updateLayerSelectionHandler = row => {
    if (row?.status === 'SUCCESS') {
      const fileSelection = row.productfiles[0];
      doUpdateDrtsporeId(row.id ?? '');
      doUpdateProductFileId(fileSelection.id ?? '');
      doUpdateProductId(fileSelection.product_id ?? '');
    }
  };

  const rowSelectHandler = row => {
    if (!rowIsSelected(row)) {
      doUpdateDrtsporeId(row.original.id);
      updateLayerSelectionHandler(row.original);
    }
  };

  const getRowClasses = row => {
    return rowIsSelected(row) ? 'row-selected' : '';
  };

  const renderSubComponent = ({ row }) => {
    const { productfiles, id, name, status } = row.original;
    return (
      <SubExpandTable
        rowData={productfiles}
        parentData={{ id, name, status }}
      />
    );
  };

  const updateMapLayerOnDataChange = () => {
    const selection = rowData.find(row => row.id === drtsporeId);
    updateLayerSelectionHandler(selection);
  };

  const table = useReactTable({
    data: rowData,
    columns,
    columnResizeMode: 'onChange',
    initialState: {
      sorting: [
        {
          id: 'create_date',
          desc: true,
        },
      ],
    },
    state: { expanded },
    onStateChange: updateMapLayerOnDataChange,
    getRowCanExpand: row => row.original.status === 'SUCCESS',
    renderSubComponent,
    onExpandedChange: setExpanded,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: import.meta.env.DEV,
  });

  let timerId;
  return (
    <div className='p-0 table-wrapper'>
      <div className='h-2' />
      <table className='expand-table'>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => {
                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{ width: header.getSize(), whiteSpace: 'nowrap' }}
                  >
                    {header.isPlaceholder || header.id === 'status' ? null : (
                      <div>
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                        <Button
                          onClick={header.column.getToggleSortingHandler()}
                          icon={
                            header.id !== 'progress' ? (
                              <Icon
                                path={sortChangeHandler(
                                  header.column.getIsSorted(),
                                )}
                                size={0.7}
                                className='mb-1'
                              />
                            ) : null
                          }
                          variant='link'
                          style={{ boxShadow: 'none' }}
                        />
                      </div>
                    )}
                    {header.index + 1 !== columns.length ? (
                      <>
                        <div
                          onMouseDown={header.getResizeHandler()}
                          onTouchStart={header.getResizeHandler()}
                          className='pl-2 resizer'
                        />
                        <div className='resizer-line' />
                      </>
                    ) : null}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => {
            const rowExpanded = row.getIsExpanded();
            return (
              <Fragment key={row.id}>
                <tr
                  onMouseUp={() => {
                    rowSelectHandler(row);
                    clearTimeout(timerId);
                  }}
                  style={{
                    borderTop: '1.5px solid grey',
                    borderBottom: !rowExpanded ? '1.5px solid grey' : 'none',
                    userSelect: 'text',
                  }}
                  key={row.id}
                  className={getRowClasses(row)}
                >
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </td>
                    );
                  })}
                </tr>
                {rowExpanded && (
                  <tr style={{ borderBottom: '1.5px solid grey' }}>
                    <td colSpan={row.getVisibleCells().length}>
                      {renderSubComponent({ row })}
                    </td>
                  </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ExpandTable;
