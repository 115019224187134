import tinygradient from 'tinygradient';

export const GL_MAX_NSHADES = 80;

export const layerType = {
  Categorical: 'categorical',
  Continuous: 'continuous',
};

export const renderType = {
  Fixed: 'fixed',
  Normalized: 'normalized',
  Standardized: 'standardized',
};

const round = num => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

export const getColorBarTicks = (start, end) => {
  const middleTickCount = 3;
  let ticks = [];
  const range = end - start;
  const increment = range / (middleTickCount + 1);
  ticks.push(round(end).toString());
  for (let i = 0; i < middleTickCount; i++) {
    const val = end - (i + 1) * increment;
    ticks.push(round(val).toString());
  }
  ticks.push(round(start).toString());
  return ticks;
};

const pfc = {
  h: {
    color: '#8744ef',
    description: '(h) high',
  },
  m: {
    color: '#9f7cfa',
    description: '(m) medium',
  },
  l: {
    color: '#cfb0fc',
    description: '(l) low',
  },
};

const pfe = {
  i: {
    color: '#d7fd3e',
    description: '(i) isolated patches',
  },
  d: {
    color: '#7afafe',
    description: '(d) discontinuous',
  },
  s: {
    color: '#acfcae',
    description: '(s) sporadic',
  },
  c: {
    color: '#9f7cfa',
    description: '(c) continuous',
  },
};

const pfl = {
  r: {
    color: '#f3b03f',
    description:
      '(r) mountains, highlands ridges, and plateaus characterized by thin overburden cover (>5-10m) and exposed bedrock',
  },
  f: {
    color: '#6ffafe',
    description:
      '(f) lowlands, highlands, and intermontane depressions characterized by thick overburden cover (>5-10m)',
  },
};

// TODO: Move to API
export const colormaps = {
  jet: [
    '#000064',
    '#0000ff',
    '#377ff7',
    '#6efdef',
    '#4afe79',
    '#26ff02',
    '#fff420',
    '#ff7a10',
    '#ff0000',
    '#640000',
  ],
  BuCy: ['#000050', '#00aaaa', '#00ddff'],
  RdYlGn: ['#d7191c', '#ffff6c', '#1a9641'],
  GnYlRd: ['#1a9641', '#ffff6c', '#d7191c'],
  Viridis: ['#440154', '#27808e', '#fde725'],
  GnYlPuWt: [
    '#4f6149',
    '#737b5c',
    '#96946e',
    '#cbaf70',
    '#ffc971',
    '#e0bc9c',
    '#c1afc6',
    '#e0d7e3',
    '#ffffff',
  ],
  Paired: [
    '#a6cee3',
    '#79b1d3',
    '#4c95c4',
    '#1f78b4',
    '#509aa6',
    '#81bd98',
    '#b2df8a',
    '#73c05b',
    '#33a02c',
    '#979d63',
    '#fb9a99',
    '#ef5a5b',
    '#e31a1c',
    '#f06d46',
    '#fdbf6f',
    '#fe9f38',
    '#ff7f00',
    '#e5996b',
    '#cab2d6',
    '#9a78b8',
    '#6a3d9a',
    '#b59e9a',
    '#ffff99',
    '#eeeeee',
    '#cccccc',
  ],
  PermafrostCombo: {
    0: { color: '#808080', description: 'No information' },
    1: {
      color: '#8744ef',
      description:
        '(chf) Continuous permafrost extent with high ground ice content and thick overburden',
    },
    2: {
      color: '#6ffafe',
      description:
        '(dhf) Discontinuous permafrost extent with high ground ice content and thick overburden',
    },
    3: {
      color: '#67fa3a',
      description:
        '(shf) Sporadic permafrost extent with high ground ice content and thick overburden',
    },
    4: {
      color: '#6ffa3a',
      description:
        '(ihf) Isolated patches of permafrost extent with high ground ice content and thick overburden',
    },
    5: {
      color: '#9f7cfa',
      description:
        '(cmf) Continuous permafrost extent with medium ground ice content and thick overburden',
    },
    6: {
      color: '#7afafe',
      description:
        '(dmf) Discontinuous permafrost extent with medium ground ice content and thick overburden',
    },
    7: {
      color: '#71fa77',
      description:
        '(smf) Sporadic permafrost extent with medium ground ice content and thick overburden',
    },
    8: {
      color: '#a9fc3c',
      description:
        '(imf) Isolated patches of permafrost extent with medium ground ice content and thick overburden',
    },
    9: {
      color: '#cfb0fc',
      description:
        '(clf) Continuous permafrost extent with low ground ice content and thick overburden',
    },
    10: {
      color: '#affcfe',
      description:
        '(dlf) Discontinuous permafrost extent with low ground ice content and thick overburden',
    },
    11: {
      color: '#acfcae',
      description:
        '(slf) Sporadic permafrost extent with low ground ice content and thick overburden',
    },
    12: {
      color: '#d7fd3e',
      description:
        '(ilf) Isolated patches of permafrost extent with low ground ice content and thick overburden',
    },
    13: {
      color: '#b27629',
      description:
        '(chr) Continuous permafrost extent with high ground ice content and thin overburden and exposed bedrock',
    },
    14: {
      color: '#ef8061',
      description:
        '(dhr) Discontinuous permafrost extent with high ground ice content and thin overburden and exposed bedrock',
    },
    15: {
      color: '#e236e1',
      description:
        '(shr) Sporadic permafrost extent with high ground ice content and thin overburden and exposed bedrock',
    },
    16: {
      color: '#ef39fa',
      description:
        '(ihr) Isolated patches of permafrost extent with high ground ice content and thin overburden and exposed bedrock',
    },
    17: {
      color: '#e2bb9c',
      description:
        '(clr) Continuous permafrost extent with low ground ice content and thin overburden and exposed bedrock',
    },
    18: {
      color: '#f3b03f',
      description:
        '(dlr) Discontinuous permafrost extent with low ground ice content and thin overburden and exposed bedrock',
    },
    19: {
      color: '#e156e0',
      description:
        '(slr) Sporadic permafrost extent with low ground ice content and thin overburden and exposed bedrock',
    },
    20: {
      color: '#f061fa',
      description:
        '(ilr) Isolated patches of permafrost extent with low ground ice content and thin overburden and exposed bedrock',
    },
    21: { color: '#808080', description: '(g) Glaciers' },
    22: { color: '#808080', description: '(r) Relict permafrost' },
    23: { color: '#808080', description: '(l) Inland lakes' },
    24: { color: '#808080', description: '(o) Ocean/inland seas' },
    25: { color: '#808080', description: '(ld) Land' },
  },
  PermafrostContent: {
    0: { color: '#808080', description: 'No information' },
    1: pfc.h,
    2: pfc.h,
    3: pfc.h,
    4: pfc.h,
    5: pfc.m,
    6: pfc.m,
    7: pfc.m,
    8: pfc.m,
    9: pfc.l,
    10: pfc.l,
    11: pfc.l,
    12: pfc.l,
    13: pfc.h,
    14: pfc.h,
    15: pfc.h,
    16: pfc.h,
    17: pfc.l,
    18: pfc.l,
    19: pfc.l,
    20: pfc.l,
    21: { color: '#808080', description: '(g) Glaciers' },
    22: { color: '#808080', description: '(r) Relict permafrost' },
    23: { color: '#808080', description: '(l) Inland lakes' },
    24: { color: '#808080', description: '(o) Ocean/inland seas' },
    25: { color: '#808080', description: '(ld) Land' },
  },
  PermafrostExtent: {
    0: { color: '#808080', description: 'No information' },
    1: pfe.c,
    2: pfe.d,
    3: pfe.s,
    4: pfe.i,
    5: pfe.c,
    6: pfe.d,
    7: pfe.s,
    8: pfe.i,
    9: pfe.c,
    10: pfe.d,
    11: pfe.s,
    12: pfe.i,
    13: pfe.c,
    14: pfe.d,
    15: pfe.s,
    16: pfe.i,
    17: pfe.c,
    18: pfe.d,
    19: pfe.s,
    20: pfe.i,
    21: { color: '#808080', description: '(g) Glaciers' },
    22: { color: '#808080', description: '(r) Relict permafrost' },
    23: { color: '#808080', description: '(l) Inland lakes' },
    24: { color: '#808080', description: '(o) Ocean/inland seas' },
    25: { color: '#808080', description: '(ld) Land' },
  },
  PermafrostLandform: {
    0: { color: '#808080', description: 'No information' },
    1: pfl.f,
    2: pfl.f,
    3: pfl.f,
    4: pfl.f,
    5: pfl.f,
    6: pfl.f,
    7: pfl.f,
    8: pfl.f,
    9: pfl.f,
    10: pfl.f,
    11: pfl.f,
    12: pfl.f,
    13: pfl.r,
    14: pfl.r,
    15: pfl.r,
    16: pfl.r,
    17: pfl.r,
    18: pfl.r,
    19: pfl.r,
    20: pfl.r,
    21: { color: '#808080', description: '(g) Glaciers' },
    22: { color: '#808080', description: '(r) Relict permafrost' },
    23: { color: '#808080', description: '(l) Inland lakes' },
    24: { color: '#808080', description: '(o) Ocean/inland seas' },
    25: { color: '#808080', description: '(ld) Land' },
  },
  Lcc: {
    111: {
      color: '#58481f',
      description: 'closed forest, needle-leaved, evergreen',
    },
    113: {
      color: '#70663e',
      description: 'closed forest, needle-leaved, deciduous',
    },
    112: {
      color: '#009900',
      description: 'closed forest, broadleaved, evergreen',
    },
    114: {
      color: '#00cc00',
      description: 'closed forest, broadleaved, deciduous',
    },
    115: { color: '#4e751f', description: 'closed forest, mixed type' },
    116: { color: '#007800', description: 'closed forest, unknown type' },
    121: {
      color: '#666000',
      description: 'open forest, needle-leaved, evergreen',
    },
    123: {
      color: '#8d7400',
      description: 'open forest, needle-leaved, deciduous',
    },
    122: {
      color: '#8db400',
      description: 'open forest, broadleaved, evergreen',
    },
    124: {
      color: '#a0dc00',
      description: 'open forest, broadleaved, deciduous',
    },
    125: { color: '#929900', description: 'open forest, mixed type' },
    126: { color: '#648c00', description: 'open forest, unknown type' },
    20: {
      color: '#ffbb22',
      description: 'shrubland',
    },
    30: {
      color: '#ffff4c',
      description: 'herbaceous vegetation',
    },
    90: {
      color: '#0096a0',
      description: 'herbaceous wetland',
    },
    100: {
      color: '#fae6a0',
      description: 'moss & lichen',
    },
    60: { color: '#b4b4b4', description: 'bare / sparse vegetation' },
    40: {
      color: '#f096ff',
      description: 'cropland',
    },
    50: {
      color: '#fa0000',
      description: 'built-up',
    },
    70: { color: '#f0f0f0', description: 'snow & ice' },
    80: {
      color: '#0032c8',
      description: 'permanent inland water bodies',
    },
    200: { color: '#000080', description: 'sea' },
  },
  Pfr: [
    '#5b7faf',
    '#8acebc',
    '#3b4997',
    '#667c65',
    '#75aeb7',
    '#8397de',
    '#91adad',
    '#c0cfcc',
    '#2a408d',
  ],
  Alt: ['#5d260e', '#d5b75e', '#42a42a', '#8acaf8'],
  DemNorthernAbsolute2: [
    '#495cb0',
    '#86d0ae',
    '#6ba2b5',
    '#bdcc77',
    '#5983b3',
    '#87a7d4',
    '#77ce56',
    '#707ecb',
    '#6db248',
    '#305c84',
    '#91ec93',
    '#70a0ba',
    '#5bbc62',
    '#d9cd7f',
    '#cb715e',
  ],
  DrtsporeSoilType: {
    1: { color: '#4ba3d9', description: 'sand' },
    2: { color: '#7abbe3', description: 'loamy sand' },
    3: { color: '#9acce4', description: 'sandy loam' },
    4: { color: '#fcf050', description: 'silt loam' },
    5: { color: '#fef7d2', description: 'silt' },
    6: { color: '#cbddc8', description: 'loam' },
    7: { color: '#c9d6ed', description: 'sandy clay' },
    8: { color: '#f0d0b1', description: 'silty clay loan' },
    9: { color: '#e4a8bc', description: 'clay loam' },
    10: { color: '#baaecc', description: 'sandy clay' },
    11: { color: '#ecb091', description: 'silty clay' },
    12: { color: '#ce5c8b', description: 'clay' },
    13: { color: '#8a4724', description: 'organic' },
    14: { color: '#194177', description: 'water' },
    15: { color: '#723659', description: 'bedrock' },
    16: { color: '#737373', description: 'other' },
  },
};

// TODO: move this to the API
export const productConfig = new Map([
  [
    'swe-nd',
    {
      description: 'milimeters (mm)',
      type: layerType.Continuous,
      productSet: true,
      colors: colormaps.jet,
      label: `SWE (mm)`,
      minRange: 0,
      maxRange: 260,
      nodata: 0,
      nodataLabel: '0',
      noDisplayValues: [0, -150, -200, -250, -300],
      attributions:
        '<br><br><a href="https://nsidc.org/data/nsidc-0630/versions/1">Daily snow water equivalent as estimated by NSIDC Calibrated Enhanced-Resolution Passive Microwave Brightness Temperatures (1978 to present). Resolition: 25km and 6km</a>',
    },
  ],
  [
    'globsnow-prob',
    {
      description: 'percentage (%)',
      type: layerType.Continuous,
      colors: colormaps.BuCy,
      label: `Snow Likeliness (%)`,
      minRange: 0,
      maxRange: 100,
      nodata: 0,
      ticks: ['100', '75', '50', '25', '> 0'],
      nodataLabel: '0',
      attributions:
        '<br><br><a href="https://www.globsnow.info/index.php?page=Snow_Extent">Mean snow-covered area from GlobSnow Snow Extent (October-May,1995-2016). Resolution: 1 km</a>',
    },
  ],
  [
    'globsnow-prob-analog',
    {
      type: layerType.Continuous,
      colors: colormaps.GnYlRd,
      label: `Likeliness`,
      nodataLabel: 'Out of Range',
      minRange: 0,
      maxRange: 255,
      nodata: 0,
      ticks: ['Less Similar', 'More Similar'],
    },
  ],
  [
    'aggregate-analog',
    {
      type: layerType.Continuous,
      colors: colormaps.GnYlRd,
      label: `Likeliness`,
      minRange: 0,
      maxRange: 255,
      nodata: 0,
      nodataLabel: 'Out of Range',
      ticks: ['Less Similar', 'More Similar'],
    },
  ],
  [
    'dem-northern',
    {
      type: layerType.Continuous,
      colors: colormaps.Viridis,
      label: `Relative Elevation`,
      nodata: 0,
      minRange: -3,
      maxRange: 3,
      ticks: ['Higher', 'Lower'],
      attributions:
        '<br><br><a href="https://spacedata.copernicus.eu/collections/copernicus-digital-elevation-model">Deviation from mean elevation using a ~5 km moving window of Coperinicus GLO-30 DSM at native resolution and then coarsened.  Resolution: 1 km</a>',
    },
  ],
  [
    'dem-northern-analog',
    {
      type: layerType.Continuous,
      colors: colormaps.GnYlRd,
      label: `Likeliness`,
      nodataLabel: 'Out of Range',
      minRange: 0,
      maxRange: 255,
      nodata: 0,
      ticks: ['Less Similar', 'More Similar'],
    },
  ],
  [
    'dem-northern-absolute',
    {
      description: 'meters (m)',
      type: layerType.Continuous,
      colors: colormaps.GnYlPuWt,
      label: `Elevation (m)`,
      minRange: 0,
      maxRange: 5000,
      nodata: 0,
      ticks: ['5000+', '4000', '3000', '2000', '1000', '0'],
      attributions:
        '<br><br><a href="https://www.usgs.gov/coastal-changes-and-impacts/gmted2010">Digital surface model derived from 11 raster inputs including DTED2 (SRTM), CDED, SPOT 5, NED, GEODATA, and ICESAT. Epoch: 2010. Resolution: 1 km</a>',
    },
  ],
  [
    'dem-northern-absolute-analog',
    {
      type: layerType.Continuous,
      colors: colormaps.GnYlRd,
      label: `Likeliness`,
      nodataLabel: 'Out of Range',
      minRange: 0,
      maxRange: 255,
      nodata: 0,
      ticks: ['Less Similar', 'More Similar'],
    },
  ],
  [
    'dem-northern-ak',
    {
      description: 'meters (m)',
      type: layerType.Continuous,
      colors: colormaps.GnYlPuWt,
      label: `Elevation (m)`,
      minRange: 0,
      maxRange: 2000,
      nodata: 0,
      ticks: ['2000+', '1500', '1000', '500', '0'],
      attributions:
        '<br><br><a href="https://www.usgs.gov/coastal-changes-and-impacts/gmted2010">Digital surface model derived from 11 raster inputs including DTED2 (SRTM), CDED, SPOT 5, NED, GEODATA, and ICESAT. Epoch: 2010. Resolution: 1 km</a>',
    },
  ],
  [
    'permafrost-combo',
    {
      type: layerType.Categorical,
      colors: colormaps.PermafrostCombo,
      attributions:
        '<br><br><a href="https://nsidc.org/data/ggd318/versions/2">Permafrost classification based on Brown et al. (1997). Digitized and hosted by NSIDC, 2002. Resolution: 12.5 km</a>',
    },
  ],
  [
    'permafrost-content',
    {
      type: layerType.Categorical,
      colors: colormaps.PermafrostContent,
      attributions:
        '<br><br><a href="https://nsidc.org/data/ggd318/versions/2">Permafrost classification based on Brown et al. (1997). Digitized and hosted by NSIDC, 2002. Resolution: 12.5 km</a>',
    },
  ],
  [
    'permafrost-extent',
    {
      type: layerType.Categorical,
      colors: colormaps.PermafrostExtent,
      attributions:
        '<br><br><a href="https://nsidc.org/data/ggd318/versions/2">Permafrost classification based on Brown et al. (1997). Digitized and hosted by NSIDC, 2002. Resolution: 12.5 km</a>',
    },
  ],
  [
    'permafrost-landform',
    {
      type: layerType.Categorical,
      colors: colormaps.PermafrostLandform,
      attributions:
        '<br><br><a href="https://nsidc.org/data/ggd318/versions/2">Permafrost classification based on Brown et al. (1997). Digitized and hosted by NSIDC, 2002. Resolution: 12.5 km</a>',
    },
  ],
  [
    'esacci-ak-pfr',
    {
      description: 'percentage (%)',
      type: layerType.Continuous,
      colors: colormaps.jet,
      label: `Permafrost Fraction (%)`,
      minRange: 0,
      maxRange: 100,
      ticks: ['100', '90', '80', '70', '60', '50', '40', '30', '20', '0'],
      attributions:
        '<br><br><a href="https://catalogue.ceda.ac.uk/uuid/6e2091cb0c8b4106921b63cd5357c97c">Fractional permafrost coverage from ESA Climate Change Initiative soil temperature model, constrained by MODIS temperature data. 2019. Resolution: 1 km</a>',
    },
  ],
  [
    'esa-lcc',
    {
      type: layerType.Categorical,
      colors: colormaps.Lcc,
      attributions:
        '<br><br><a href="https://land.copernicus.eu/global/products/lc">Land cover classification derived from Sentinel 2 Imagery using PROBA-V time series training data. 2019. Resolution: 100 m</a>',
    },
  ],
  [
    'esacci-ak-alt',
    {
      description: 'centimeters (cm)',
      type: layerType.Continuous,
      colors: colormaps.Alt,
      label: `Active Layer Thickness (cm)`,
      minRange: 0,
      maxRange: 500,
      ticks: ['500+', '400', '300', '200', '100', '0'],
      attributions:
        '<br><br><a href="https://catalogue.ceda.ac.uk/uuid/67a3f8c8dc914ef99f7f08eb0d997e23">Active layer thickness from ESA Climate Change Initiative soil temperature model, constrained by MODIS temperature data. 2019. Resolution: 1 km</a>',
    },
  ],
  [
    'soil-moisture',
    {
      type: layerType.Continuous,
      colors: colormaps.jet,
      label: 'Soil Moisture',
      minRange: 0,
      maxRange: 1,
      nodata: -9999,
      ticks: ['1', '0.75', '0.5', '0.25', '0'],
    },
  ],
  [
    'soil-temperature',
    {
      type: layerType.Continuous,
      colors: colormaps.jet,
      label: 'Soil Temperature',
      minRange: -40,
      maxRange: 40,
      nodata: -9999,
      ticks: ['40', '10', '0', '-10', '-40'],
    },
  ],
  [
    'soil-type',
    {
      type: layerType.Categorical,
      colors: colormaps.DrtsporeSoilType,
      nodata: -9999,
    },
  ],
  [
    'co2-polynomial',
    {
      type: layerType.Continuous,
      colors: colormaps.jet,
      label: 'Soil Activity Poly',
      minRange: 0,
      maxRange: 200,
    },
  ],
  [
    'co2-linear',
    {
      type: layerType.Continuous,
      colors: colormaps.jet,
      label: 'Soil Activity Lin',
      minRange: 0,
      maxRange: 200,
    },
  ],
  [
    'co2-km',
    {
      type: layerType.Continuous,
      colors: colormaps.jet,
      label: 'Soil Activity KM',
      minRange: 0,
      maxRange: 200,
    },
  ],
]);

export const mapConfigHandler = slug => {
  const colorConfig = productConfig.get(slug);
  return colorConfig ?? {};
};

// inspired by https://openlayers.org/workshop/en/cog/colormap.html
// added tinygradient and range functionality
export const getColorStops = ({
  min = 0,
  max = 1,
  colors = colormaps.jet,
  nshades = colors.length,
  reverse = false,
  rangeInputBand = ['band', 1], // input band
}) => {
  if (!rangeInputBand.length) return [];

  // Rendering too many shades in GL context will crash Firefox
  nshades = Math.min(nshades, GL_MAX_NSHADES);

  const delta = (max - min) / (nshades - 1);
  const stops = new Array(nshades * 2);
  const gradient = tinygradient(colors);
  if (reverse) {
    gradient.reverse();
  }
  const colormap = gradient.rgb(nshades);
  if (rangeInputBand.length) {
    let prevStop = min;
    let currentStop;
    for (let i = 0; i < nshades; ++i) {
      currentStop = min + i * delta;
      stops[i * 2] = ['between', rangeInputBand, prevStop, currentStop];
      stops[i * 2 + 1] = Array.from(Object.values(colormap[i].toRgb()));
      prevStop = currentStop;
    }
  }
  if (!stops) return [];
  else return stops;
};

// for categorical datsets
export const getColorStopsMatch = ({
  colors = {},
  rangeInputBand = ['band', 1],
  reverse = false,
}) => {
  const colorLen = Object.keys(colors).length;
  if (colorLen === 0) {
    return [];
  }

  const gradient = tinygradient(Object.values(colors).map(obj => obj.color));
  if (reverse) {
    gradient.reverse();
  }
  const colormap = gradient.rgb(colorLen);

  const stops = [];
  stops.push('match');
  stops.push(rangeInputBand);

  for (const [idx, key] of Object.entries(Object.keys(colors))) {
    stops.push(Number(key));
    stops.push(Array.from(Object.values(colormap[idx].toRgb())));
  }
  stops.push([0, 0, 0, 0]);

  return stops;
};

// for hiding out-of-range categorical data in continuous datasets
export const getNoDisplayValuesMatch = ({
  noDisplayValues = [],
  inputBand = ['band', 1],
}) => {
  const casePairs = [];
  noDisplayValues.forEach(num => {
    casePairs.push(['==', inputBand, Number(num)]),
      casePairs.push([0, 0, 0, 0]);
  });
  return casePairs;
};
