import React, {
  useRef,
  useState,
  useCallback,
  useContext,
  createContext,
} from 'react';

import { Map, View } from 'ol';
import {
  defaults as defaultControls,
  ZoomSlider,
  Rotate,
  ZoomToExtent,
  ScaleLine,
} from 'ol/control';
import LayerSwitcher from 'ol-layerswitcher';
import { classArray } from '../../utils/helpers';
import { useCallbackRef } from '../../customHooks/useCallbackRef';

import 'ol/ol.css';
import 'ol-layerswitcher/dist/ol-layerswitcher.css';
import './olMap.scss';

// Inspired by https://github.com/carbonplan/maps/blob/main/src/mapbox.js

export const MapContext = createContext(null);

export const useOLMap = () => {
  return useContext(MapContext);
};

const OLMap = ({
  center = [0, 0],
  projection = 'EPSG:3857',
  extent = undefined,
  zoom = 0,
  minZoom = 3,
  maxZoom = 13,
  zoomExtent = 0,
  children,
  className,
}) => {
  const map = useRef(null);
  const [ready, setReady] = useState(false);

  const classes = classArray([className, 'ol-map']);

  const initOptions = {
    view: new View({
      center,
      projection,
      zoom,
      minZoom,
      maxZoom,
      padding: [0, 0, 0, 660],
      multiWorld: true,
    }),
    controls: new defaultControls().extend([
      new ScaleLine({ units: 'metric' }),
      new ZoomSlider(),
      new ZoomToExtent({ extent: zoomExtent }),
      new Rotate({
        className: 'ol-rotate bg-white',
        tipLabel: 'Click to Reset to Default Rotation',
        autoHide: false,
        label: 'R',
      }),
      new LayerSwitcher({
        reverse: true,
        groupSelectStyle: 'group',
      }),
    ]),
  };

  const mapRef = useCallbackRef(
    useCallback(node => {
      if (node !== null) {
        map.current = new Map({
          target: node,
          ...initOptions,
        });
        map.current.on('postrender', () => {
          setReady(true);
        });
      }
      return () => {
        if (map.current) {
          map.current.setTarget(undefined);
          setReady(false);
        }
      };
    }, []),
  );

  return (
    <MapContext.Provider
      value={{ mapInstance: map, map: map.current, projection, extent }}
    >
      <div ref={mapRef} className={classes}>
        {ready && children}
      </div>
    </MapContext.Provider>
  );
};

export default OLMap;
