import React, { useEffect, useCallback } from 'react';
import { Layers, VectorLayer } from '../../../../app-components/ol-map/layers';
import {
  Interactions,
  DrawInteraction,
} from '../../../../app-components/ol-map/interactions';

import { useOLMap } from '../../../../app-components/ol-map/olMap';

import {
  pointStyle,
  selectedStyle,
} from '../../../../app-components/ol-map/styles/vectorStyles';
import {
  useWKT,
  useRadius,
  useAutoFocus,
} from '../../../../app-components/ol-map/hooks';
import { store } from '../../../../stores/app-state/store';
import { useGetMyCoordinateSelectionQuery } from '../../../../stores/server-state/coordinates';

const CoordinateMapContent = ({ nPixelsRadius = 0 }) => {
  const { isDrawingCoordinate } = store.useIsDrawingCoordinate();
  const { doUpdateDrawGeomCoordinate } = store.useDrawGeomCoordinate();
  const { doUpdateLatLonCoordinate } = store.useLatLonCoordinate();
  const { myCoordinateId, doUpdateMyCoordinateId } = store.useMyCoordinateId();
  const { isSavePromptCoordinate, doUpdateIsSavePromptCoordinate } =
    store.useIsSavePromptCoordinate();

  const { data: myCoordinateSelection } = useGetMyCoordinateSelectionQuery({
    myCoordinateId,
  });

  const { projection } = useOLMap();

  const wktSource = useWKT({
    wkt: myCoordinateSelection?.geometry,
    dataProjection: projection,
    featureProjection: projection,
  });

  const radiusSource = useRadius({
    source: wktSource,
    nPixelsRadius,
    projection,
    unit: 'm',
  });

  const [focus, setFocus] = useAutoFocus({
    source: radiusSource,
    shouldReset: true,
  });

  const updateDrawGeom = useCallback(
    geom => doUpdateDrawGeomCoordinate(geom),
    [doUpdateDrawGeomCoordinate],
  );
  const updateLatLon = useCallback(
    name => doUpdateLatLonCoordinate(name),
    [doUpdateLatLonCoordinate],
  );
  const resetCoordiante = useCallback(
    () => doUpdateMyCoordinateId(''),
    [doUpdateMyCoordinateId],
  );
  const drawEnd = useCallback(
    () => doUpdateIsSavePromptCoordinate(true),
    [resetCoordiante, doUpdateIsSavePromptCoordinate],
  );

  useEffect(() => {
    return () => {
      myCoordinateSelection?.length && resetCoordiante();
    };
  }, []);

  return (
    <div>
      {!isDrawingCoordinate && (
        <Layers>
          <VectorLayer source={wktSource} zIndex={5} style={pointStyle} />
          <VectorLayer
            source={radiusSource}
            zIndex={4}
            style={selectedStyle}
            zoomPadding={300}
            zoomSource={focus}
          />
        </Layers>
      )}
      <Interactions>
        <DrawInteraction
          zIndex={2}
          drawShape='Point'
          isDrawing={isDrawingCoordinate}
          isSavePrompt={isSavePromptCoordinate}
          onDrawGeomChange={updateDrawGeom}
          onLatLonChange={updateLatLon}
          onDrawEnd={drawEnd}
          onSourceChange={setFocus}
        />
      </Interactions>
    </div>
  );
};

export default CoordinateMapContent;
