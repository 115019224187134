import { useState, useCallback, useEffect } from 'react';

import Circle from 'ol/geom/Circle';
import { Feature } from 'ol';
import { getPointResolution } from 'ol/proj';
import VectorSource from 'ol/source/Vector';

const useRadius = ({
  source = undefined,
  nPixelsRadius = 10,
  projection = 'EPSG:4326',
  unit = 'm',
}) => {
  const [radiusSource, setRadiusSource] = useState(null);

  const createRadiusSource = useCallback(
    radius => {
      if (!source || !projection) return undefined;
      const features = source.getFeatures();
      const xy =
        features &&
        features.length &&
        features[0].getGeometry().getCoordinates();
      return new VectorSource({
        features: [
          new Feature({
            geometry: new Circle(
              xy,
              radius *
                getPointResolution(
                  projection,
                  1000,
                  xy,
                  unit,
                ),
            ),
          }),
        ],
      });
    },
    [source, projection, unit],
  );

  useEffect(() => {
    nPixelsRadius && setRadiusSource(createRadiusSource(nPixelsRadius));
    return () => {
      nPixelsRadius && setRadiusSource(null);
    };
  }, [nPixelsRadius, createRadiusSource]);

  return radiusSource;
};

export default useRadius;
