import { useRef, useCallback } from 'react';

// Abstraction for implementing cleanup function in callback refs
// https://github.com/facebook/react/issues/15176#issuecomment-512740882

export const useCallbackRef = rawCallback => {
  const cleanupRef = useRef(null);
  const callback = useCallback(
    node => {
      if (cleanupRef.current) {
        cleanupRef.current();
        cleanupRef.current = null;
      }

      if (node) {
        cleanupRef.current = rawCallback(node);
      }
    },
    [rawCallback],
  );

  return callback;
};
