import React, { useCallback, useMemo } from 'react';
import Button from '../../../../../app-components/button';

import Icon from '../../../../../app-components/icon';
import { mdiDownload } from '@mdi/js';

import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { store } from '../../../../../stores/app-state/store';
import { useDownloadFileMutation } from '../../../../../stores/server-state/products';
import './subExpandTable.scss';

const columnHelper = createColumnHelper();

const SubExpandTable = ({ rowData = [], parentData = {} }) => {
  const { drtsporeId, doUpdateDrtsporeId } = store.useDrtsporeId();
  const { productFileId, doUpdateProductFileId } = store.useProductFileId();
  const { doUpdateProductId } = store.useProductId();

  const downloader = useDownloadFileMutation();

  const updateDrtspore = useCallback(
    id => doUpdateDrtsporeId(id),
    [doUpdateDrtsporeId],
  );

  const renderSimpleFilename = filename => {
    const nameArr = filename.split('__');
    if (nameArr && nameArr.length > 1) {
      if (nameArr[0].includes('source')) {
        if (nameArr[1].includes('.')) {
          const details = nameArr[1].split('.');
          return details[details.length - 1];
        }
        return nameArr[1];
      } else if (nameArr[0].includes('ecw/products/drtspore/')) {
        return nameArr[0].split('ecw/products/drtspore/')[1];
      }
      return nameArr[0];
    }
    return '';
  };

  const downloadProduct = useCallback(
    (id, fileName) => downloader.mutate({ id, fileName }),
    [downloader],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('file', {
        header: () => (
          <div
            style={{
              paddingLeft: 30,
            }}
          >
            File
          </div>
        ),
        cell: ({ row }) => (
          <div
            style={{
              paddingLeft: 30,
            }}
          >
            {renderSimpleFilename(row.original.file)}
          </div>
        ),
        size: 250,
      }),
      columnHelper.display({
        header: 'Time',
        id: 'datetime',
        cell: ({ row }) => {
          return new Date(row.original.datetime).toUTCString().substring(4, 29);
        },
        size: 400,
      }),
      columnHelper.display({
        header: null,
        id: 'download',
        size: 10,
        cell: ({ row }) => {
          return (
            <Button
              handleClick={e => {
                e.stopPropagation();
                const fileName = row.original.file.replace(
                  /[/.tif]/g,
                  '',
                );
                downloadProduct(row.original.id, fileName);
              }}
              onMouseUp={e => e.stopPropagation()}
              onMouseDown={e => e.stopPropagation()}
              style={{ boxShadow: 'none' }}
              icon={<Icon path={mdiDownload} size={0.8} />}
              size='large'
              variant='link'
              title='Download GeoTIFF'
              className='d-flex p-0 m-0 pr-1 shadow-none'
            />
          );
        },
      }),
    ],
    [downloadProduct, parentData.name],
  );

  const parentRowIsSelected = parentData.id === drtsporeId;
  const subRowIsSelected = row => row.original.id === productFileId;

  const rowSelectHandler = row => {
    if (!Object.keys(row.original)) {
      return;
    }
    if (row.original.id && row.original.product_id) {
      doUpdateProductFileId(row.original.id);
      doUpdateProductId(row.original.product_id);
    }

    if (parentData.status === 'SUCCESS') {
      if (parentData.id && !subRowIsSelected(row)) {
        updateDrtspore(parentData.id);
      }
    }
    if (!parentRowIsSelected) {
      doUpdateDrtsporeId(parentData.id);
    }
  };

  const getRowClasses = row => {
    return row && parentRowIsSelected && subRowIsSelected(row)
      ? 'sub-row-selected'
      : '';
  };

  const table = useReactTable({
    data: rowData,
    columns,
    initialState: {
      sorting: [
        {
          id: 'datetime',
          desc: false,
        },
      ],
    },
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: import.meta.env.DEV,
    className: 'sub-expand-table',
  });

  let timerId;
  return (
    <table className='sub-expand-table'>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                    whiteSpace: 'nowrap',
                    backgroundColor: 'rgb(250, 250, 250)',
                    borderTop: '1px dashed rgb(200, 200, 200)',
                    borderBottom: '1px dashed rgb(200, 200, 200)',
                    backgroundImage: 'none',
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                  {header.index + 1 !== columns.length ? (
                    <>
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className='pl-2 resizer'
                      />
                      <div className='resizer-line' />
                    </>
                  ) : null}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr
              key={row.id}
              onMouseUp={() => {
                rowSelectHandler(row);
                clearTimeout(timerId);
              }}
              className={getRowClasses(row)}
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SubExpandTable;
