import React from 'react';
import { Icon } from '@mdi/react';

const _Icon = props => (
  <a id={props.id}>
    <Icon {...props} />
  </a>
);

export default _Icon;
