import React from 'react';

import Dropdown from '../../../../app-components/dropdown';
import { classArray } from '../../../../utils/helpers';

const SelectProductDropdown = ({
  buttonClasses = '',
  dropdownClasses = '',
  options = [],
  label = '',
  text = '',
  value = '',
  handleClick = () => {},
  className = '',
  labelClassName = '',
  isDisabled = false,
}) => {
  const classes = classArray([className]);

  return (
    <div className={classes}>
      {label && (
        <label className={labelClassName}>
          <small>{label}</small>
        </label>
      )}
      <Dropdown.Menu
        dropdownClass={dropdownClasses}
        buttonClass={buttonClasses}
        buttonContent={text}
        isDisabled={isDisabled || options.length <= 1}
      >
        {options
          .filter(option => option.value !== value)
          .map(option => (
            <Dropdown.Item
              value={option.value}
              key={option.value}
              onClick={e => {
                handleClick(e.target.value);
              }}
            >
              {option.text}
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </div>
  );
};

export default SelectProductDropdown;
