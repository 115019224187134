import React, { useRef, useState } from 'react';
import Button from '../../../../../../app-components/button/button';

import { classArray } from '../../../../../../utils/helpers';

import Icon from '../../../../../../app-components/icon';
import { mdiSort, mdiSortAscending, mdiSortDescending } from '@mdi/js';

import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import './zonalStatsTable.scss';

const ZonalStatsTable = ({
  isHidden = false,
  className,
  rowData = [],
  columns = [],
}) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const classes = classArray([isHidden ? 'd-none' : '', className]);

  const ref = useRef(null);

  const sortChangeHandler = sortType => {
    if (sortType === 'asc') return mdiSortAscending;
    else if (sortType === 'desc') return mdiSortDescending;
    else return mdiSort;
  };

  const table = useReactTable({
    data: rowData,
    columns,
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    sorting: [
      {
        id: 'doy',
        desc: true,
      },
    ],
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection: true,
    enableMultiRowSelection: true,
  });

  return (
    <div ref={ref} className={isHidden ? 'd-none' : 'p-0 expand-table-wrapper'}>
      <div className={classes}>
        <div className='h-2'>
          <table className='water-table'>
            <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, i) => {
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          whiteSpace: 'nowrap',
                          zIndex: 1,
                          paddingLeft: i === 0 ? '8px' : '0px',
                        }}
                      >
                        {header.isPlaceholder ||
                        header.id === 'isLoading' ? null : (
                          <div>
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext(),
                            )}
                            <Button
                              onClick={header.column.getToggleSortingHandler()}
                              icon={
                                <Icon
                                  path={sortChangeHandler(
                                    header.column.getIsSorted(),
                                  )}
                                  size={0.7}
                                />
                              }
                              variant='link'
                              style={{ boxShadow: 'none', paddingTop: '1px' }}
                            />
                          </div>
                        )}
                        {header.index + 1 !== columns.length ? (
                          <>
                            <div
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                              className='pl-2 resizer'
                            />
                            <div className='resizer-line' />
                          </>
                        ) : null}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className='zonalBody'>
              {table.getRowModel().rows.map(row => (
                <tr
                  key={row.id}
                  onClick={() => setSelectedRowIndex(row.index)}
                  className={`${
                    row.index === selectedRowIndex
                      ? 'water-row-selected'
                      : 'hover-row'
                  }`}
                >
                  {row.getVisibleCells().map(cell => (
                    <td
                      style={{
                        paddingLeft: 7,
                      }}
                      key={cell.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}{' '}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default ZonalStatsTable;
