import { doyToAbstractDate } from './helpers';
import { saveAs } from 'file-saver';

export const getTableDataForExport = (data, columns) =>
  data.map(record => {
    return columns.reduce((recordToDownload, column) => {
      return {
        ...recordToDownload,
        [column.header()]: record[column.accessorKey],
      };
    }, {});
  });

export const makeCsv = async (rows, fileName, prependContent = undefined) => {
  const separator = ',';
  const keys = Object.keys(rows[0]);

  const csvContent = `${
    prependContent !== undefined ? `${prependContent}\n` : ''
  }${keys.join(separator)}\n${rows
    .map(row =>
      keys
        .map(k => {
          row.Date = doyToAbstractDate(row.Date);
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];

          cell =
            cell instanceof Date
              ? cell.toLocaleString()
              : cell.toString().replace(/"/g, '""');

          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        })
        .join(separator),
    )
    .join('\n')}`;

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, fileName);
};
