import React from 'react';

import { classArray } from '../../utils/helpers';

import './rangeSlider.scss';

const RangeSlider = ({
  value,
  className,
  onChange,
  orient = 'vertical',
  style = {},
}) => {
  const classes = classArray([className, 'input-range']);

  return (
    <div className='range-slider'>
      <input
        className={classes}
        orient={orient}
        style={style}
        type='range'
        step='0.1'
        value={value}
        onChange={e => onChange(e.target.valueAsNumber)}
        min='0'
        max='1'
      />
      <label className='font-weight-light inline-block w-75 mt-2 mr-0 pr-0'>
        {value * 100}%
      </label>
    </div>
  );
};

export default RangeSlider;
