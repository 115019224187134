import Keycloak from 'keycloak-js';
import { config } from '../config';

let mockAuthenticated = false;

const _kc = !config.ECW_AUTH_DISABLED
  ? new Keycloak({
      url: import.meta.env.VITE_KC_URL,
      realm: import.meta.env.VITE_KC_REALM,
      clientId: import.meta.env.VITE_KC_CLIENT_ID,
    })
  : new Object({
      authenticated: mockAuthenticated,
      token: 'token string',
      tokenParsed: {
        preferred_username: 'USER',
        name: 'Demo User',
        given_name: 'User',
      },
      initialized: true,
      login() {
        mockAuthenticated = true;
      },
      logout() {
        mockAuthenticated = false;
        window.location.href = '/';
      },
    });

export const initKeycloak = renderAppCallback => {
  if (!config.ECW_AUTH_DISABLED) {
    _kc
      .init({
        onLoad: 'check-sso',
        pkceMethod: 'S256',
        silentCheckSsoRedirectUri:
          window.location.origin + '/silent-check-sso.html',
      })
      .then(() => {
        renderAppCallback();
      })
      // eslint-disable-next-line no-console
      .catch('Error', console.error);
  } else {
    renderAppCallback();
  }
};

export const doLogin = _kc.login;
export const doLogout = _kc.logout;
export const getToken = () => _kc.token;
export const isLoggedIn = () => !!_kc.token;
export const updateToken = successCallback =>
  _kc.updateToken(5).then(successCallback).catch(doLogin);
export const getUsername = () => _kc.tokenParsed?.preferred_username;
export const getFullName = () => _kc.tokenParsed?.name;
export const getFirstName = () => _kc.tokenParsed?.given_name;

_kc.onTokenExpired = () => {
  _kc.updateToken(50);
};

_kc.onAuthRefreshError = () => {
  doLogout();
};
