import React from 'react';
import Button from '../../../../../app-components/button/button';
import { store } from '../../../../../stores/app-state/store';
import {
  useDeleteMyCoordinateMutation,
  useGetMyCoordinateSelectionQuery,
} from '../../../../../stores/server-state/coordinates';

const MyCoordinatesDeletePrompt = () => {
  const { myCoordinateId } = store.useMyCoordinateId();
  const { doUpdateIsDeletePromptCoordinate } =
    store.useIsDeletePromptCoordinate();

  const { data: myCoordinateSelection } = useGetMyCoordinateSelectionQuery({
    myCoordinateId,
  });
  const deleter = useDeleteMyCoordinateMutation({ myCoordinateId });

  return (
    <div className='form-row'>
      <div className='form-group mr-3'>
        <label htmlFor='deleteForm'>
          <small>
            <strong>
              Are you sure you want to delete "{myCoordinateSelection?.name}"?
            </strong>
          </small>
        </label>
        <div id='deleteForm' className='mt-1'>
          <Button
            text='Delete'
            variant='danger'
            handleClick={() => {
              deleter.mutate();
              doUpdateIsDeletePromptCoordinate(false);
            }}
          />
          <Button
            className='ml-2 my-0'
            text='Cancel'
            variant='secondary'
            handleClick={() => doUpdateIsDeletePromptCoordinate(false)}
          />
        </div>
      </div>
    </div>
  );
};

export default MyCoordinatesDeletePrompt;
