import React from 'react';

import DrtsporeForm from './components/drtsporeForm';
import Card from '../../../../app-components/card/card';

const DrtsporeFormCard = () => {
  return (
    <Card
      header='Create DRTSPORE'
      tooltip='To create a new record: <br />
        <ol><li>Select valid date range (less than one month)</li>
        <li>Select timestamps</li>
        <li>Draw bounding box</li>
        <li>Name record</li>
        <li>Save to start creation process</li>
        </ol>'
    >
      <DrtsporeForm />
    </Card>
  );
};

export default DrtsporeFormCard;
