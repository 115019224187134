import React from 'react';

import MapLayout from '../../shared-components/mapLayout/mapLayout';
import { Interactions } from '../../../app-components/ol-map/interactions';
import { PixelQueryInteraction } from '../../../app-components/ol-map/interactions/pixelQueryInteraction';
import { drtsporeMapConfig } from '../../shared-components/mapContent/baseMapConfig';
import DrtsporeMapContent from './component/drtsporeMapContent';
import DrtsporeMapDraw from './component/drtsporeMapDraw';

import 'react-tooltip/dist/react-tooltip.css';

const OlMap = ({ productSlug }) => {
  return (
    <MapLayout productSlug={productSlug} mapConfig={drtsporeMapConfig}>
      <DrtsporeMapDraw />
      <DrtsporeMapContent />
      <Interactions>
        <PixelQueryInteraction event='pointermove' />
      </Interactions>
    </MapLayout>
  );
};

export default OlMap;
