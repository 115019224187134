import React from 'react';
import { classArray } from '../../utils/helpers';

const LoadWheel = ({
  className,
  text = '',
  subText = '',
  style = { width: '100%', height: '350px' },
}) => {
  const classes = classArray([className]);
  return (
    <div className={classes} style={style}>
      <div className='d-flex'>
        <p className='font-weight-light'>{text}</p>
      </div>
      <div className='d-flex'>
        <div className='spinner-border' role='status'>
          <span className='sr-only'>{text}</span>
        </div>
      </div>
      <br />
      <p className='font-weight-light text-secondary'>{subText}</p>
    </div>
  );
};

export default LoadWheel;
