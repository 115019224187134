import React, { useEffect } from 'react';
import OlMap from './olMap';
import Sidebar from '../../app-components/sidebar/sidebar';
import { store } from '../../stores/app-state/store';
import DrtsporeTableCard from './dashboard-cards/drtsporeTableCard/drtsporeTableCard';
import DrtsporeFormCard from './dashboard-cards/drtsporeFormCard/drtsporeFormCard';
import LegendExplorerCard from './dashboard-cards/LegendExplorerCard';
import { drtsporeMapConfig } from '../shared-components/mapContent/baseMapConfig';
import { useGetProductSelectionQuery } from '../../stores/server-state/products';
import Card from '../../app-components/card/card';
import { drtsporeDesc } from '../HomePage';

const Drtspore = () => {
  const { productId, doUpdateProductId } = store.useProductId();
  const { doUpdateDrtsporeId } = store.useDrtsporeId();
  const { doUpdateIsDrawingDrtspore } = store.useIsDrawingDrtspore();

  const { data: productSelection } = useGetProductSelectionQuery({ productId });

  useEffect(() => {
    return () => {
      doUpdateDrtsporeId('');
      doUpdateProductId('');
      doUpdateIsDrawingDrtspore(false);
    };
  }, []);

  return (
    <div>
      <Sidebar>
        <Card className='font-weight-light'>
          <span>{drtsporeDesc}</span>
        </Card>
        <DrtsporeFormCard />
        <DrtsporeTableCard />
        <LegendExplorerCard />
      </Sidebar>
      <OlMap productSlug={productSelection?.slug} config={drtsporeMapConfig} />
    </div>
  );
};

export default Drtspore;
