import { useEffect } from 'react';

import { useOLMap } from '../olMap';
import { DragRotate } from 'ol/interaction';

const DragRotateInteraction = ({ condition }) => {
  const { map } = useOLMap();

  useEffect(() => {
    const dragRotate = new DragRotate({ condition });

    map.addInteraction(dragRotate);
    return () => map.removeInteraction(dragRotate);
  }, [condition, map]);

  return null;
};

export default DragRotateInteraction;
