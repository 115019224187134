import React from 'react';
import MyCoordinatesDeletePrompt from './components/myCoordinatesDeletePrompt';
import MyCoordinatesSelect from './components/myCoordinatesSelect';
import MyCoordinatesForm from './components/myCoordinatesForm';
import { store } from '../../../../stores/app-state/store';
import Card from '../../../../app-components/card/card';

import 'react-tooltip/dist/react-tooltip.css';

const SelectCoordinateCard = ({ tag }) => {
  const { isSavePromptCoordinate } = store.useIsSavePromptCoordinate();
  const { isDeletePromptCoordinate } = store.useIsDeletePromptCoordinate();

  return (
    <Card
      header='Select Point'
      tooltip={`<span>Create or select a point on the map</span>`}
    >
      <div>
        {isSavePromptCoordinate && <MyCoordinatesForm tag={tag} />}
        {isDeletePromptCoordinate && <MyCoordinatesDeletePrompt />}
        {!isSavePromptCoordinate && !isDeletePromptCoordinate && (
          <MyCoordinatesSelect tag={tag} />
        )}
      </div>
    </Card>
  );
};

export default SelectCoordinateCard;
