import React from 'react';
import HomePageCard from './dashboard-cards/homePageCard';

import InfoPanel from '../../app-components/info-panel';
import { getFirstName, isLoggedIn } from '../../utils/userService';
import Icon from '../../app-components/icon';
import { mdiLock } from '@mdi/js';

export const watershedStatsDesc = `
  Calculate zonal statistics from the intersection of predefined
  watersheds (HYDROBASINS) or custom geometries and snow water
  equivalent (SWE) daily and historical products. Export line charts
  and spreadsheets of the generated statistics. Visualize and
  interact with the data with the map interface.
`;

export const snowAnalogDesc = `
  Generate Snow Analog raster layers by quering a point with a
  buffer using various sources including Snow-Covered Area (SCA) and
  Topography. An aggregate layer is generated representing th
  overall likeliness of the combined sources. These values can be
  queried and filtered interactively on the map. For the generated 
  analog raster grids, the closer to 0, the more similar the given 
  pixel is to the area of interest for the source raster. 
`;

export const permafrostDesc = `
  Visualize categorical and continuous raster data within a
  highlighted bounding box in Alaska. A respective Colorbar / Legend
  will appear upon product selection. Query and recall pixel values
  for inspection and analysis. Preview pixel values by hovering the
  mouse over the map.
`;

export const drtsporeDesc = `
  Visualize and process static and dynamic grids from an external
  WCS (Web Coverage Service). For a given date/time and region of interest,
  the model generates a resulting grid indicating the potential for soil
  activity/respiration for the date and time of data from the WCS.
`;

const HomePage = () => {
  const subtitle = !isLoggedIn() ? (
    <>
      <Icon path={mdiLock} className='mr-2 pb-1' size={0.8} />
      Login Required
    </>
  ) : (
    ''
  );

  return (
    <div className='col'>
      <h4 className='row py-3 justify-content-center font-italic'>
        {isLoggedIn()
          ? `Welcome, ${getFirstName() ?? 'Guest User'}`
          : `Please log in to use this application`}
      </h4>
      <InfoPanel className='row font-weight-light p-2 border border-light rounded bg-light text-primary'>
        <div className='mx-auto w-50 p-3'>
          <span>
            The Extreme Cold Weather web application provides a suite of tools
            used to evaluate environmental threats related to weather and
            climate variables. Each tool provides a set of products and
            associated files for analysis.
            {isLoggedIn() ? (
              <>
                <br />
                <br />
                To navigate this site, click one of the card headers below or
                use the <strong>Tools</strong> dropdown section of the navbar.
              </>
            ) : null}
          </span>
        </div>
      </InfoPanel>
      <InfoPanel className='font-weight-light p-2 border border-light rounded bg-light text-primary'>
        <div className='d-flex justify-content-center row'>
          <HomePageCard
            title='Watershed Statistics'
            subtitle={subtitle}
            hrefTitle={['/watershed-statistics']}
            style={{ width: '500px' }}
            titleLinkDisabled={!isLoggedIn()}
          >
            <span>
              {watershedStatsDesc}
            </span>
          </HomePageCard>
          <HomePageCard
            title='Snow Analog'
            subtitle={subtitle}
            hrefTitle={['/snow-analog']}
            style={{ width: '500px' }}
            titleLinkDisabled={!isLoggedIn()}
          >
            <span>
              {snowAnalogDesc}
            </span>
          </HomePageCard>
          <HomePageCard
            title='Permafrost'
            subtitle={subtitle}
            hrefTitle={['/permafrost']}
            style={{ width: '500px' }}
            titleLinkDisabled={!isLoggedIn()}
          >
            <span>
              {permafrostDesc}
            </span>
          </HomePageCard>
          <HomePageCard
            title='DRTSPORE'
            subtitle={subtitle}
            hrefTitle={['/drtspore']}
            style={{ width: '500px' }}
            titleLinkDisabled={!isLoggedIn()}
          >
            <span>
              {drtsporeDesc}
            </span>
          </HomePageCard>
        </div>
      </InfoPanel>
    </div>
  );
};

export default HomePage;
