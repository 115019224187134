import React from 'react';

const InfoPanel = ({
  variant = 'light',
  textVariant = 'primary',
  children,
  ...customProps
}) => {
  return (
    <div
      {...customProps}
      className={`info-panel border border-${variant} rounded bg-${variant}`}
    >
      <div className={`m-2 text-sm text-${textVariant} text-center`}>
        <small>{children}</small>
      </div>
    </div>
  );
};

export default InfoPanel;
