import { toast } from 'react-toastify';
import { apiGet, apiFetch } from './api-helpers';
import { tError, tSuccess } from '../../utils/toast-helpers';
import { useQuery, useQueries, useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver';

export const useGetProductsQuery = (opts = null) => {
  const uri = '/products/parameter/ECW';

  return useQuery(['products'], () => apiGet(uri), opts);
};

export const useGetProductsByTagQuery = ({ tagId }) => {
  const uri = `/products/tag/${tagId}`;

  return useQuery(['products', 'tag'], () => apiGet(uri), { enabled: !!tagId });
};

export const useGetProductSelectionQuery = ({ productId }) => {
  return useGetProductsQuery({
    select: products => products.find(product => product.id === productId),
  });
};

export const useGetProductFilesQuery = ({ productId, opts = null }) => {
  const uri = `/products/${productId}/files?after=1970-01-01&before=3000-01-01`;

  return useQuery(['products', productId, 'files'], () => apiGet(uri), {
    enabled: !!productId,
    ...opts,
  });
};

export const useGetProductFilesQueries = ({ productIds = [] }) => {
  return useQueries({
    enabled: productIds?.length,
    queries: productIds.map(id => {
      const uri = `/products/${id}/files?after=1970-01-01&before=3000-01-01`;
      return {
        queryKey: ['products', id, 'files'],
        queryFn: () => apiGet(uri),
      };
    }),
  });
};

export const useGetProductFileSelectionQuery = ({ productId }) => {
  return useGetProductFilesQuery({
    productId,
    opts: {
      select: productFiles =>
        productFiles.find(product => product.id === productId),
    },
  });
};

export const useGetProductsFilesGroupByTagQuery = ({ tagId, opts = null }) => {
  const uri = `/products/tag/${tagId}/files`;

  return useQuery(['products', tagId, 'files'], () => apiGet(uri), {
    enabled: !!tagId,
    ...opts,
  });
};

export const useDownloadFileMutation = () => {
  const downloadFn = async (id, fileName) => {
    const uri = `/product/file/download/${id}`;

    return apiFetch(uri)
      .then(res => res.blob())
      .then(blob => {
        const cleanName = fileName.replace(/[/\\?%*:|"<>]/g, '_');
        saveAs(blob, `${cleanName}.tif`);
      });
  };

  return useMutation(
    ({ id, fileName }) => {
      return downloadFn(id, fileName);
    },
    {
      onMutate: () => {
        const toastId = toast.loading('Downloading GeoTIFF...');
        return { toastId };
      },
      onError: (_, __, ctx) => {
        tError(ctx.toastId, 'Failed to download. Try again later.');
      },
      onSuccess: (_, __, ctx) => {
        tSuccess(ctx.toastId, 'Download successful!');
      },
    },
  );
};
