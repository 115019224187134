import React from 'react';
import Sidebar from '../../app-components/sidebar/sidebar';
import SelectProductCard from '../shared-components/selectProductCard';
import SelectCoordinateCard from './dashboard-cards/selectCoordinateCard';
import SelectSnowAnalogCard from './dashboard-cards/selectSnowAnalogCard/selectSnowAnalogCard';
import OlMap from './olMap';
import DashboardContent from '../shared-components/dashboardContent';
import { store } from '../../stores/app-state/store';
import { useGetProductSelectionQuery } from '../../stores/server-state/products';
import { useGetTagByNameQuery } from '../../stores/server-state/tags';
import { snowAnalogDesc } from '../HomePage';
import Card from '../../app-components/card/card';

const defaultProductSlug = 'globsnow-prob';

const SnowAnalog = () => {
  const { productId, doUpdateProductId } = store.useProductId();
  const { doUpdateMyCoordinateId } = store.useMyCoordinateId();
  const { doUpdateMySnowAnalogId } = store.useMySnowAnalogId();
  const { doUpdateIsSavePromptCoordinate } = store.useIsSavePromptCoordinate();
  const { doUpdateIsDeletePromptCoordinate } =
    store.useIsDeletePromptCoordinate();
  const { doUpdateIsDrawingCoordinate } = store.useIsDrawingCoordinate();
  const { doUpdateShowSource } = store.useShowSource();

  const { data: tag } = useGetTagByNameQuery({ name: 'Snow Analog' });
  const { data: productSelection } = useGetProductSelectionQuery({ productId });

  React.useEffect(() => {
    return () => {
      doUpdateProductId('');
      doUpdateMyCoordinateId('');
      doUpdateMySnowAnalogId('');
      doUpdateIsSavePromptCoordinate(false);
      doUpdateIsDeletePromptCoordinate(false);
      doUpdateIsDrawingCoordinate(false);
      doUpdateShowSource(true);
    };
  }, []);

  return (
    <DashboardContent>
      <Sidebar>
        <Card className='font-weight-light'>
          <span>{snowAnalogDesc}</span>
        </Card>
        <SelectProductCard defaultProductSlug={defaultProductSlug} />
        <SelectCoordinateCard tag={tag} />
        <SelectSnowAnalogCard />
      </Sidebar>
      <OlMap productSlug={productSelection?.slug} />
    </DashboardContent>
  );
};

export default SnowAnalog;
