import { useEffect } from 'react';

import { store } from '../../../stores/app-state/store';
import { useOLMap } from '../olMap';

import { selectedStyle } from '../styles/vectorStyles';
import { useVectorLayer } from '../hooks';

const VectorLayer = ({
  title = '',
  source,
  zoomSource = source,
  zIndex = 0,
  style = selectedStyle,
  zoomPadding = 150,
}) => {
  const { map, extent } = useOLMap();
  const { isAutoFocus } = store.useIsAutoFocus();

  const vectorLayer = useVectorLayer({
    source,
    name: title,
    zIndex,
  });

  // Apply Layer
  useEffect(() => {
    vectorLayer && map.addLayer(vectorLayer);
    return () => {
      vectorLayer && map.removeLayer(vectorLayer);
    };
  }, [map, vectorLayer]);

  // Auto Focus
  useEffect(() => {
    if (!isAutoFocus || !extent) return;
    const view = map.getView();

    if (!zoomSource) {
      view.fit(extent, { duration: 1500 });
    } else if (zoomSource === source) {
      const padding = new Array(4).fill(zoomPadding);
      view.fit(zoomSource.getFeatures()[0].getGeometry(), {
        duration: 1500,
        padding,
      });
    }
  }, [map, isAutoFocus, zoomSource, source, extent, zoomPadding]);

  useEffect(() => {
    vectorLayer && vectorLayer.setStyle(style);
  }, [vectorLayer, style]);

  return null;
};

export default VectorLayer;
