import React, { useEffect } from 'react';
import OlMap from './olMap';
import SelectRasterCard from './dashboard-cards/selectRasterCard/selectRasterCard';
import Sidebar from '../../app-components/sidebar/sidebar';
import LegendExplorerCard from './dashboard-cards/LegendExplorerCard/LegendExplorerCard';
import SelectCoordinateCard from '../SnowAnalog/dashboard-cards/selectCoordinateCard/selectCoordinateCard';
import { store } from '../../stores/app-state/store';
import {
  useGetProductsFilesGroupByTagQuery,
  useGetProductSelectionQuery,
} from '../../stores/server-state/products';
import { useGetTagByNameQuery } from '../../stores/server-state/tags';
import Card from '../../app-components/card/card';
import { permafrostDesc } from '../HomePage';

const Permafrost = () => {
  const { productId, doUpdateProductId } = store.useProductId();
  const { doUpdateShowSource } = store.useShowSource();

  const { data: productSelection } = useGetProductSelectionQuery({ productId });

  const { data: tag } = useGetTagByNameQuery({ name: 'Permafrost' });
  const { data: products, isLoading } = useGetProductsFilesGroupByTagQuery({
    tagId: tag?.id,
  });

  useEffect(() => {
    return () => {
      doUpdateProductId('');
      doUpdateShowSource(true);
    };
  }, []);

  return (
    <div>
      <Sidebar>
        <Card className='font-weight-light'>
          <span>{permafrostDesc}</span>
        </Card>
        <SelectRasterCard products={products} />
        <SelectCoordinateCard tag={tag} />
        <LegendExplorerCard />
      </Sidebar>
      <OlMap
        productSlug={productSelection?.slug}
        isLoading={isLoading}
        products={products}
      />
    </div>
  );
};

export default Permafrost;
