import React from 'react';
import Card from '../../../../app-components/card/card';
import { useGetProductSelectionQuery } from '../../../../stores/server-state/products';
import { store } from '../../../../stores/app-state/store';

import './LegendExplorerCard.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { layerType, mapConfigHandler } from '../../../../utils/colormaps';

const legendContentHandler = slug => {
  switch (slug) {
    case 'esa-lcc':
      return (
        <img
          alt='LCC Legend'
          className='p-2'
          src='/esa-lcc.png'
          width='100%'
          height='90%'
        />
      );
    case 'permafrost-combo':
      return (
        <img
          alt='permafrost combo legend'
          src='/permafrost-combo.png'
          width='100%'
          height='100%'
          className='p-2'
        />
      );
    case 'permafrost-extent':
      return (
        <img
          alt='permafrost extent Legend'
          src='/permafrost-extent.png'
          width='50%'
          className='p-2'
        />
      );
    case 'permafrost-content':
      return (
        <img
          alt='permafrost content legend'
          src='/permafrost-content.png'
          width='65%'
          className='p-2'
        />
      );
    case 'permafrost-landform':
      return (
        <img
          alt='permafrost landform legend'
          src='/permafrost-landform.png'
          width='103%'
          height='150px'
          className='p-2'
        />
      );
  }
};

const LegendExplorerCard = () => {
  const { productId } = store.useProductId();
  const { data: productSelection } = useGetProductSelectionQuery({ productId });

  const layerCfg = mapConfigHandler(productSelection?.slug);

  return layerCfg?.type === layerType.Categorical ? (
    <Card
      header='Legend Explorer'
      tooltip={`
        <span>
          Select a product using the 'Select Product' card to populate this card
          with the respective Legend
        </span>
      `}
    >
      {legendContentHandler(productSelection?.slug)}
    </Card>
  ) : null;
};

export default LegendExplorerCard;
