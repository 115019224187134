import React from 'react';

import DropdownContext from './dropdownContext';
import { Link } from '@tanstack/react-location';

const DropdownItem = ({
  onClick = () => {},
  value = '',
  href = null,
  className = '',
  children = null,
}) => (
  <DropdownContext.Consumer>
    {({ closeDropdown }) =>
      href ? (
        <Link
          className={`dropdown-item ${className}`}
          to={href}
          onClick={() => closeDropdown()}
        >
          {children}
        </Link>
      ) : (
        <button
          value={value}
          className={`dropdown-item ${className}`}
          onClick={e => {
            closeDropdown();
            onClick(e);
          }}
        >
          {children}
        </button>
      )
    }
  </DropdownContext.Consumer>
);

export default DropdownItem;
