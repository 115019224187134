import { useState, useCallback, useEffect } from 'react';

import OLVectorLayer from 'ol/layer/Vector';

const useVectorLayer = ({ source, name, zIndex }) => {
  const [layer, setLayer] = useState(null);

  const createLayer = useCallback(
    source => {
      return new OLVectorLayer({
        name,
        source,
        zIndex,
      });
    },
    [name, zIndex],
  );

  useEffect(() => {
    source && setLayer(createLayer(source));
    return () => {
      source && setLayer(null);
    };
  }, [createLayer, source]);

  return layer;
};

export default useVectorLayer;
