import React, { useEffect, useState } from 'react';
import { store } from '../../../stores/app-state/store';
import { WebGLTileLayer } from '../../../app-components/ol-map/layers';
import { useWebGLStyle } from '../../../app-components/ol-map/hooks';
import { mapConfigHandler } from '../../../utils/colormaps';
import { useWCS1 } from '../../../app-components/ol-map/hooks/useWCS';
import { useOLMap } from '../../../app-components/ol-map/olMap';

export const WCSDataTile = ({
  name = '',
  baseUrl,
  layerName,
  timeISO,
  tileGrid,
  nshades = 80,
  visible = true,
  visibleStyle = true,
  colors,
  type,
  minRange,
  maxRange,
  clipMinColor,
  clipMaxColor,
  nodata,
  noDisplayValues,
  attributions,
}) => {
  const { minFilter } = store.useMinFilter();
  const { maxFilter } = store.useMaxFilter();
  const { projection } = useOLMap();
  const [source, setSource] = useState(null);

  const { noDisplayValues: noDisplayValuesDefault } = mapConfigHandler(name);

  useEffect(() => {
    const newSource = useWCS1({
      baseUrl,
      layerName,
      timeISO,
      projection,
      tileGrid,
      attributions,
    });
    setSource(newSource);
  }, [baseUrl, layerName, timeISO, projection, tileGrid, attributions]);

  const [style, setStyle] = useWebGLStyle({
    type,
    colors,
    nshades,
    clipMinColor,
    clipMaxColor,
    minRange,
    maxRange,
    minFilter,
    maxFilter,
    nodata,
    visible: visibleStyle,
    noDisplayValues: noDisplayValues ?? noDisplayValuesDefault,
  });

  return (
    <WebGLTileLayer
      name={name}
      source={source}
      style={style}
      visible={visible}
      minFilter={minFilter}
      maxFilter={maxFilter}
      getVariables={setStyle}
    />
  );
};
