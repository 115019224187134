import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import DropdownContext from './dropdownContext';
import useWindowListener from '../../customHooks/useWindowListener';
import { classArray } from '../../utils/helpers';

import './dropdown.scss';

const DropdownMenu = forwardRef(
  (
    {
      id = 'dropdown',
      dropdownClass = '',
      buttonClass = '',
      menuClass = '',
      closeOnSelect = true,
      buttonContent = null,
      customContent = null,
      children = null,
      closeWithEscape = true,
      onToggle = () => {},
      customElementProps = {},
      isDisabled = false,
    },
    ref,
  ) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);

    const dropdownClasses = classArray(['dropdown', dropdownClass]);
    const buttonClasses = classArray(['btn', buttonClass]);
    const menuClasses = classArray([
      'dropdown-menu',
      isOpen && 'show',
      menuClass,
    ]);

    useImperativeHandle(ref, () => ({
      openDropdown: () => setIsOpen(true),
      closeDropdown: () => setIsOpen(false),
      toggleDropdown: () => setIsOpen(!isOpen),
    }));

    useWindowListener('keyup', e => {
      if (
        closeWithEscape &&
        (e.key === 'Esc' || e.key === 'Escape') &&
        isOpen
      ) {
        setIsOpen(false);
      }
    });

    useEffect(() => {
      onToggle(isOpen);
    }, [isOpen, onToggle]);

    const commonProps = {
      onClick: () => setIsOpen(!isOpen),
      'aria-haspopup': true,
      'aria-expanded': isOpen,
      ...customElementProps,
    };

    const closeDropdown = () => (closeOnSelect ? setIsOpen(false) : () => {});

    return (
      <DropdownContext.Provider value={{ closeDropdown }}>
        <div
          className={dropdownClasses}
          id={id}
          onBlur={closeDropdown}
          onMouseDown={e => isOpen && e.preventDefault()}
        >
          {customContent ? (
            React.cloneElement(customContent, commonProps)
          ) : (
            <button
              className={buttonClasses}
              id={`${id}MenuButton`}
              title='Toggle Dropdown'
              disabled={isDisabled}
              {...commonProps}
            >
              {buttonContent}
            </button>
          )}
          <div
            className={menuClasses}
            aria-labelledby={`${id}MenuButton`}
            ref={menuRef}
          >
            {children}
          </div>
        </div>
      </DropdownContext.Provider>
    );
  },
);

export default DropdownMenu;
