import React from 'react';
import Card from '../../../../app-components/card/card';
import { useGetProductSelectionQuery } from '../../../../stores/server-state/products';
import { store } from '../../../../stores/app-state/store';

import './LegendExplorerCard.scss';
import 'react-tooltip/dist/react-tooltip.css';
import { layerType, mapConfigHandler } from '../../../../utils/colormaps';

const legendContentHandler = slug => {
    if (slug === 'soil-type') {
      return (
        <img
          alt='Soil Texture Legend'
          className='p-2'
          src='/drtspore-soil-type.png'
          width='35%'
          height='90%'
        />
      );
  }
};

const LegendExplorerCard = () => {
  const { productId } = store.useProductId();
  const { data: productSelection } = useGetProductSelectionQuery({ productId });

  const layerCfg = mapConfigHandler(productSelection?.slug);

  return layerCfg?.type === layerType.Categorical ? (
    <Card
      header='Legend Explorer'
      tooltip={`
        <span>
          Select a product using the 'Select Product' card to populate this card
          with the respective Legend
        </span>
      `}
    >
      {legendContentHandler(productSelection?.slug)}
    </Card>
  ) : null;
};

export default LegendExplorerCard;
