import React, { useState, useRef, useEffect, useMemo } from 'react';

import { Tooltip } from 'react-tooltip';

import { toast } from 'react-toastify';
import { tSuccess, tError } from '../../../../utils/toast-helpers';
import ZonalStatsTable from './components/zonalStatsTable/zonalStatsTable';
import ZonalStatsLineChart from './components/zonalStatsLineChart/zonalStatsLineChart';
import LoadWheel from '../../../../app-components/load-wheel';
import ExportDropdown from './components/exportDropdown/exportDropdown';
import { buildFilename, meterToKilometer } from '../../../../utils/helpers';
import { getTableDataForExport, makeCsv } from '../../../../utils/tableHelpers';
import { saveAs } from 'file-saver';
import { createColumnHelper } from '@tanstack/react-table';

import Icon from '../../../../app-components/icon';
import { mdiHelpCircleOutline } from '@mdi/js';

import 'react-tooltip/dist/react-tooltip.css';
import './watershedStatsCard.scss';

const columnHelper = createColumnHelper();

const WatershedStatsCard = ({
  data,
  spatialResolution,
  isLoading = false,
  selectedFeatureName = '',
  selectedDate = '',
}) => {
  const [statsView, setStatsView] = useState('lineChart');
  const [waterYear, setWaterYear] = useState(0);
  const chartRef = useRef();

  useEffect(() => {
    const today = new Date();
    let year = today.getFullYear();
    if (today.getMonth() > 7) {
      year += 1;
    }
    setWaterYear(year);
  }, [setWaterYear]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('doy', {
        header: () => 'Date',
        cell: info => {
          const dateOfYear = info.getValue();
          const date = new Date(1970, 0);
          const tmp = new Date(date.setDate(dateOfYear));
          return (
            <div
              style={{
                paddingLeft: 2,
              }}
            >
              {`${(tmp.getMonth() + 1).toString().padStart(2, '0')}/${tmp
                .getDate()
                .toString()
                .padStart(2, '0')}`}
            </div>
          );
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor('last_year', {
        header: () => `WY${waterYear - 1}`,
        cell: info => {
          const lastYear = info.getValue();
          if (lastYear) return `${lastYear.toFixed(4)}`;
          else return info.renderValue();
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor('this_year', {
        header: () => `WY${waterYear}`,
        cell: info => {
          const thisYear = info.getValue();
          if (thisYear) return `${thisYear.toFixed(4)}`;
          else return info.renderValue();
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor('hist_min', {
        header: () => 'Min',
        cell: info => {
          const histMin = info.getValue();
          if (histMin) return `${histMin.toFixed(4)}`;
          else return info.renderValue();
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor('hist_max', {
        header: () => 'Max',
        cell: info => {
          const histMax = info.getValue();

          if (histMax) return `${histMax.toFixed(4)}`;
          else return info.renderValue();
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor('hist_mean', {
        header: () => 'Mean',
        cell: info => {
          const mean = info.getValue();
          if (mean) return `${mean.toFixed(4)}`;
          else return info.renderValue();
        },
        footer: info => info.column.id,
      }),
      columnHelper.accessor('hist_std', {
        header: () => 'Std Dev',
        cell: info => {
          const std = info.getValue();
          if (std) return `${std.toFixed(4)}`;
          else return info.renderValue();
        },
        footer: info => info.column.id,
      }),
    ],
    [waterYear],
  );

  const statsViewHandler = (e, value) => {
    e.preventDefault();
    setStatsView(value);
  };

  const exportHandler = reportType => {
    const toastId = toast.loading('Downloading statistics...');
    if (!reportType) {
      tError(toastId, 'Failed to download. Try again later.');
      return;
    }

    const spatialResKm = meterToKilometer(spatialResolution);
    const lowercaseReport = reportType.toLowerCase();
    const fileName = buildFilename({
      featureName: selectedFeatureName,
      spatialResolution: `${spatialResKm}km`,
      fileExtension: lowercaseReport,
    });

    if (reportType === 'CSV') {
      makeCsv(
        getTableDataForExport(data, columns),
        fileName,
        `${selectedFeatureName}; Spatial Resolution ${spatialResKm}km`,
      );

      tSuccess(toastId, 'Download successful!');
    } else if (reportType === 'PNG' || reportType === 'JPEG') {
      const b64png = chartRef.current.toBase64Image(`image/${lowercaseReport}`);
      fetch(b64png)
        .then(res => res.blob())
        .then(blob => {
          saveAs(blob, fileName);
          tSuccess(toastId, 'Download successful!');
        })
        .catch(err => {
          tError(toastId, `Download Failed: ${err}`);
        });
    }
  };

  return (
    <div className='card'>
      <div className='card-header font-weight-light p-2'>
        <div className='row'>
          <span className='col ml-3 mt-2'>
            Zonal Statistics
            <Icon
              path={mdiHelpCircleOutline}
              size={0.6}
              id='stats-help'
              className='tooltip-icon ml-2'
            />
            <Tooltip style={{ zIndex: 1000 }} anchorId='stats-help'>
              <span>
                <strong>Chart</strong>: Click the labels to hide the associated
                trace, hover over the traces for details
                <br />
                <strong>Table</strong>: Click the headers to change sorting
              </span>
            </Tooltip>
          </span>
          <div className='d-flex justify-content-end mb-1 mr-4'>
            <ul
              className='nav nav-tabs nav-fill card-header-tabs'
              id='watershed-list'
              role='tablist'
            >
              <li className='nav-item mr-2'>
                <a
                  href='/watershed-statistics'
                  className={`nav-link ${
                    statsView === 'lineChart' ? 'active' : ''
                  }`}
                  role='tab'
                  aria-controls='lineChart'
                  aria-selected={statsView === 'lineChart' ? 'true' : 'false'}
                  onClick={e => statsViewHandler(e, 'lineChart')}
                >
                  Chart
                </a>
              </li>
              <li className='nav-item mr-2'>
                <a
                  href='/watershed-statistics'
                  className={`nav-link ${
                    statsView === 'table' ? 'active' : ''
                  }`}
                  role='tab'
                  aria-controls='table'
                  aria-selected={statsView === 'table' ? 'true' : 'false'}
                  onClick={e => statsViewHandler(e, 'table')}
                >
                  Table
                </a>
              </li>
              <div className='span2'>
                <ExportDropdown
                  className='btn-width mt-1 mr-2 pr-1'
                  handleClick={exportHandler}
                  text='Export'
                  dropdownClasses='btn-block'
                  buttonClasses='btn-success btn-sm btn-block'
                  isDisabled={isLoading || !data || !data.length}
                />
              </div>
            </ul>
          </div>
        </div>
      </div>
      <div className='card-body' style={{ width: '100%', height: '414px' }}>
        {isLoading ? (
          <LoadWheel
            text='Calculating Statistics...'
            subText='Processing time may vary with size of selected area'
            className='mt-4 d-flex flex-column justify-content-center align-items-center'
          />
        ) : (
          <>
            <ZonalStatsLineChart
              ref={chartRef}
              data={data}
              isHidden={statsView !== 'lineChart'}
              waterYear={waterYear}
              selectedDate={selectedDate}
            />
            <ZonalStatsTable
              rowData={data}
              waterYear={waterYear}
              isHidden={statsView !== 'table'}
              columns={columns}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default WatershedStatsCard;
