import proj4 from 'proj4';
import { get } from 'ol/proj';
import { register } from 'ol/proj/proj4';

// WGS 84 / NSIDC EASE-Grid 2.0 North
proj4.defs(
  'EPSG:6931',
  '+proj=laea +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
);
// NAD83
proj4.defs(
  'EPSG:4269',
  '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs',
);
register(proj4);

const projection6931 = get('EPSG:6931');
const extent6931 = [
  -9009964.761231285, -9009964.761231285, 9009964.761231285, 9009964.761231285,
];
const zoomExtent6931 = [...extent6931];

export const easeGridNorthMapConfig = {
  projection: projection6931,
  extent: extent6931,
  zoomExtent: zoomExtent6931,
};

export const alaskaWebMercatorMapConfig = {
  center: [-16290058.903445011, 9448541.51522524],
  zoomExtent: [
    -16606922.119251821, 9143337.291976493, -15885839.895844046,
    9753745.738473985,
  ],
  zoom: 7.25,
  minZoom: 0,
  maxZoom: 19,
};

export const drtsporeMapConfig = {
  projection: get('EPSG:4326'),
  center: [-158, 64.5],
  extent: [-176, 50, -140, 79],
  zoomExtent: [-176, 50, -140, 79],
  zoom: 6,
  minZoom: 4,
  maxZoom: 20,
};
