import React from 'react';

import DrtsporeTable from './components/drtsporeTable';
import Card from '../../../../app-components/card/card';

const DrtsporeTableCard = () => {
  return (
    <Card
      header='DRTSPORE Record'
      tooltip='Select a record by name. <br /> Use the arrow to expand the row to select individual products.'
    >
      <DrtsporeTable />
    </Card>
  );
};

export default DrtsporeTableCard;
