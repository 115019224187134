// source: https://github.com/USACE/jems-ui/blob/main/src/utils/helpers.js
export const classnames = opts =>
  Object.keys(opts)
    .map(key => (opts[key] ? key : ''))
    .join(' ');

export const classArray = arr => arr.filter(e => e).join(' ');

export const formatBytes = bytes => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]}`;
  return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
};

export const isNumeric = str => {
  if (typeof str != 'string') return false; // only process strings
  return (
    !isNaN(str) && // use type coercion to parse the entirety of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
};

/**
 * Returns a provided string based on parameters provided
 * @param {string} single - The string to be returned if value is 1
 * @param {string} plural - The string to be returned if value is not 1
 * @param {number} value - Checked to determine which string to return;
 * @returns The single or plural string provided based on the value.
 */
export const pluralize = (single, plural, value) => {
  if (value === 1) return single;
  return plural;
};

export const keyAsText = key => {
  const words = key
    .substring(1)
    .split(/(?=[A-Z])/)
    .join(' ');
  return key.substring(0, 1).toUpperCase() + words;
};

export const hrefAsString = (href, allCaps = false) => {
  const str = href.replace('/', '');
  const words = str.split('-');
  const upperWords = words.map(word =>
    allCaps
      ? word.toUpperCase()
      : word.substring(0, 1).toUpperCase() + word.substring(1),
  );

  return upperWords.join(' ');
};

export const doyToAbstractDate = dateValue => {
  const date = new Date(1970, 0);
  const tmp = new Date(date.setDate(dateValue));
  return `${(tmp.getMonth() + 1).toString().padStart(2, '0')}/${tmp
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

export const leapYear = year => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const dateToDoy = dateStr => {
  const date = new Date(dateStr);
  if (date.getMonth() === 1 && date.getDate() === 29) date.setDate(28);
  let year = date.getFullYear();
  if (leapYear(year)) date.setFullYear(year - 1);
  const start = new Date(date.getFullYear(), 0, 0); // use arbirary non-leap year
  const diff =
    date -
    start +
    (start.getTimezoneOffset() - date.getTimezoneOffset()) * 60 * 1000;
  const oneDay = 1000 * 60 * 60 * 24;
  return Math.ceil(diff / oneDay);
};

export const doyCellFormatter = cell => {
  let cellVal = cell.value;
  if (cell.column.colId === 'doy') {
    cellVal = doyToAbstractDate(cellVal);
  }
  return cellVal;
};

export const meterToKilometer = spatialResolution => {
  return parseFloat(spatialResolution) / 1000;
};

export const buildFilename = ({
  featureName,
  spatialResolution,
  fileExtension,
}) => {
  return `${String(featureName).replace(
    / /g,
    '-',
  )}_${spatialResolution}_${new Date().toISOString()}.${fileExtension}`;
};

export const isNullOrUndefined = value => {
  return value === null || value === undefined;
};

export const arraysEqual = (a, b) => {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  for (var i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const roundIfNeeded = (
  value,
  minimumFractionDigits,
  maximumFractionDigits,
) => {
  const formattedValue = value.toLocaleString('en', {
    useGrouping: false,
    minimumFractionDigits,
    maximumFractionDigits,
  });
  return !isNaN(formattedValue) ? Number(formattedValue) : undefined;
};

// normalize number between 0 and 1
export const normalize = (val, min, max) => (val - min) / (max - min);

// denormalize number to provided min and max range
export const denormalize = (val, min, max) => (val + min) * (max + min);

/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 */
export function roundUp(num, precision) {
  precision = Math.pow(10, precision)
  return Math.ceil(num * precision) / precision
}

/**
 * @param num The number to round
 * @param precision The number of decimal places to preserve
 */
export function roundDown(num, precision) {
  precision = Math.pow(10, precision)
  return Math.floor(num * precision) / precision
}
