import React from 'react';

import ExpandTable from './expandTable';
import Card from '../../../../app-components/card/card';

import { usePollMySnowAnalogQuery } from '../../../../stores/server-state/snow-analog';

import 'react-tooltip/dist/react-tooltip.css';

const SelectSnowAnalogCard = () => {
  const { data: mySnowAnalog } = usePollMySnowAnalogQuery();
  const rowData = mySnowAnalog?.length ? mySnowAnalog : [];

  return (
    <Card
      header='Layer Explorer'
      tooltip={`
        <span>
          Select one of the generated raster products to display on the map
        </span>
      `}
      className='p-2'
      bodyStyle={{ width: '100%', height: '414px' }}
    >
      <ExpandTable rowData={rowData} />
    </Card>
  );
};

export default SelectSnowAnalogCard;
