import React, { useState, useCallback, useMemo } from 'react';
import Button from '../../../../app-components/button';

import Icon from '../../../../app-components/icon';
import { mdiDownload } from '@mdi/js';

import {
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { store } from '../../../../stores/app-state/store';
import { useDownloadFileMutation } from '../../../../stores/server-state/products';

import './subExpandTable.scss';

const columnHelper = createColumnHelper();

const SubExpandTable = ({ rowData = [], parentData = {} }) => {
  const [rowSelectionCancelled, setRowSelectionCancelled] = useState(false);

  const { doUpdateProductId } = store.useProductId();
  const { doUpdateProductFileId } = store.useProductFileId();
  const { doUpdateAnalogProductId } = store.useAnalogProductId();
  const { doUpdateAnalogProductFileId } = store.useAnalogProductFileId();
  const { doUpdateSourceProductId } = store.useSourceProductId();
  const { doUpdateSourceProductFileId } = store.useSourceProductFileId();
  const { doUpdateShowSource } = store.useShowSource();
  const { mySnowAnalogId, doUpdateMySnowAnalogId } = store.useMySnowAnalogId();
  const { myCoordinateId, doUpdateMyCoordinateId } = store.useMyCoordinateId();

  const downloader = useDownloadFileMutation();

  const updateSnowAnalog = useCallback(
    id => doUpdateMySnowAnalogId(id),
    [doUpdateMySnowAnalogId],
  );

  const downloadSnowAnalog = useCallback(
    (id, fileName) => downloader.mutate({ id, fileName }),
    [downloader],
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('source_productfile_name', {
        header: () => (
          <div
            style={{
              paddingLeft: 30,
            }}
          >
            Source File
          </div>
        ),
        cell: ({ row }) => (
          <div
            style={{
              paddingLeft: 30,
            }}
          >
            {row.original.analog_productfile?.product_slug}
          </div>
        ),
        size: 250,
      }),
      columnHelper.display({
        header: 'Zonal Stats',
        id: 'zonal_statistics',
        cell: ({ row }) => {
          if (!row.original.zonal_stats) return '';
          const { min, max, mean, std } = row.original.zonal_stats;
          return `Min:  ${min.toFixed(4)}, Max: ${max.toFixed(
            4,
          )}, Mean: ${mean.toFixed(4)}, Std:  ${std.toFixed(4)}`;
        },
        size: 400,
      }),
      columnHelper.display({
        header: null,
        id: 'download',
        size: 10,
        cell: ({ row }) => {
          return (
            <Button
              handleClick={e => {
                e.stopPropagation();
                const fileName =
                  parentData.coordinate_name +
                  '_' +
                  row.original.analog_productfile?.file.replace(/[/.tif]/g, '') +
                  '_analog';
                downloadSnowAnalog(row.original.analog_productfile.id, fileName);
              }}
              onMouseUp={e => e.stopPropagation()}
              onMouseDown={e => e.stopPropagation()}
              style={{ boxShadow: 'none' }}
              icon={<Icon path={mdiDownload} size={0.8} />}
              size='large'
              variant='link'
              title='Download GeoTIFF'
              className='d-flex p-0 m-0 pr-1 shadow-none'
            />
          );
        },
      }),
    ],
    [downloadSnowAnalog, parentData.coordinate_name],
  );

  const parentRowIsSelected = parentData.coordinate_id === myCoordinateId;
  const subRowIsSelected = row => row.original.id === mySnowAnalogId;

  const rowSelectHandler = row => {
    if (!Object.keys(row.original)) {
      return;
    }
    doUpdateProductId(row.original.analog_productfile.product_id);
    doUpdateProductFileId(row.original.analog_productfile.id);
    doUpdateAnalogProductId(row.original.analog_productfile.product_id);
    doUpdateAnalogProductFileId(row.original.analog_productfile.id);
    doUpdateSourceProductId(row.original?.source_productfile?.product_id ?? '');
    doUpdateSourceProductFileId(row.original?.source_productfile?.id ?? '');

    if (parentData.status === 'SUCCESS') {
      if (row.original.id && !subRowIsSelected(row)) {
        updateSnowAnalog(row.original.id);
        doUpdateShowSource(false);
      }
    }
    if (!parentRowIsSelected) {
      doUpdateMyCoordinateId(parentData.coordinate_id);
    }
  };

  const getRowClasses = row => {
    return row && subRowIsSelected(row) ? 'sub-row-selected' : '';
  };

  const table = useReactTable({
    data: rowData,
    columns,
    initialState: {
      sorting: [
        {
          id: 'source_productfile_name',
          desc: false,
        },
      ],
    },
    columnResizeMode: 'onChange',
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: import.meta.env.DEV,
    className: 'sub-expand-table',
  });

  let timerId;
  return (
    <table className='sub-expand-table'>
      <thead>
        {table.getHeaderGroups().map(headerGroup => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map(header => {
              return (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  style={{
                    width: header.getSize(),
                    whiteSpace: 'nowrap',
                    backgroundColor: 'rgb(250, 250, 250)',
                    borderTop: '1px dashed rgb(200, 200, 200)',
                    borderBottom: '1px dashed rgb(200, 200, 200)',
                    backgroundImage: 'none',
                  }}
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </div>
                  )}
                  {header.index + 1 !== columns.length ? (
                    <>
                      <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className='pl-2 resizer'
                      />
                      <div className='resizer-line' />
                    </>
                  ) : null}
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map(row => {
          return (
            <tr
              key={row.id}
              onMouseDown={e => {
                // Differentiate click from hold and rightclick for text highlighting
                setRowSelectionCancelled(e.button === 2);
                timerId = setTimeout(() => {
                  setRowSelectionCancelled(true);
                }, 300);
              }}
              onMouseUp={() => {
                if (!rowSelectionCancelled) {
                  rowSelectHandler(row);
                }
                clearTimeout(timerId);
              }}
              className={getRowClasses(row)}
            >
              {row.getVisibleCells().map(cell => {
                return (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default SubExpandTable;
