import React, { useEffect, useReducer, useState } from 'react';
import Button from '../../../../../app-components/button/button';
import VirtualSelect from '../../../../../app-components/select-virtual/select-virtual';
import { store } from '../../../../../stores/app-state/store';
import {
  useCreateDrtsporeMutation,
  useGetDrtsporeSelectionQuery,
  useGetDrtsporeTimeQuery,
  useUpdateDrtsporeMutation,
} from '../../../../../stores/server-state/drtspore';
import { reduceFormData } from '../../../../../utils/form-helpers';

const DrtsporeForm = () => {
  const { drtsporeId } = store.useDrtsporeId();
  const { drawGeomDrtspore, doUpdateDrawGeomDrtspore } =
    store.useDrawGeomDrtspore();
  const { isDrawingDrtspore, doUpdateIsDrawingDrtspore } =
    store.useIsDrawingDrtspore();
  const { doUpdateIsSavePromptDrtspore } = store.useIsSavePromptDrtspore();

  let initialState = {
    name: '',
    geom: '',
    requested_timestamps: [],
  };

  const { data: drtsporeSelection } = useGetDrtsporeSelectionQuery({
    drtsporeId,
  });
  const { data: dateTimes } = useGetDrtsporeTimeQuery();
  const creator = useCreateDrtsporeMutation();
  const updater = useUpdateDrtsporeMutation({ drtsporeId });

  const [formIsValid, setFormIsValid] = useState(true);
  const [formType, setFormType] = useState('new');
  const [formData, dispatch] = useReducer(reduceFormData, initialState);
  const [timeDisabled, setTimeDisabled] = useState(false);
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [drtsporeDateRange, setDrtsporeDateRange] = useState([]);
  const [drtsporeAllTimeOptions, setDrtsporeAllTimeOptions] = useState([]);
  const [drtsporeTimeOptions, setDrtsporeTimeOptions] = useState([]);

  useEffect(() => {
    drawGeomDrtspore &&
      dispatch({
        type: 'update',
        field: 'geom',
        value: drawGeomDrtspore,
      });
  }, [dispatch, drawGeomDrtspore]);

  useEffect(() => {
    if (drtsporeId?.length) {
      setFormType('edit');
    } else {
      setFormType('new');
    }
  }, [drtsporeId, drtsporeSelection, setFormType]);

  useEffect(() => {
    setDrtsporeTimeOptions(
      drtsporeAllTimeOptions.filter(option =>
        filterDateTimesInDateRange(option.value, drtsporeDateRange),
      ),
    );
  }, [drtsporeDateRange]);

  useEffect(() => {
    if (
      drtsporeTimeOptions.length > 0 &&
      formData.requested_timestamps.length > 0
    ) {
      const timestampsInRange = [];
      for (const selection of formData.requested_timestamps) {
        if (drtsporeTimeOptions.includes(selection)) {
          timestampsInRange.push(selection);
        }
      }
      dispatch({
        type: 'update',
        field: 'requested_timestamps',
        value: timestampsInRange,
      });
    }
  }, [drtsporeTimeOptions]);

  useEffect(() => {
    if (dateTimes && dateTimes.length > 0) {
      const firstDate = new Date(dateTimes[0]);
      const lastDate = new Date(dateTimes[dateTimes.length - 1]);
      const weekBeforeLastDate = new Date(lastDate - 60 * 60 * 24 * 7 * 1000);
      const startDate =
        firstDate < weekBeforeLastDate ? weekBeforeLastDate : lastDate;
      setDrtsporeAllTimeOptions(
        dateTimes.map(date => ({
          label: new Date(date).toUTCString().substring(0, 19) + '00 GMT',
          value: date,
        })),
      );
      setFirstDate(firstDate.toISOString().substring(0, 10));
      setLastDate(lastDate.toISOString().substring(0, 10));
      setDrtsporeDateRange([
        startDate.toISOString().substring(0, 10),
        lastDate.toISOString().substring(0, 10),
      ]);
    }
  }, [dateTimes]);

  useEffect(() => {
    if (
      formData.name.length !== 0 &&
      formData.geom !== '' &&
      formData.requested_timestamps.length !== 0
    ) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
  }, [formData]);

  const closeForm = () => {
    doUpdateIsDrawingDrtspore(false);
    doUpdateIsSavePromptDrtspore(false);
    doUpdateDrawGeomDrtspore(undefined);
  };

  const submitForm = e => {
    e.preventDefault();
    formData.name = formData.name.trim();
    if (
      formData.name.length === 0 ||
      formData.requested_timestamps.length === 0 ||
      formData.geom === ''
    ) {
      setFormIsValid(false);
      return;
    }
    const submitData = {
      name: formData.name,
      geom: formData.geom,
      requested_timestamps: formData.requested_timestamps.map(o => o.value),
    };
    if (formType === 'edit') {
      updater.mutate(submitData);
    } else {
      creator.mutate(submitData);
    }
    dispatch({ type: 'reset', payload: initialState });
    closeForm();
  };

  const cancelForm = () => {
    dispatch({ type: 'reset', payload: initialState });
    closeForm();
  };

  function filterDateTimesInDateRange(dateStr, range) {
    const date = new Date(dateStr);
    return (
      date.getTime() >= dateToMilliseconds(range[0]) &&
      date.getTime() <
        new Date(dateToMilliseconds(range[1]) + 1000 * 60 * 60 * 24)
    );
  }

  function dateToMilliseconds(dateStr) {
    return Date.parse(dateStr + 'T00:00:00.000Z');
  }

  function isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  function daysBetweenDates(startDate, endDate) {
    return (
      (dateToMilliseconds(endDate) - dateToMilliseconds(startDate)) / 8.64e7
    );
  }

  return (
    <div>
      <div className='form-row'>
        <div className='w-100 ml-1 form-group'>
          <form id='drtspore-form' onSubmit={submitForm}>
            <div className='input-group mt-1'>
              <input
                type='date'
                id='start-date'
                className='form-control rounded-left'
                min={firstDate}
                max={lastDate}
                required={true}
                defaultValue={drtsporeDateRange[0]}
                onChange={e => {
                  const date = new Date(e.target.value);
                  if (
                    isValidDate(date) &&
                    isValidDate(new Date(drtsporeDateRange[1]))
                  ) {
                    const dates = [
                      date.toISOString().substring(0, 10),
                      drtsporeDateRange[1],
                    ];
                    setDrtsporeDateRange(dates);
                    if (
                      dates[0] > dates[1] ||
                      daysBetweenDates(dates[0], dates[1]) > 31
                    ) {
                      setTimeDisabled(true);
                      dispatch({
                        type: 'update',
                        field: 'requested_timestamps',
                        value: [],
                      });
                    } else {
                      setDrtsporeTimeOptions(
                        dateTimes.filter(option =>
                          filterDateTimesInDateRange(option.value, dates),
                        ),
                      );
                      setTimeDisabled(false);
                    }
                  } else {
                    setTimeDisabled(true);
                    dispatch({
                      type: 'update',
                      field: 'requested_timestamps',
                      value: [],
                    });
                  }
                }}
              />
              <input
                type='date'
                id='end-date'
                className='form-control rounded-left'
                min={firstDate}
                max={lastDate}
                required={true}
                defaultValue={drtsporeDateRange[1]}
                onChange={e => {
                  const date = new Date(e.target.value);
                  if (
                    isValidDate(date) &&
                    isValidDate(new Date(drtsporeDateRange[0]))
                  ) {
                    const dates = [
                      drtsporeDateRange[0],
                      date.toISOString().substring(0, 10),
                    ];
                    setDrtsporeDateRange(dates);
                    if (
                      dates[0] > dates[1] ||
                      daysBetweenDates(dates[0], dates[1]) > 31
                    ) {
                      setTimeDisabled(true);
                      dispatch({
                        type: 'update',
                        field: 'requested_timestamps',
                        value: [],
                      });
                    } else {
                      setDrtsporeTimeOptions(
                        dateTimes.filter(option =>
                          filterDateTimesInDateRange(option.value, dates),
                        ),
                      );
                      setTimeDisabled(false);
                    }
                  } else {
                    setTimeDisabled(true);
                    dispatch({
                      type: 'update',
                      field: 'requested_timestamps',
                      value: [],
                    });
                  }
                }}
              />
            </div>
            <div className='input-group mt-1'>
              <VirtualSelect
                name='requested_timestamps'
                isDisabled={
                  !(drtsporeDateRange[0] && drtsporeDateRange[1]) ||
                  timeDisabled
                }
                isMulti={true}
                options={drtsporeTimeOptions}
                placeholderText='Select times between dates...'
                style={{ maxHeight: '40px' }}
                aria-label='Select times'
                aria-describedby='Select times'
                value={formData.requested_timestamps}
                onChange={selectedOptions => {
                  dispatch({
                    type: 'update',
                    field: 'requested_timestamps',
                    value: selectedOptions,
                  });
                }}
              />
            </div>
            <div className='form-group row mt-1'>
              <div className='col-md-2'>
                <Button
                  text={!isDrawingDrtspore ? 'Draw' : 'Cancel'}
                  variant={!isDrawingDrtspore ? 'success' : 'danger'}
                  handleClick={() => {
                    if (isDrawingDrtspore) {
                      dispatch({
                        type: 'update',
                        field: 'geom',
                        value: '',
                      });
                    }
                    doUpdateIsSavePromptDrtspore(false);
                    doUpdateIsDrawingDrtspore(!isDrawingDrtspore);
                  }}
                  isOutline={isDrawingDrtspore}
                />
              </div>
              <div className='col-md-10'>
                <div className='input-group'>
                  <input
                    id='drtspore-name'
                    disabled={
                      !(
                        formData.geom !== '' &&
                        formData.requested_timestamps.length > 0
                      )
                    }
                    name='name'
                    className={`form-control ${
                      formData.name.length === 0 &&
                      formData.geom !== '' &&
                      formData.requested_timestamps.length > 0 &&
                      'is-invalid'
                    }`}
                    maxLength={100}
                    placeholder='Enter a record name'
                    aria-label='Enter a name for the Drtspore record'
                    aria-describedby='Enter a name for the Drtspore record'
                    onChange={e =>
                      dispatch({
                        type: 'update',
                        field: 'name',
                        value: e.target.value,
                      })
                    }
                    value={formData.name}
                    autoFocus
                  />
                  <div className='input-group-append'>
                    <Button
                      isDisabled={!formIsValid}
                      text='Save'
                      type='submit'
                      variant='success'
                      className='my-0 py-0'
                      style={{ zIndex: 0 }}
                    />
                  </div>
                  <div className='input-group-append'>
                    <Button
                      className='input-group-text my-0 py-0'
                      text='Cancel'
                      variant='danger'
                      style={{ zIndex: 0 }}
                      handleClick={cancelForm}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DrtsporeForm;
