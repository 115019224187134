import React from 'react';
import ColorBarFilter from '../../../app-components/color-bar-filter/colorBarFilter';
import { BaseMap } from '../../shared-components/mapContent';
import { store } from '../../../stores/app-state/store';
import { getColorBarTicks, mapConfigHandler } from '../../../utils/colormaps';
import { HoverValuePopout } from '../mapContent/hoverValuePopout';
import { isNullOrUndefined } from '../../../utils/helpers';

import './mapLayout.scss';
import 'react-tooltip/dist/react-tooltip.css';

const MapLayout = ({ productSlug, mapConfig = {}, children }) => {
  const { doUpdateMinFilter } = store.useMinFilter();
  const { doUpdateMaxFilter } = store.useMaxFilter();
  const { productId } = store.useProductId();
  const { rasterOptionMap, doUpdateRasterOptionMap } =
    store.useRasterOptionMap();

  const generateProductConfig = React.useCallback(() => {
    const defaultConfig = mapConfigHandler(productSlug);
    if (rasterOptionMap.has(productId)) {
      const rasterOptions = rasterOptionMap.get(productId);
      const statistics = rasterOptions.statistics;
      const overrideMinRange = rasterOptions?.override?.minRange;
      const overrideMaxRange = rasterOptions?.override?.maxRange;
      const ticks = rasterOptions?.override?.ticks;
      return {
        ...defaultConfig,
        ticks,
        defaultConfig,
        statistics,
        overrideMinRange,
        overrideMaxRange,
      };
    }
    return { ...defaultConfig, defaultConfig: mapConfigHandler(productSlug) };
  }, [productId, productSlug, JSON.stringify(rasterOptionMap?.get(productId))]);

  const applyConfigOverride = React.useCallback(
    override => {
      const newRasterOptionMap = new Map(rasterOptionMap);
      if (newRasterOptionMap.has(productId)) {
        let productOptions = newRasterOptionMap.get(productId);
        if (
          !isNullOrUndefined(override.minRange) &&
          !isNullOrUndefined(override.maxRange)
        ) {
          productOptions.override = {};
          productOptions.override.minRange = override.minRange;
          productOptions.override.maxRange = override.maxRange;
          productOptions.override.ticks = getColorBarTicks(
            override.minRange,
            override.maxRange,
          );
          newRasterOptionMap.set(productId, productOptions);
          doUpdateRasterOptionMap(newRasterOptionMap);
          doUpdateMinFilter(override.minRange);
          doUpdateMaxFilter(override.maxRange);
        } else {
          let newProductOptions = {};
          newProductOptions.defaultConfig = productOptions.defaultConfig;
          newProductOptions.statistics = productOptions.statistics;
          newRasterOptionMap.set(productId, productOptions);
          doUpdateRasterOptionMap(newRasterOptionMap);
          doUpdateMinFilter(productOptions?.defaultConfig.minRange);
          doUpdateMaxFilter(productOptions?.defaultConfig.maxRange);
        }
      }
    },
    [productId, productSlug, JSON.stringify(rasterOptionMap?.get(productId)), doUpdateMinFilter, doUpdateMaxFilter],
  );

  return (
    <div className='map-container'>
      {productSlug && (
        <>
          {rasterOptionMap.has(productId) &&
            <ColorBarFilter
              className='map-layout-colorbar'
              nshades={80}
              onMinChange={doUpdateMinFilter}
              onMaxChange={doUpdateMaxFilter}
              onApplyConfigOverride={applyConfigOverride}
              {...generateProductConfig()}
            />
          }
          <HoverValuePopout productSlug={productSlug} />
        </>
      )}
      <div className='map-layout'>
        <BaseMap {...mapConfig}>{children}</BaseMap>
      </div>
    </div>
  );
};

export default MapLayout;
